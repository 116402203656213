import OrderTemplateApiAgency from '@/api/OrderTemplateApiAgency';
import OrderToolboxJobDescriptionAddModal from '@/components/order/toolbox/OrderToolboxJobDescriptionAddModal.vue';
import type OrderTemplateEntity from '@/entity/order-template/OrderTemplateEntity';
import { Bus, useModal, useNotif } from '@orion.ui/orion';
import BaseService from '../BaseService';
import OrderTemplateApiClient from '@/api/OrderTemplateApiClient';

class OrderToolboxJobDescriptionService extends BaseService {
	showJobDescriptionModal (orderTemplate: OrderTemplateEntity) {

		useModal({
			Nested: OrderToolboxJobDescriptionAddModal,
			NestedProps: {
				orderTemplate,
				title: orderTemplate.id ? 'Modifier la description de poste' : undefined,
			},
			size: 'sm',
			events:
				{
					'validateJobDescription': async (Modal, payload) => {
						if (!orderTemplate.id) {
							try {
								Modal._loader()?.show();
								const { data } = await OrderTemplateApiAgency.add(payload);
								if (data) {
									useNotif.success(`La description de poste a bien été créée`);
									Bus.emit('OrderToolboxJobDescriptionSetupService:refresh-list', payload);
								}
								await Modal.close();
							} catch (e: any) {
								if (e.response?.data?.includes('Le nom existe déjà')) {
									Modal.bus.emit('titleValidation', false);
								}
							} finally {
								Modal._loader()?.hide();
							}
						} else {
							try {
								Modal._loader()?.show();
								orderTemplate.applyClone();
								await OrderTemplateApiAgency.update(payload.id, orderTemplate.publicState);
								Bus.emit('OrderToolboxJobDescriptionSetupService:refresh-list', payload);
								useNotif.success(`La description a bien été modifiée`);
								Modal.close();
							} catch (e: any) {
								if (e.response?.data?.includes('nom existe déjà')) {
									Modal.bus.emit('titleValidation', false);
								}
							} finally {
								Modal._loader()?.hide();
							}
						}
					},

					'resetParentTitleValidation': (M) => {
						M.bus.emit('titleValidation', true);
					},
				},

		});
	}

	async deleteModelAsync (id: number) {
		if (await OrderTemplateApiAgency.delete(id)) {
			useNotif.success(`Le modèle a bien été supprimé`);
		}
	}

	async listAsync (...args: Parameters<typeof OrderTemplateApiAgency.list>) {
		if (this.self.isAgency()) {
			const { data } = await OrderTemplateApiAgency.list(...args);
			return data;
		} else if (this.self.isClient()) {
			const { data } = await OrderTemplateApiClient.list(...args);
			return data;
		}
	}
}

const orderToolboxJobDescriptionServiceSingleton = new OrderToolboxJobDescriptionService();

export default function useOrderToolboxJobDescriptionService () {
	return orderToolboxJobDescriptionServiceSingleton;
}

