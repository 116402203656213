import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/orderuser');

class OrderUserApiAgency {
	static add (addOrderUserAgencyModel: IAddOrderUsersModel) {
		return client.post<void>('', addOrderUserAgencyModel);
	}

	static grid (orderUserAgencyPagedListParameters: IOrderUserAgencyPagedListParameters) {
		return client.post<IPagedList<IOrderUserAgencyListItemModel>>('grid', orderUserAgencyPagedListParameters);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

	static deleteMultiple (deleteOrderUsersModel: IDeleteOrderUsersModel) {
		return client.post<void>('delete', deleteOrderUsersModel);
	}

	static reject (rejectOrderUserModel: IRejectOrderUserModel) {
		return client.put<void>(`reject`, rejectOrderUserModel);
	}

	static rejectCancel (rejectCancelOrderUserModel: IRejectOrderUserModel) {
		return client.put<void>(`reject/cancel`, rejectCancelOrderUserModel);
	}

	static move (moveOrderUserModel: IMoveOrderUserModel) {
		return client.put<void>(`move`, moveOrderUserModel);
	}

	static listUserMostActive () {
		return client.get<IUserOrderStatisticActiveModel[]>(`stats/tempworker/active`);
	}

	static listCandidateStatus (orderId: number, params: IOrderUserAgencyForCandidateStatusPagedListParameters) {
		return client.post<IPagedList<Armado.TweakedOrderUserForCandidateStatusModel>>(`${orderId}/grid`, params);
	}
}

export default OrderUserApiAgency;
