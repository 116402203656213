import ApiClient from '@/api/ApiClient';

const client = new ApiClient('motive');

class MotiveApi {
	static list () {
		return client.get<IMotiveModel[]>('');
	}
}

export default MotiveApi;
