import { PropType, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import type OrderEntity from '@/entity/order/OrderEntity';
import { useNotif, useValidation } from '@orion.ui/orion';
import { Emits } from './OrderCreationAsideSetupService';

type Props = SetupProps<typeof OrderCreationStep3SetupService.props>

export default class OrderCreationStep3SetupService extends BaseSetupService<Props> {
	static readonly props = {
		order: {
			type: Object as PropType<OrderEntity>,
			required: true as const,
		},
	};

	readonly _startDate = ref<OrionDatepicker>();
	readonly _endDate = ref<OrionDatepicker>();
	readonly _periodRange = ref<OrionDatepicker>();
	private emits: Emits;

	validationRules = {
		start: 'required',
		end: 'required',
	};

	validator = useValidation(this.props.order, this.validationRules);

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;


	}

	protected onMounted () {
		const initialStartDate = new Date(this.props.order.start.setHours(8, 0, 0));
		initialStartDate.setDate(initialStartDate.getDate() + 1);

		if ((!this._startDate.value?.hasBeenFocus() && !this._startDate.value?.isFocus())) {
			this.props.order.start = initialStartDate;
		}
		if ((!this._endDate.value?.hasBeenFocus() && !this._endDate.value?.isFocus())) {
			this.props.order.end = new Date(initialStartDate.setHours(18, 0, 0));
		}
	}

	next () {
		if (!this.validator.validate()) {
			this.validator.showValidationState();
			useNotif.danger('Veuillez remplir les champs obligatoires');
			return;
		}
		this.props.order.asideActivePane = 4;
		this.emits('current', 4);
	}
}
