import { Reactive } from '@/utils/decorators';
import BaseEntity from '../BaseEntity';
import OrderScheduleEntity from './OrderScheduleEntity';
import ScheduleOrderRangeEntity from './ScheduleOrderRangeEntity';
import OrderTempWorkerEntity from './OrderTempWorkerEntity';
import OrderEntity from './OrderEntity';
import { TAG_LINK_TYPE } from '@/typings/enums/TAG_LINK_TYPE';
import { ORDER_STATUS } from '@/typings/enums/ORDER_STATUS';
import { itemIs } from '@orion.ui/orion';
import OrderSpecificScheduleEntity from './OrderSpecificScheduleEntity';

export default abstract class BaseOrderEntity<T extends Armado.OrderCommon> extends BaseEntity<T> {

	// eslint-disable-next-line local-rules/state-are-private-readonly
	@Reactive protected readonly selfState = {
		scheduleDefaultEntities: [] as OrderScheduleEntity[],
		scheduleOrderRangesEntities: [] as ScheduleOrderRangeEntity[],
	};

	isTempWorkerOrder = (): this is OrderTempWorkerEntity => false;
	isCompanyOrder = (): this is OrderEntity => false;

	get isCanceled () { return this.state.status === ORDER_STATUS.Canceled; }
	get gearIds () { return this.state.gears?.mapKey('id') ?? []; }
	get justificatoryIds () { return this.state.justificatories?.mapKey('id') ?? []; }
	get languageIds () { return this.state.languages?.mapKey('id') ?? []; }
	get missionPlaceId () {return this.state.missionPlace?.id; }
	get motiveId () {return this.state.motive?.id; }
	get professionalSkillId () { return this.state.professionalSkill?.id; }
	get status () { return this.state.status; }
	get tagLinkType () { return TAG_LINK_TYPE.Order; }
	get orderProfessionalSkillsProfessionalSkillIds () { return this.state.orderProfessionalSkillsProfessionalSkill?.mapKey('id') ?? []; }

	get needVehicleLabel () {
		return this.state.needPersonalVehicle
			? `Requis`
			: `Non requis`;
	}

	get start () { return this.state.start ? new Date(this.state.start) : new Date(); }
	set start (val) { this.state.start = val.toPost(true);}

	get end () { return this.state.end ? new Date(this.state.end) : new Date(); }
	set end (val) { this.state.end = val.toPost(true); }

	get clientCompanyName () { return this.state.clientCompanyName; }
	set clientCompanyName (val) { this.state.clientCompanyName = val; }



	get clientCompanyId () { return this.state.clientCompanyId; }
	set clientCompanyId (val) { this.state.clientCompanyId = val; }

	get languages () { return this.state.languages; }
	set languages (val) { this.state.languages = val; }

	get justificatories () { return this.state.justificatories ?? []; }
	set justificatories (val) {this.state.justificatories = val; }

	get gears () { return this.state.gears ?? []; }
	set gears (val) { this.state.gears = val; }

	get needPersonalVehicle () { return this.state.needPersonalVehicle; }
	set needPersonalVehicle (val) { this.state.needPersonalVehicle = val; }

	get jobDescription () { return this.state.jobDescription; }
	set jobDescription (val) { this.state.jobDescription = val; }

	get hourlyRate () { return this.state.hourlyRate; }
	set hourlyRate (val) { this.state.hourlyRate = val; }

	get address () { return this.state.address; }
	set address (val) { this.state.address = val; }

	get city () { return this.state.city; }
	set city (val) { this.state.city = val; }

	get latitude () { return this.state.latitude; }
	set latitude (val) { this.state.latitude = val; }

	get longitude () { return this.state.longitude; }
	set longitude (val) { this.state.longitude = val; }

	get countryCode () { return this.state.countryCode; }
	set countryCode (val) { this.state.countryCode = val; }

	get postalCode () { return this.state.postalCode; }
	set postalCode (val) { this.state.postalCode = val; }

	get missionPlace () { return this.state.missionPlace; }
	set missionPlace (val) { this.state.missionPlace = val; }

	get professionalSkill () { return this.state.professionalSkill; }
	set professionalSkill (val) { this.state.professionalSkill = val; }

	get orderProfessionalSkillsProfessionalSkill () { return this.state.orderProfessionalSkillsProfessionalSkill ?? []; }
	set orderProfessionalSkillsProfessionalSkill (val) { this.state.orderProfessionalSkillsProfessionalSkill = val; }

	get orderInterlocutor () { return this.state.orderInterlocutor; }
	set orderInterlocutor (val) { this.state.orderInterlocutor = val; }

	get motive () { return this.state.motive; }
	set motive (val) { this.state.motive = val; }

	get justificationMotive () { return this.state.justificationMotive; }
	set justificationMotive (val) { this.state.justificationMotive = val; }

	get scheduleDefaultsEntities () { return this.selfState.scheduleDefaultEntities; }
	get scheduleOrderRangesEntities () { return this.selfState.scheduleOrderRangesEntities; }

	set scheduleOrderRangesEntities (val: ScheduleOrderRangeEntity[]) { this.selfState.scheduleOrderRangesEntities = val; }
	set scheduleDefaults (val: IScheduleDefaultModel[]) { this.state.scheduleDefaults = val; }
	set scheduleOrderRanges (val: IScheduleOrderRangeModel[]) { this.state.scheduleOrderRanges = val;}

	getScheduleDefaultEntity (weekday: number) {
		return this.selfState.scheduleDefaultEntities.findByKey(weekday, 'weekDay');
	}

	getScheduleOrderRangeEntity (daterange: Orion.DateRange) {
		return this.selfState.scheduleOrderRangesEntities.
			find(x => x.startRange === daterange.start?.toPost(true) && x.endRange === daterange.end?.toPost(true));
	}

	duplicateDay (schedule: OrderSpecificScheduleEntity | OrderScheduleEntity, allPeriod = false, specific?: boolean) {

		if (specific && itemIs<OrderSpecificScheduleEntity>(schedule)) {
			const range = this.scheduleOrderRangesEntities.find((x) => {
				return x.scheduleOrderEntities.findByKey(schedule.id, 'id');
			});

			if (!range) return;
			const diff = Math.floor(new Date(range.endRange).getTime() - new Date(range.startRange).getTime()) / (1000 * 3600 * 24);

			const tab = range.scheduleOrderEntities;

			for (let i = 0; i <= diff; i++) {
				const date = new Date(range.startRange).toMidnight();
				date.setDate(date.getDate() + i);

				if (date === new Date(schedule.date)) continue;
				if (!allPeriod && date.getDay() !== new Date(schedule.date).getDay()) continue;
				if (date > new Date(range.endRange))
					return;

				const entity = range.scheduleOrderEntities.filter(x => x.weeknumber === date.getWeekNumber()).findByKey(date.getDay(), 'weekDay');

				if (entity && !entity.isActive) continue;

				if (!entity) {
					const newEntity = new OrderSpecificScheduleEntity({
						id: 0,
						weekDay: date.getDay(),
						date: date.toPost(true),
						scheduleOrderTimeSlots: schedule.scheduleOrderTimeSlots.map((x) => {
							const newDate = date;
							x.startTime = new Date(newDate.setHours(new Date(x.startTime).getHours(), new Date(x.startTime).getMinutes(), 0)).toPost(true);
							x.endTime = new Date(newDate.setHours(new Date(x.endTime).getHours(), new Date(x.endTime).getMinutes(), 0)).toPost(true);
							return x;
						}),
					});
					tab.push(newEntity);
				} else {
					entity.scheduleOrderTimeSlots = schedule.scheduleOrderTimeSlots.map((x) => {
						const newDate = date;
						x.startTime = new Date(newDate.setHours(new Date(x.startTime).getHours(), new Date(x.startTime).getMinutes(), 0)).toPost(true);
						x.endTime = new Date(newDate.setHours(new Date(x.endTime).getHours(), new Date(x.endTime).getMinutes(), 0)).toPost(true);
						return x;
					});
				}

			}
			range.emptyOrderSpecificScheduleEntity();
			tab.forEach(x => range.pushOrderSpecificScheduleEntity(x));
		} else if (!specific && itemIs<OrderScheduleEntity>(schedule)) {
			this.scheduleDefaultsEntities.forEach((entity, index) => {
				const date = new Date(this.start).toMidnight();
				date.setDate(new Date(this.start).getDate() + index);
				entity.scheduleDefaultTimeSlots = schedule.scheduleDefaultTimeSlots.map((x) => {
					x.startTime = new Date(date.setHours(new Date(x.startTime).getHours(), new Date(x.startTime).getMinutes(), 0)).toPost(true);
					x.endTime = new Date(date.setHours(new Date(x.endTime).getHours(), new Date(x.endTime).getMinutes(), 0)).toPost(true);
					return x;
				});
			});
		}

	}
}
