<template>
	<div class="order-step">
		<o-section id="order-step-2">
			<h4 class="order-step__title">
				<span class="text--brand">
					Étape 2
				</span> - Quel est votre besoin ?
			</h4>

			<div class="row row--grid">
				<div class="col-sm-6">
					<o-input
						ref="number"
						v-model.number="order.requiredTempWorker"
						prefix-font-icon="icon-user-single-neutral"
						mask="integer"
						label="Nombre d'intérimaires"
						:min-value="1"
						:max-value="99"
						required/>
				</div>
				<div class="col-sm-6">
					<o-input
						v-model="order.hourlyRate"
						:min-value="0"
						:max-value="setup.isEuroCurrency ? 99 : 9999"
						prefix-font-icon="icon-money-cash-file-dollar"
						:label="`Taux horaire en ${setup.currency}`"
						mask="decimal"/>
				</div>
				<div class="col-sm-6">
					<o-select
						ref="qualif"
						v-model="order.professionalSkill"
						prefix-font-icon="icon-programming-script-2"
						label="Qualification"
						:options="setup.qualificationOptions"
						track-key="id"
						display-key="label"
						:custom-search="setup.customSearchQualification"
						searchable
						required>
						<template #item="{ data, markedSearch }">
							<div v-html="markedSearch(`${data.label}`)"/>
						</template>
					</o-select>
				</div>
				<div class="col-sm-6">
					<o-select
						ref="qualif"
						v-model="order.orderProfessionalSkillsProfessionalSkill"
						prefix-font-icon="icon-programming-script-2"
						label="Qualifications secondaires"
						:options="setup.qualificationOptions"
						track-key="id"
						display-key="label"
						:custom-search="setup.customSearchQualification"
						searchable
						multiple>
						<template #item="{ data, markedSearch }">
							<div v-html="markedSearch(`${data.label}`)"/>
						</template>
					</o-select>
				</div>
				<div class="col-sm-6">
					<o-select
						ref="languages"
						v-model="order.languages"
						searchable
						label="Langues"
						track-key="id"
						display-key="name"
						prefix-font-icon="icon-travel-map-earth-2"
						multiple
						:options="setup.languageOptions"/>
				</div>

				<div class="col-sm-6">
					<o-select
						v-model="order.motive"
						label="Motif"
						track-key="id"
						clearable
						display-key="motive"
						prefix-font-icon="icon-interface-file-clipboard-text"
						:options="setup.motiveOptions"/>
				</div>
				<div class="col-sm-12">
					<o-textarea
						v-model="order.justificationMotive"
						:max-length="400"
						:validation="setup.validator.rule('justificationMotive')"
						:required="!!order.motive?.id"
						label="Justificatif"/>
				</div>
			</div>

			<o-checkbox
				v-model="order.needPersonalVehicle"
				label="L'intérimaire doit disposer de son propre moyen de locomotion"/>

			<o-checkbox
				v-model="setup.isRequiredGears"
				label="L'intérimaire doit disposer d'EPI"/>

			<shared-select-picto-card
				v-if="setup.isRequiredGears"
				v-model="order.gears"
				:required="setup.isRequiredGears"
				:options="setup.epiOptions"/>

			<o-checkbox
				v-if="setup.self.isAgency()"
				v-model="setup.isRequiredJustificatories"
				label="L'intérimaire doit disposer de documents spécifiques"/>
			<shared-select-picto-card
				v-if="setup.isRequiredJustificatories && setup.self.isAgency()"
				v-model="order.justificatories"
				:required="setup.isRequiredJustificatories"
				:options="setup.justificatoriesOptions"/>
		</o-section>

		<o-section
			v-if="order.asideActivePane !== 6"
			align="center">
			<o-button
				prefix-font-icon="icon-interface-arrows-button-left"
				outline
				@click="$emit('current', 1)">
				Précédent
			</o-button>
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				outline
				@click="setup.next()">
				Suivant
			</o-button>
		</o-section>

		<o-section
			v-else
			align="center">
			<o-button
				suffix-font-icon="icon-interface-arrows-right"
				color="success"
				@click="setup.next(6)">
				Valider
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import OrderCreationStep2SetupService from '@/setup/order/creation/OrderCreationStep2SetupService';
import { inject } from 'vue';
type Emits = { (e: 'current' | 'origin', val: number): void }
const emits = defineEmits<Emits>();
const props = defineProps(OrderCreationStep2SetupService.props);
const _aside = inject<OrionAside>('_aside');
const setup = new OrderCreationStep2SetupService(props, emits, _aside);
</script>
