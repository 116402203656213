import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/ordertemplate');

class OrderTemplateApiClient {

	static list (orderTemplateList: IOrderTemplateListParameters) {
		return client.get<IOrderTemplateModel[]>('list', { params: orderTemplateList });
	}
}

export default OrderTemplateApiClient;
