import ApiClient from '@/api/ApiClient';

const client = new ApiClient('justificatory');

class JustificatoryApi {
	static list () {
		return client.get<IJustificatoryModel[]>('');
	}
}

export default JustificatoryApi;
