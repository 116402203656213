import ApiClient from '@/api/ApiClient';

const ROUTE = 'professionalskill';
const client = new ApiClient(ROUTE);

class ProfessionalSkillApi {

	static list () {
		return client.get<IProfessionalSkillModel[]>('');
	}

}

export default ProfessionalSkillApi;
