import { PropType, nextTick, reactive, ref, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrderEntity from '@/entity/order/OrderEntity';
import { isEqual } from 'lodash-es';
import OrderTempWorkerEntity from '@/entity/order/OrderTempWorkerEntity';
import useOrderService from '@/services/OrderService';

type Props = SetupProps<typeof OrderDetailsSpecificScheduleModalSetupService.props>;

export default class OrderDetailsSpecificScheduleModalSetupService extends BaseSetupService<Props> {
	static readonly props = {
		order: {
			type: Object as PropType<OrderEntity | OrderTempWorkerEntity>,
			default: undefined,
		},
	};

	private readonly state = reactive({
		showDatepicker: false,
		dataLoaded: false,
		daterange: undefined as Undef<Orion.DateRange>,
		order: new OrderEntity(),
		clone: undefined as Undef<OrderEntity | OrderTempWorkerEntity>,
	});

	readonly _periodRange = ref<OrionDatepicker>();
	readonly _modal?: OrionModal;

	get clone () { return this.state.clone; }
	get order () { return this.props.order ?? this.state.order;}

	get dataLoaded () { return this.state.dataLoaded; }

	get showDatepicker () { return this.state.showDatepicker; }
	set showDatepicker (val) { this.state.showDatepicker = val; }

	get daterange () { return this.state.daterange; }
	set daterange (val) { this.state.daterange = val; }

	get orderHasChanged () {
		return !!this.clone && !isEqual(this.order?.scheduleOrderRangesEntities, this.clone?.scheduleOrderRangesEntities);
	}

	constructor (props?: Props, modal?: OrionModal) {
		super(props);
		this._modal = modal;


		watch(() => this.state.daterange, (val) => {
			if (val?.end && !val.selecting && this.clone?.isCompanyOrder()) {
				this.clone?.addSpecificSchedule(val);
				this.state.showDatepicker = false;
			}
		});
	}

	protected async onBeforeMountAsync () {
		if (!this.props.order && this.route.params.id) {
			const res = await useOrderService().getDetailedAsync(+this.route.params.id);
			if (res)
				this.state.order.assignData(res);
		}

		this.state.clone = this.order?.clone();
		this.state.dataLoaded = true;
	}

	togglePeriodSelection () {
		this.showDatepicker = !this.showDatepicker;
		nextTick(() => this._periodRange.value?.focus());
	}

	async saveChangesAsync () {
		if (!this.clone) return;
		this._modal?.trigger('updated', this.clone?.scheduleOrderRangesEntities.mapKey('entityState'));
	}
}
