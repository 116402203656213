import CompanySettingApi from '@/api/CompanySettingApi';
import { useNotif } from '@orion.ui/orion';
import { debounce } from 'lodash-es';
import { reactive } from 'vue';
import useSelfService from './SelfService';
import { SETTING_TYPE } from '@/typings/enums/SETTING_TYPE';
import AjaxCooler from '@/utils/AjaxCooler';
import { USER_TYPE } from '@/typings/enums/USER_TYPE';
import CompanySettingApiClient from '@/api/CompanySettingApiClient';


class CompanySettingsService {
	private readonly state = reactive({
		companyId: undefined as Undef<number>,
		showNotifDataSaved: debounce(() => {
			useNotif.success('Vos paramètres ont bien été sauvegardés');
		}, 2000),
		companiesOptions: [] as number[],
	});

	private currencyCooler = new Map<number, AjaxCooler<ICompanySettingModel | undefined>>();

	get companyId () { return this.state.companyId;}
	get companiesOptions () { return this.state.companiesOptions;}

	get companySettingsApi () {
		return useSelfService().self.userType === USER_TYPE.Client
			? CompanySettingApiClient
			: CompanySettingApi;
	}

	setCompanyId (val?: number) {
		this.state.companyId = val;
	}

	setCompaniesOptions (val: number[]) {
		this.state.companiesOptions = val;
	}

	async updateSettingAsync (value: string | string[], settingType: SETTING_TYPE) {
		if (!this.state.companyId)
			return;
		try {
			await CompanySettingApi.saveSetting({
				userId: useSelfService().self.id,
				companyId: this.state.companyId,
				settingType,
				value: settingType === SETTING_TYPE.PaymentTypeAdvancedPayment
					? JSON.stringify(value)
					: String(value),
			});

			this.state.showNotifDataSaved();
		} catch {
			useNotif.danger(`Nous n'avons pas pu sauvegarder votre paramètrage, réessayez plus tard ou contactez notre support.`);
		}
	}

	async selectSettingAsync (...args: Parameters<typeof CompanySettingApi.selectSetting>) {
		const res = await this.companySettingsApi.selectSetting(...args);
		return res?.data;
	}

	async getCurrencyAsync (companyId?: number, hourly = true): Promise<string> {
		if (!companyId) return '';

		if (!this.currencyCooler.has(companyId)) {
			this.currencyCooler.set(companyId, new AjaxCooler(this.companySettingsApi.selectSetting, 60*1000));
		}

		const res = await this.currencyCooler.get(companyId)!.coolAsync(false, SETTING_TYPE.Currency, companyId);

		return hourly ? ` ${res?.value ?? ''}/h` : ` ${res?.value}` ?? '';
	}
}

const companySettingsServiceSingleton = new CompanySettingsService();

export default function useCompanySettingsService () {
	return companySettingsServiceSingleton;
}
