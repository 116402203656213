import { PropType, reactive, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import CompanyApiAgency from '@/api/CompanyApiAgency';
import CompanyApiClient from '@/api/CompanyApiClient';
import CompanyApi from '@/api/CompanyApi';
import SelfEntity from '@/entity/SelfEntity';

type Props = SetupProps<typeof SharedSelectCompanySetupService.props>

type Emits = {
	(e: 'update:modelValue' | 'input', id: Undef<number | number[]>): void,
	(e: 'select', companyModel: ICompanyModel | [ICompanyModel]): void,
	(e: 'loaded'): void,
}

type CompanyElement = ICompanyWithCheckPluginOrderModel & {
	disabled: boolean
}

export default class SharedSelectCompanySetupService extends BaseSetupService<Props> {
	static readonly props = {
		checkOrderPlugin: Boolean,
		hideAgencyName: Boolean,
		setToFirst: Boolean,
		clearable: {
			type: Boolean,
			default: true,
		},
		modelValue: {
			type: Number,
			default: undefined,
		},
		permissionAction: {
			type: Number as PropType<PERMISSION_ACTION>,
			default: undefined,
		},
		type: {
			type: String as PropType<InstanceType<typeof SelfEntity>['readableUserType']>,
			default: undefined,
		},
		agencyCompanyId: {
			type: Number,
			default: undefined,
		},
	};

	private emits: Emits;
	private readonly state = reactive({ companies: [] as Partial<CompanyElement>[] });


	get companies () { return this.state.companies;}
	get searchable () { return this.companies && this.companies.length >= 10; }
	get label () { return this.props.type === 'client' ? 'Client' : 'Agence'; }

	get publicInstance () {
		return { getOptions: () => this.companies as ICompanyWithCheckPluginOrderModel[] };
	}


	get vModel () { return this.props.modelValue; };
	set vModel (val) { this.emits('update:modelValue', val); };


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;

		watch(() => this.props.agencyCompanyId, (val) => {
			if (val) this.vModel = undefined;
			this.onBeforeMountAsync();
		});
	}

	async onBeforeMountAsync () {
		if (this.props.type === 'client' && this.self.isAgency()) {
			const { data } = await CompanyApiAgency.clientList({
				checkOrderPlugin: false,
				agencyCompanyId: this.props.agencyCompanyId,
			});
			this.state.companies = data;
		} else if (this.props.type === 'agency' && this.self.isClient()) {
			const { data } = await CompanyApiClient.getAgencyList(
				this.props.permissionAction,
				this.props.checkOrderPlugin,
			);
			this.state.companies = data;
		} else {
			const { data } = await CompanyApi.get({
				permissionAction: this.props.permissionAction,
				checkOrderPlugin: this.props.checkOrderPlugin,
			});
			this.state.companies = data;
		}

		if (this.props.setToFirst) {
			const first = this.companies?.first();
			if (first?.id) {
				this.emits('update:modelValue', first.id);
				this.emits('input', first.id);
			}
		}

		this.emits('loaded');
	}
}
