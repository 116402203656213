import ApiClient from './ApiClient';

const client = new ApiClient('agency/scheduleOrderRange');

class ScheduleOrderRangeApi {

	static add (addMultipleScheduleOrderRangeModels : IAddMultipleScheduleOrderRangeModel[]) {
		return client.post<IScheduleOrderRangeModel>('', addMultipleScheduleOrderRangeModels);
	}

	static deleteByOrderId (id: number) {
		return client.delete<void>(`${id.toString()}/orderId`);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

	static update (addMultipleScheduleOrderRangeModels : IUpdateMultipleScheduleOrderRangeModel) {
		return client.put<void>('', addMultipleScheduleOrderRangeModels);
	}
}
export default ScheduleOrderRangeApi;
