import MotiveApi from '@/api/MotiveApi';

class MotiveService {
	async listAsync (...args: Parameters<typeof MotiveApi.list>) {
		const { data } = await MotiveApi.list(...args);
		return data.sort((a, b) => (a.motive ?? '').localeCompare(b.motive ?? ''));
	}
}

export default function useMotiveService () {
	return new MotiveService();
}
