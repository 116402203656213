<template>
	<div
		v-if="order.id"
		class="modal-modify-order">
		<o-select
			v-if="tags === 'skills'"
			v-model="order.professionalSkill"
			:validation="setup.validator.rule('professionalSkill')"
			label="Modification des qualifications requises"
			track-key="id"
			display-key="label"
			searchable
			:options="setup.professionalSkillsOptions"
			autofocus/>
		<o-select
			v-if="tags === 'secondarySkills'"
			v-model="order.orderProfessionalSkillsProfessionalSkill"
			label="Modification des qualifications secondaires"
			track-key="id"
			multiple
			display-key="label"
			searchable
			:options="setup.professionalSkillsOptions"
			autofocus/>
		<o-select
			v-if="tags === 'languages'"
			v-model="order.languages"
			required
			searchable
			label="Modification des langues requises"
			multiple
			track-key="id"
			display-key="name"
			:options="setup.languagesOptions"
			autofocus/>

		<div
			v-if="tags === 'description'">
			<o-select
				v-model="setup.jobDescriptionId"
				class="modal-modify-order__select-template"
				track-key="id"
				searchable
				clearable
				display-key="id"
				value-key="id"
				label="Choix à partir d'un modèle de description de poste"
				:options="setup.jobDescriptionOptions"
				@input="setup.selectDescriptionTemplate()"
				@clear="setup.clearDescriptionTemplate()">
				<template #option="{ item }">
					<div class="modal-modify-order__select-item">
						<div class="modal-modify-order__item-description">
							<strong> {{ `${item.title}` }} </strong>
							- {{ item.content }}
						</div>
						<o-label
							v-for="skill in item.professionalSkills"
							:key="skill.id"
							outline
							style="margin-top:0.3rem; margin-left:0.3rem"
							:color="skill.id === order.professionalSkill?.id ? 'info' : 'default'">
							{{ skill.label }}
						</o-label>
					</div>
				</template>
				<template
					v-if="setup.descriptionTemplateSelected"
					#value="{ item }">
					<div v-if="item">
						<strong> {{ item.title }} </strong>
						<o-chips
							v-for="skill in item.professionalSkills"
							:key="skill.id"
							outline
							size="sm"
							style="margin-left:0.3rem">
							{{ skill.label }}
						</o-chips>
					</div>
				</template>
			</o-select>

			<div class="col-md-12">
				<o-textarea
					v-model="order.jobDescription"
					label="Description du poste"/>
			</div>
		</div>

		<o-textarea
			v-if="tags === 'comment'"
			v-model="order.comment"
			label="Modification du commentaire"
			autofocus/>

		<o-input
			v-if="tags === 'hourlyRate'"
			v-model="order.hourlyRate"
			required
			:validation="setup.validator.rule('hourlyRate')"
			:min-value="1"
			label="Modification du taux horaire"
			mask="decimal"
			autofocus/>
		<order-creation-schedule
			v-if="tags === 'scheduleDefault'"
			:order="order"/>
		<div
			v-if="tags === 'date'"
			class="row row--grid">
			<o-datepicker
				v-model="order.start"
				required
				:min-date="new Date()"
				time
				:validation="setup.validator.rule('start')"
				class="col-xs-6"
				label="Modifier la date de début"/>
			<o-datepicker
				v-model="order.end"
				required
				:validation="setup.validator.rule('end')"
				class="col-xs-6"
				time
				:min-date="order.start"
				label="Modifier la date de fin"/>
		</div>
		<div
			v-if="tags === 'address'"
			class="order-address">
			<template v-if="!!order.missionPlace?.id">
				<o-textarea
					v-model="setup.missionPlaceForAgency"
					label="Lieu de mission"
					disabled/>
				<span>ou</span>
			</template>
			<div class="row row--grid">
				<shared-google-auto-complete
					v-model="order.address"
					required
					class="col-xs-12"
					:validation="setup.validator?.rule('address')"
					@google-found="setup.handleGoogleFound($event)"/>
				<template v-if="!order.missionPlace">
					<div
						v-show="order.address"
						class="col-sm-4">
						<o-select
							v-show="order.address"
							v-model="order.countryCode"
							label="Pays"
							track-key="code"
							value-key="code"
							display-key="name"
							required
							searchable
							:options="setup.countries"
							@input="setup.switchingCountry()"/>
					</div>

					<div
						v-show="order.countryCode"
						class="col-sm-4">
						<o-input
							v-model="order.postalCode"
							required
							label="Code postal"
							:max-length="order.countryCode === 'FR' ? 5 : 10"
							@input="setup.getCitiesAsync(order.postalCode)"/>
					</div>

					<div
						v-show="order.countryCode"
						class="col-sm-4">
						<o-select
							v-if="order.countryCode === 'FR' && setup.cityOptions.length"
							:ref="setup._citySelect"
							v-model="order.city"
							required
							label="Ville"
							track-key="code"
							display-key="nom"
							value-key="nom"
							searchable
							:options="setup.cityOptions"/>
						<o-input
							v-else
							v-model="order.city"
							required
							label="Ville"/>
					</div>
				</template>
			</div>
		</div>
		<o-input
			v-if="tags === 'numberRequired'"
			v-model="order.requiredTempWorker"
			:validation="setup.validator.rule('requiredTempWorker')"
			required
			mask="integer"
			label="Modifiez le nombre d'intérimaire(s) requis"
			autofocus/>
		<div
			v-if="tags === 'interlocutor'">
			<div
				class="row row--grid">
				<div class="col-sm-6">
					<o-input
						v-model="setup.interlocutor.lastName"
						:validation="setup.validator?.rule('lastName')"
						label="Nom"/>
				</div>
				<div
					class="col-sm-6">
					<o-input
						v-model="setup.interlocutor.firstName"
						:validation="setup.validator?.rule('firstName')"
						label="Prénom"/>
				</div>
			</div>
			<div class="row row--grid">
				<div class="col-sm-6">
					<o-input
						v-model="setup.interlocutor.email"
						:validation="setup.validator?.rule('email')"
						type="email"
						label="Adresse email"/>
				</div>
				<div class="col-sm-6">
					<o-phone
						v-model="setup.interlocutorPhoneNumber"
						:validation="order.orderInterlocutor?.telephone ? setup.validator.rule('telephone') : undefined"
						label="Téléphone"/>
				</div>
			</div>
		</div>

		<div
			v-if="tags === 'motive'"
			class="flex fd-c g-sm">
			<o-select
				v-model="order.motive"
				label="Motif"
				track-key="id"
				clearable
				display-key="motive"
				prefix-font-icon="icon-interface-file-clipboard-text"
				:options="setup.motiveOptions"/>
			<o-textarea
				v-model="order.justificationMotive"
				:max-length="400"
				:validation="setup.validator.rule('justificationMotive')"
				:required="!!order.motive?.id"
				label="Justificatif"/>
		</div>

		<o-alert
			v-show="setup.showError && order.users?.length"
			style="margin-bottom: 0.6rem;"
			color="warning"
			title="Attention">
			Vous avec actuellement validé {{ 'intérimaire'.pluralize(setup.validetedUsers) }} pour cette commande. <br>
			Veuillez supprimer un intérimaire des candidats validés avant de poursuivre la modification.
		</o-alert>

		<o-section
			align="right"
			class="modal-modify-order__actions">
			<o-button
				v-if="tags === 'interlocutor'"
				color="danger"
				outline
				@click="setup.deleteInterlocutor()">
				Supprimer l'interlocuteur
			</o-button>
			<o-button
				color="danger"
				nude
				@click="setup.cancel()">
				Annuler
			</o-button>
			<o-button
				color="brand"
				@click="setup.confirm()">
				Modifier
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import OrderModifyModalSetupService from '@/setup/order/OrderModifyModalSetupService';
import OrderCreationSchedule from '@/components/order/creation/OrderCreationSchedule.vue';
import { inject } from 'vue';
const props = defineProps(OrderModifyModalSetupService.props);
const _modal = inject<OrionModal>('_modal');
const setup = new OrderModifyModalSetupService(props, _modal);
</script>

<style lang="less">
@import '@/styles/mixins';

.modal-modify-order {
  .row--grid {
    margin-bottom: 2.5rem;
  }

  .order-address {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0.9rem;

    > :nth-child(2) {
      align-self: center;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__select-item {
    width: 37rem;
  }

  &__item-description {
    .ellipsis();
    max-height: 100%;
  }
}
</style>
