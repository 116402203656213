import { PropType, reactive, watchEffect } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import type OrderEntity from '@/entity/order/OrderEntity';
import { cloneDeep, pick } from 'lodash-es';
import { Emits } from './OrderCreationAsideSetupService';
import useOrderService from '@/services/OrderService';
import useCompanySettingsService from '@/services/CompanySettingsService';

type Props = SetupProps<typeof OrderCreationRecapCardSetupService.props>
type CandidateToAdd = IOrderUserAvatarModel & {removed?: boolean}

export default class OrderCreationRecapCardSetupService extends BaseSetupService<Props> {
	static readonly props = {
		creating: Boolean,
		refreshing: Boolean,
		order: {
			type: Object as PropType<OrderEntity>,
			required: true as const,
		},
	};

	readonly _aside?: OrionAside;
	private emits: Emits;

	private readonly state = reactive({
		candidatesToAdd: [] as CandidateToAdd[],
		removedCandidates: [],
		removeAllCandidates: true,
		currency: '',
	});

	get removeAllCandidates () { return this.state.removeAllCandidates;}
	set removeAllCandidates (val) { this.state.removeAllCandidates = val;}
	get candidatesToAdd () { return this.state.candidatesToAdd; }
	get removedCandidates () { return this.state.removedCandidates; }
	get currency () { return this.state.currency; }

	get startHour () {
		return new Date(this.props.order.start).toReadable('$hh h $mm');
	}

	get endHour () {
		return new Date(this.props.order.end).toReadable('$hh h $mm');
	}

	get datesArePast () {
		return new Date(this.props.order.start) < new Date();
	}

	get gearsAreEmpty () {
		return (this.props.order.gears?.length === 0 || this.props.order.gears === null);
	}

	get justificatoriesAreEmpty () {
		return (this.props.order.justificatories?.length === 0 || this.props.order.justificatories === null);
	}

	get isValid () {
		return !!this.props.order.requiredTempWorker;
	}

	get location () {
		return this.props.order.missionPlace ?? pick(this.props.order, [
			'address',
			'city',
			'postalCode',
			'countryCode',
		]);
	}

	constructor (props: Props, emits: Emits, _aside?: OrionAside) {
		super(props);
		this.emits = emits;
		this._aside = _aside;
	}

	protected async onBeforeMountAsync (): Promise<void> {
		await super.onBeforeMountAsync();
		this.state.currency = await useCompanySettingsService().getCurrencyAsync(this.props.order?.agencyCompanyId);

		watchEffect(async () => this.state.currency = await useCompanySettingsService().getCurrencyAsync(this.props.order?.agencyCompanyId));
	}

	protected onMounted () {
		if (this.props.order.users && this.self.isAgency()) {
			this.setCandidateList();
		}
	}

	async submitAsync () {
		if (this.props.order.professionalSkill)
			this.props.order.professionalSkill.id = this.props.order.professionalSkill.id;

		if (this.self.isClient()) this.props.order.warnClient = true;
		//this.props.order.tempWorkerUserIds = this.candidatesToAdd.filter(x => x.removed === false).map(x => x.userId);
		this._aside?._loader()?.show(`Création de la commande...`);

		try {
			const data = await useOrderService().addOrderAsync({
				...this.props.order.publicState,
				orderInterlocutor: this.props.order.orderInterlocutor?.firstName ? this.props.order.orderInterlocutor : undefined,
				warnClient: this.self.isClient() ? true : !!this.props.order.warnClient,
				tempWorkerUserIds: this.candidatesToAdd.filter(x => !x.removed).mapKey('userId'),
			} as unknown as IAddOrderForAgencyModel /* TODO: remove type after merge OrderV2 */);
			if (this._aside) {
				this._aside.trigger('save', data);
				this._aside.trigger('reset', data);
			}
			this._aside?.close();

			if (data?.id) {
				const shouldReloadWindow = /^OrderDetails/.test(this.route.name?.toString() ?? '');

				await this.router.push({
					name: 'OrderDetails',
					params: { id: data.id },
				});

				if (shouldReloadWindow) window.location.reload();
			}
		} finally {
			this._aside?._loader()?.hide();

		}
	}

	toggleCandidates () {
		this.candidatesToAdd.forEach(x => x.removed = this.state.removeAllCandidates);
		this.state.removeAllCandidates = !this.state.removeAllCandidates;
	}

	setCandidateList () {
		this.state.candidatesToAdd = cloneDeep(this.props.order.users) ?? [];
		this.state.candidatesToAdd.forEach(x => x.removed = false);
	}
}
