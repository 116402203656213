import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import type OrderEntity from '@/entity/order/OrderEntity';
import useOrderService from '@/services/OrderService';
import { useNotif, useValidation } from '@orion.ui/orion';
import { Emits } from './OrderCreationAsideSetupService';
import useMotiveService from '@/services/MotiveService';
import useCompanySettingsService from '@/services/CompanySettingsService';

type Props = SetupProps<typeof OrderCreationStep2SetupService.props>


export default class OrderCreationStep2SetupService extends BaseSetupService<Props> {
	static readonly props = {
		order: {
			type: Object as PropType<OrderEntity>,
			required: true as const,
		},
	};

	readonly _aside?: OrionAside;
	private emits: Emits;

	private readonly state = reactive({
		qualificationOptions: [] as IProfessionalSkillModel[],
		justificatoriesOptions: [] as IJustificatoryModel[],
		languageOptions: [] as ILanguageModel[],
		motiveOptions: [] as IMotiveModel[],
		epiOptions: [] as IGearModel[],
		isRequiredGears: false,
		isRequiredJustificatories: false,
		currency: '',
	});

	get qualificationOptions () { return this.state.qualificationOptions; }
	get justificatoriesOptions () { return this.state.justificatoriesOptions; }
	get languageOptions () { return this.state.languageOptions; }
	get epiOptions () { return this.state.epiOptions; }
	get motiveOptions () { return this.state.motiveOptions; }
	get isRequiredGears () { return this.state.isRequiredGears; }
	set isRequiredGears (val) { this.state.isRequiredGears = val; }
	get isRequiredJustificatories () { return this.props.order.justificatories.length > 0 || this.state.isRequiredJustificatories; }
	set isRequiredJustificatories (val) { this.state.isRequiredJustificatories = val; }
	get currency () { return this.state.currency; }
	get isEuroCurrency () { return this.state.currency.includes('€'); }


	get validationRules () {
		return {
			requiredTempWorker: 'required',
			professionalSkill: 'required',
			gears: this.isRequiredGears ?'required' : undefined,
			justificatories: this.isRequiredJustificatories ? 'required' : undefined,
			justificationMotive: () => {
				return this.props.order.motive?.id === undefined || (!!this.props.order.motive?.id && !!this.props.order.justificationMotive?.length);
			},
		};
	}

	readonly validator = useValidation(this.props.order, this.validationRules);


	constructor (props: Props, emits: Emits, aside?: OrionAside) {
		super(props);
		this._aside = aside;
		this.emits = emits;
	}

	async onBeforeMountAsync () {
		this._aside?._loader()?.show();
		this.state.qualificationOptions = await useOrderService().getQualificationsAsync();
		this.state.languageOptions = await useOrderService().getLanguagesAsync();
		this.state.epiOptions = await useOrderService().getGearsAsync();
		this.state.motiveOptions = await useMotiveService().listAsync();
		this.state.justificatoriesOptions = await useOrderService().getJustificatoriesAsync();
		this.state.currency = await useCompanySettingsService().getCurrencyAsync(this.props.order.agencyCompanyId);
		this._aside?._loader()?.hide();
		this.props.order.hourlyRate = 0;

		if (this.props.order.justificatories.length) {
			this.state.isRequiredJustificatories = true;
		}
		if (this.props.order.gears.length) {
			this.state.isRequiredGears = true;
		}
	}

	customSearchQualification (option: IQualificationModel, valueToSearch: string) {
		return option.label?.toLowerCase().indexOf(valueToSearch.toLowerCase()) !== -1
				|| option.code?.toLowerCase().indexOf(valueToSearch.toLowerCase()) !== -1;
	}

	next (step = 3) {
		if (!this.validator.validate()) {
			this.validator.showValidationState();
			useNotif.danger('Veuillez remplir les champs obligatoires');
			return;
		}
		this.props.order.asideActivePane = step;
		this.emits('current', step);
	}
}
