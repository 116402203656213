import { groupBy } from 'lodash-es';
import BaseEntity from '../BaseEntity';
import OrderSpecificScheduleEntity from './OrderSpecificScheduleEntity';
import { Reactive } from '@/utils/decorators';
import { pickFrom } from '@/utils/tools';
import { watch } from 'vue';

export default class ScheduleOrderRangeEntity extends BaseEntity<IScheduleOrderRangeModel> {

	// eslint-disable-next-line local-rules/state-are-private-readonly
	@Reactive protected readonly selfState = { scheduleOrderEntities: [] as OrderSpecificScheduleEntity[] };


	get startRange () { return this.state.startRange; }
	set startRange (val) { this.state.startRange = val; }
	get endRange () { return this.state.endRange; }

	get scheduleOrderEntities () { return this.selfState.scheduleOrderEntities ?? [];}

	get specificScheduleEntityGroupedByWeeknumber () {
		if (!this.selfState.scheduleOrderEntities) return;
		return groupBy(this.selfState.scheduleOrderEntities, 'weeknumber');
	}

	get entityState () {
		return {
			...pickFrom(
				this,
				'startRange',
				'endRange',
				'id'),
			scheduleOrders: this.selfState.scheduleOrderEntities.filter(x => x.isActive).mapKey('entityState'),
		};
	}

	constructor (payload?: IScheduleOrderRangeModel) {
		super(payload);

	}

	protected override onDataAssigned () {
		if (this.state.scheduleOrders.length && this.selfState) {
			this.selfState.scheduleOrderEntities = OrderSpecificScheduleEntity.map(this.state.scheduleOrders ?? []);
		}
	}

	protected onInstanceCreated (): void {
		watch(() => this.selfState.scheduleOrderEntities.mapKey('isActive'), () => {
			this.updateDateRange();
		});
	}

	updateDateRange () {
		const min = Math.min(...this.selfState.scheduleOrderEntities
			.filter(x => x.isActive).mapKey('entityState')
			.map(schedule => new Date(schedule.date).getTime()));

		const max = Math.max(...this.selfState.scheduleOrderEntities
			.filter(x => x.isActive).mapKey('entityState')
			.map(schedule => new Date(schedule.date).getTime()));

		if (isFinite(min) && isFinite(max)) {
			this.startRange = new Date(min).toPost();
			this.state.endRange = new Date(max).toPost();
		}
	}


	emptyOrderSpecificScheduleEntity () {
		this.selfState.scheduleOrderEntities = [];
	}

	pushOrderSpecificScheduleEntity (val: OrderSpecificScheduleEntity) {
		this.selfState.scheduleOrderEntities.push(val);
	}

}


