import ApiClient from '@/api/ApiClient';

const client = new ApiClient('tempworker/orderTempWorkerProposal');

class OrderTempWorkerProposalApiTempWorker {
	static update (updateOrderTempWorkerProposalModel: IUpdateOrderTempWorkerProposalModel) {
		return client.put<void>('', updateOrderTempWorkerProposalModel);
	}

	static grid (orderTempWorkerProposalPagedListParameters: IOrderTempWorkerProposalPagedListParameters) {
		return client.post<IPagedList<IOrderTempWorkerProposalListItemModel>>('grid', orderTempWorkerProposalPagedListParameters);
	}

	static getDetailed (id: number) {
		return client.get<IOrderTempWorkerProposalForOverPageModel>(id.toString());
	}

	static countWaiting () {
		return client.get<number>('waiting/count');
	}

	static link (orderId: number) {
		return client.get<number>(`link/${orderId}`);
	}
}

export default OrderTempWorkerProposalApiTempWorker;
