import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/discussion');

class DiscussionApiClient {

	static add (addDiscussionModel: IAddDiscussionClientModel) {
		return client.post<number>('', addDiscussionModel);
	}

	static grid (filter: IDiscussionPagedListParameters) {
		return client.post<IPagedList<IDiscussionItemModel>>('grid', filter);
	}

	static selectById (id: number) {
		return client.get<IDiscussionItemModel>(`${id}`);
	}

	static search (searchClientParameters: ISearchDiscussionForClientParameters) {
		return client.get<IDiscussionItemModel>('search', { params: searchClientParameters });
	}
}

export default DiscussionApiClient;
