import ApiClient from './ApiClient';

const client = new ApiClient('agency/scheduleDefault');

class ScheduleDefaultApiAgency {

	static add (addMultipleScheduleDefaultModels : IAddMultipleScheduleDefaultModel) {
		return client.post<IScheduleDefaultModel>('', addMultipleScheduleDefaultModels);
	}

	static deleteByOrderId (id: number) {
		return client.delete<void>(`${id.toString()}/orderId`);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

	static update (addMultipleScheduleDefaultModels : IAddMultipleScheduleDefaultModel) {
		return client.put<IScheduleDefaultModel>('', addMultipleScheduleDefaultModels);
	}
}
export default ScheduleDefaultApiAgency;
