import ProfessionalSkillApi from '@/api/ProfessionalSkillApi';

class ProfessionalSkillService {
	private get api () {
		return ProfessionalSkillApi;
	}

	async getAsync () :Promise<IProfessionalSkillModel[]> {
		const { data } = await this.api.list();
		return data;
	}

	async listAsync (...args: Parameters<typeof ProfessionalSkillApi.list>) {
		const { data } = await ProfessionalSkillApi.list(...args);
		return data;
	}
}

const professionalSkillServiceServiceSingleton = new ProfessionalSkillService();

export default function useProfessionalSkillService () {
	return professionalSkillServiceServiceSingleton;
}
