import { PropType, nextTick, reactive, ref, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import type OrderEntity from '@/entity/order/OrderEntity';
import { forEach } from 'lodash-es';
import { regexEmail, useCountry, useValidation } from '@orion.ui/orion';
import { ORDER_USER_STATUS } from '@/typings/enums/ORDER_USER_STATUS';
import GouvApi from '@/api/GouvApi';
import useLanguageService from '@/services/LanguageService';
import useProfessionalSkillService from '@/services/ProfessionalSkillService';
import useOrderToolboxJobDescriptionService from '@/services/order/OrderToolboxJobDescriptionService';
import { regexMobile, regexPhone } from '@/utils/tools';
import useMotiveService from '@/services/MotiveService';

type Props = SetupProps<typeof OrderModifyModalSetupService.props>
type Cities = { nom: string }

export default class OrderModifyModalSetupService extends BaseSetupService<Props> {
	static readonly props = {
		tags: {
			type: String,
			default: null,
		},
		order: {
			type: Object as PropType<OrderEntity>,
			required: true as const,
		},
	};

	readonly _modal?: OrionModal;
	readonly _citySelect = ref<OrionSelect>();

	private readonly state = reactive({
		isLoading: false,
		professionalSkillsOptions: [] as IProfessionalSkillModel[],
		languagesOptions: [] as ILanguageModel[],
		jobDescriptionOptions: [] as IOrderTemplateModel[],
		motiveOptions: [] as IMotiveModel[],
		jobDescriptionId: null,
		descriptionTemplateSelected: false,
		showError: false,
		orderAddress: undefined as Undef<string>,
		countries: [] as Orion.Country[],
		cityOptions: [] as Cities[],
		interlocutor: {} as IOrderInterlocutorModel,
		interlocutorPhoneNumber: {
			phoneNumber: this.props.order.orderInterlocutor?.telephone,
			phoneCountryCode: 'FR',
		},
	});

	validator = useValidation(this.props.tags !== 'interlocutor' ? this.props.order : this.interlocutor, this.validationRules);

	get showError () { return this.state.showError; }
	get professionalSkillsOptions () { return this.state.professionalSkillsOptions; }
	get languagesOptions () { return this.state.languagesOptions; }
	get jobDescriptionOptions () { return this.state.jobDescriptionOptions; }
	get motiveOptions () { return this.state.motiveOptions; }
	get descriptionTemplateSelected () { return this.state.descriptionTemplateSelected; }

	get jobDescriptionId () { return this.state.jobDescriptionId; }
	set jobDescriptionId (val) { this.state.jobDescriptionId = val;}

	get cityOptions () { return this.state.cityOptions; }
	get countries () { return this.state.countries; }

	get interlocutor () { return this.state.interlocutor; }
	set interlocutor (val) { this.state.interlocutor = val; }

	get interlocutorPhoneNumber () { return this.state.interlocutorPhoneNumber; }
	set interlocutorPhoneNumber (val) {
		this.state.interlocutorPhoneNumber = val;
		if (this.props.order.orderInterlocutor && val.phoneNumber !== '+33') {
			this.interlocutor.telephone = val.phoneNumber;
			this.props.order.orderInterlocutor.telephone = val.phoneNumber;
		}
	}

	get missionPlaceForAgency () {
		return this.props.order.missionPlace
			? `${this.props.order.missionPlace.address}\n${this.props.order.missionPlace.postalCode} ${this.props.order.missionPlace.city}`
			: undefined;
	}

	get validetedUsers () {
		return this.props.order.users?.map((x) => {
			x.status === ORDER_USER_STATUS.Validated;
		}).length ?? 0;
	}

	get numberRequiredMinValue () {
		let number = 0;
		this.props.order.users?.forEach((x) => {
			if (x.orderStepTitle === 'Validé')
				number+= 1;
		});
		return number ? number : 1;
	}


	constructor (props: Props, _modal?: OrionModal) {
		super(props);
		this._modal = _modal;

		watch(() => this.props.order.requiredTempWorker, (newValue) => {
			if (!newValue) return;
			if (!this.validetedUsers) {
				this.state.showError = false;
				return;
			}
			if (newValue < this.validetedUsers) this.state.showError = true;
			else this.state.showError = false;
		});


		watch(this.state.interlocutor, (val) => {
			this.props.order.orderInterlocutor = {
				...this.props.order.orderInterlocutor,
				...val,
			};
		});
	}


	protected async onBeforeMountAsync () {

		if (this.props.tags === 'skills' || 'secondarySkills') {
			this.state.isLoading = true;
			const data = await useProfessionalSkillService().listAsync();
			this.state.professionalSkillsOptions = data;
			this.state.isLoading = false;
		}
		if (this.props.tags === 'languages') {
			this.state.isLoading = true;
			const data = await useLanguageService().getLanguageListAsync();
			this.state.languagesOptions = data;
			this.state.isLoading = false;
		}

		if (this.props.tags === 'description') {
			this.state.isLoading = true;
			const data = await useOrderToolboxJobDescriptionService().listAsync({});
			if (data)
				this.state.jobDescriptionOptions = this.orderDescriptions(data);
			this.state.isLoading = false;
		}

		if (this.props.tags === 'address' && !this.props.order.missionPlace?.id) {
			this.state.countries = useCountry().countries;
			this.state.orderAddress = `${this.props.order.address}, ${this.props.order.postalCode} ${this.props.order.city}`;
		}

		if (this.props.tags === 'scheduleDefault') {
			this.props.order.setDefaultSchedule(false);
		}

		if (this.props.tags === 'interlocutor') {
			Object.assign(this.state.interlocutor, this.props.order.orderInterlocutor ?? {
				name: '',
				firstName: '',
				email: '',
				telephone: undefined,
			});
		}

		if (this.props.tags === 'motive') {
			this.state.motiveOptions = await useMotiveService().listAsync();
		}
	}

	get validationRules () {
		switch (this.props.tags) {
		case ('date'):
			return {
				start: 'required',
				end: 'required',
			};
		case ('numberRequired'):
			return { requiredTempWorker: () => { return this.props.order.requiredTempWorker > this.validetedUsers;} };
		case ('address'):
			return {
				address: 'required',
				countryCode: 'required',
				postalCode: 'required',
				city: this.props.order.countryCode ? 'required' : undefined,
			};
		case ('hourlyRate'):
			return { hourlyRate: 'required' };
		case ('skills'):
			return { professionalSkill: 'required' };
		case ('interlocutor'):
			return {
				lastName: () => {
					return (this.props.order.orderInterlocutor?.firstName?.length
						|| this.props.order.orderInterlocutor?.email?.length
						|| this.props.order.orderInterlocutor?.telephone?.length)
						? !!this.props.order.orderInterlocutor?.lastName?.length
						: false;
				},
				firstName: () => {
					return (this.props.order.orderInterlocutor?.lastName?.length
						|| this.props.order.orderInterlocutor?.email?.length
						|| this.props.order.orderInterlocutor?.telephone?.length)
						? !!this.props.order.orderInterlocutor?.firstName?.length
						: false;
				},
				email: () => {
					return this.props.order.orderInterlocutor?.email?.length
						? regexEmail.test(this.props.order.orderInterlocutor?.email)
						: true;
				},
				telephone: () => {
					return (this.props.order.orderInterlocutor?.telephone && this.props.order.orderInterlocutor.telephone?.length)
						? regexMobile.test(this.props.order.orderInterlocutor?.telephone) || regexPhone.test(this.props.order.orderInterlocutor?.telephone)
						: true;
				},
			};
		case ('motive'): {
			return {
				justificationMotive: () => {
					return !this.props.order.motive?.id || (this.props.order.motive?.id && !!this.props.order.justificationMotive);
				},
			};
		}

		default:
			return {};
		}
	}

	updateOrder () {
		this._modal?.trigger('updateOrder', this.props.order.publicState);
	}

	updateProfessionalSkill () {
		this._modal?.trigger('updateProfessionalSkill', this.props.order.professionalSkill);
	}

	updateSecondaryProfessionalSkill () {
		this._modal?.trigger('updateSecondaryProfessionalSkill', this.props.order.orderProfessionalSkillsProfessionalSkill);
	}

	updateLanguages () {
		this._modal?.trigger('updateLanguages', this.props.order.languages);
	}

	updateScheduleDefault () {
		this._modal?.trigger('updateScheduleDefault', this.props.order.scheduleDefaultsEntities.filter(x => x.isActive).mapKey('entityState'));
	}

	updateInterlocutor () {
		this._modal?.trigger('updateInterlocutor', this.props.order.orderInterlocutor);
	}

	deleteInterlocutor () {
		this._modal?.trigger('deleteInterlocutor');
	}

	confirm () {
		if (!this.validator.validate())
			this.validator.showValidationState();
		else {
			if (this.props.tags === 'skills') this.updateProfessionalSkill();
			else if (this.props.tags === 'secondarySkills') this.updateSecondaryProfessionalSkill();
			else if (this.props.tags === 'languages') this.updateLanguages();
			else if (this.props.tags === 'scheduleDefault') this.updateScheduleDefault();
			else if (this.props.tags === 'interlocutor') this.updateInterlocutor();
			else this.updateOrder();
		}

	}

	cancel () {
		this._modal?.close();
	}

	handleGoogleFound (payload: GoogleAutoCompleteFoundPayload) {
		this.props.order.missionPlace = undefined;
		this.props.order.address = payload.address;
		if (payload.latitude) this.props.order.latitude = payload.latitude;
		if (payload.longitude) this.props.order.longitude = payload.longitude;
		this.props.order.postalCode = payload.postalCode;
		this.props.order.city = payload.city;
		const countryCode = payload.countryCode ?? useCountry().countries.find(x => x.name === payload.country)?.code;
		if (countryCode) this.props.order.countryCode = countryCode;
	}

	orderDescriptions (array: IOrderTemplateModel[]) {
		const withOrderSkill = [];
		const withSkill = [];
		const withoutSkill = [];

		for (let i=0; i<array.length; i++) {
			let test = false;

			if (array[i].professionalSkills.length === 0) {
				withoutSkill.push(array[i]);
				continue;
			}

			forEach(array[i].professionalSkills, (skill) => {
				if (skill.id === this.props.order.professionalSkill?.id) {
					test = true;
				}
			});
			if (test)
				withOrderSkill.push(array[i]);
			else
				withSkill.push(array[i]);
		}
		return withOrderSkill.concat(withSkill).concat(withoutSkill);
	}


	selectDescriptionTemplate () {
		if (this.state.jobDescriptionId)
			this.props.order.jobDescription = this.jobDescriptionOptions.find(x => x.id === this.jobDescriptionId)?.content;

		this.state.descriptionTemplateSelected = true;
	}

	clearDescriptionTemplate () {
		this.props.order.jobDescription = undefined;
		this.state.descriptionTemplateSelected = false;
	}

	async getCitiesAsync (postalCode?: string, city?: string) {
		if (!postalCode) return;
		const { data } = await GouvApi.getListCity(postalCode);
		if (!data.length) {
			this.state.cityOptions = [];
			return;
		} else {
			this.state.cityOptions = data;
			if (city) {
				this.props.order.city = data.find(x => x.nom === city)?.nom ?? undefined;
			} else {
				this.props.order.city = data[0].nom;
				nextTick(() => {
					this._citySelect.value?.focus();
				});
			}
		}
	}

	switchingCountry () {
		this.props.order.postalCode = undefined;
		this.props.order.city = undefined;
	}
}
