export enum SETTING_TYPE {
	DeleteTime = 0,
	ReleaseNote = 1,
	NightHours = 2,
	ProfileIsPublic = 3,
	Layout = 4,
	ByMonthCardStatDocument = 5,
	DisplaySouplesse = 6,
	Currency = 7,
	AllowAdvancedPayment = 8,
	MessageAdvancedPayment = 9,
	AllowRecovery = 10,
	NumberOfClientContractReminder = 11,
	ClientContractReminderStartAt = 12,
	NumberOfTempWorkerContractReminder = 13,
	TempWorkerContractReminderStartAt = 14,
	DisplayBillStatus = 15,
	AgencyFacturationDate = 16,
	Notification = 17,
	PaymentTypeAdvancedPayment = 18,
	ShowOrderMarketingCard = 19,
	ShowFlex = 20,
	UnfoldTimeTicket = 21,
}
