import ApiClient from '@/api/ApiClient';

const client = new ApiClient('language');

class LanguageApi {
	static list () {
		return client.get<ILanguageModel[]>('');
	}
}

export default LanguageApi;
