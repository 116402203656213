import { PropType, reactive, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrderEntity from '@/entity/order/OrderEntity';
import useOrderService from '@/services/OrderService';
import { watch } from 'vue';
import { nextTick } from 'vue';


type Props = SetupProps<typeof OrderCreationScheduleRecapSetupService.props>;

export default class OrderCreationScheduleRecapSetupService extends BaseSetupService<Props> {
	static readonly props = {
		order: {
			type: Object as PropType<OrderEntity>,
			default: undefined,
		},
	};

	readonly _startDate = ref<OrionDatepicker>();
	readonly _endDate = ref<OrionDatepicker>();
	readonly _periodRange = ref<OrionDatepicker>();

	private readonly state = reactive({
		showDatepicker: false,
		daterange: undefined as Undef<Orion.DateRange>,
		addDefaultSchedule: false,
		order: new OrderEntity(),
		dataLoaded: false,
	});

	get dataLoaded () { return this.state.dataLoaded; }
	get order () { return this.props.order ?? this.state.order;}
	get addDefaultSchedule () { return this.state.addDefaultSchedule; }
	set addDefaultSchedule (val) { this.state.addDefaultSchedule = val; }
	get showDatepicker () { return this.state.showDatepicker; }
	set showDatepicker (val) { this.state.showDatepicker = val; }
	get daterange () { return this.state.daterange; }
	set daterange (val) { this.state.daterange = val; }

	constructor (props?: Props, private _modal?: OrionModal) {
		super(props);

		watch(() => this.addDefaultSchedule, (val) => {
			if (val && this.order.isCompanyOrder()) {
				this.order.setDefaultSchedule();
			} else {
				this.order.scheduleDefaultsEntities.empty();
			}
		});

		watch(() => this.order.start, (val) => {
			if (this.order.start && (!this.order.end || this.order.end < new Date(val))) {
				this.order.end = new Date(new Date(val).toEndOfDay().setDate(new Date(val).getDate() + 1));
				this.order.end = new Date(new Date(this.order.end).setHours(18, 0, 0));
			}

			if (this.addDefaultSchedule && this.order.isCompanyOrder()) {
				this.order.setDefaultSchedule();
			}
		});

		watch(() => this.order.end, () => {
			if (this.addDefaultSchedule && this.order.isCompanyOrder()) {
				this.order.setDefaultSchedule();
			}
		});

		watch(() => this.state.daterange, (val) => {
			if (val?.end && !val.selecting && this.order.isCompanyOrder()) {
				this.order.addSpecificSchedule(val);
				this.state.showDatepicker = false;
			}
		});
	}

	protected async onBeforeMountAsync () {
		if (!this.props.order && this.route.params.id) {
			const res = await useOrderService().getDetailedAsync(+this.route.params.id);
			if (res)
				this.state.order.assignData(res);
		}
		this.state.dataLoaded = true;
	}

	togglePeriodSelection () {
		this.showDatepicker = !this.showDatepicker;
		nextTick(() => this._periodRange.value?.focus());
	}

	goToOrderDetails () {
		this._modal?.close();
		this.router.push({
			name: `OrderDetailsRecap`,
			params: { id: this.order.id },
		});
	}
}
