import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/ordercancelrequest');

class OrderCancelRequestApi {
	static add (addOrderCancelRequest: IAddOrderCancelRequestModel) {
		return client.post<number>('', addOrderCancelRequest);
	}

	static delete (id: number) {
		return client.delete<void>(`order/${id.toString()}`);
	}
}

export default OrderCancelRequestApi;
