<template>
	<o-section v-bind="{ title, subtitle }">
		<o-alert
			v-if="setup.route.name === 'OrganizationTagDetails'"
			color="warning"
			class="mb-sm">
			L'application d'un Tag à un utilisateur va <b> restreindre la visibilité </b> de ses documents et processus sur Armado.
			N'attribuez un Tag que lorsque vous souhaitez <b> limiter l'accès à certains éléments </b> bien particuliers, eux aussi taggués.
		</o-alert>
		<o-input
			v-if="!hideSearch"
			v-model="setup.search"
			label="Chercher parmis les utilisateurs ..."
			suffix-font-icon="icon-interface-search"
			:donetyping="600"
			@input="setup.refreshListAsync(true)"/>

		<div v-if="!hideQuickFilters && quickFiltersType">
			<o-chips
				v-for="(chips, index) in setup.quickFiltersOptions"
				:key="index"
				outline
				:color="setup.filterIsSelected(chips) ? 'info' : 'default'"
				@click="setup.selectFilter(chips)">
				{{ chips.label }}
			</o-chips>
		</div>

		<div class="flex mb-sm">
			<o-toggle
				v-if="!!setup.self.suiteUserId"
				v-model="setup.filter.withHiddenUsers"
				label="Afficher les utilisateurs masqués"
				@input="setup.refreshListAsync(true)"/>
		</div>

		<o-list
			v-model:page="setup.page"
			:use-pagination-top="false"
			:list="setup.list"
			:total="setup.total"
			layout="row">
			<template #default="{ item: user }">
				<o-sticker
					:muted="setup.userIsHidden(user)"
					:v-tooltip="user.isDisabled ? 'Utilisateur désactivé' : undefined"
					:selected="setup.userIsSelected(user)"
					@click="setup.toggleSelectUser(user)">
					<div class="flex ai-c g-sm">
						<o-avatar
							class="shared-select-user-list__user-avatar"
							size="sm"
							root-url="/app/avatar/"
							:name="user.user.firstName"
							:avatar="user.user.avatarId"/>
						<div>
							<div class="flex ai-c g-xs">
								<strong v-html="user.user.name.mark(setup.search)"/>
								<o-icon
									v-if="setup.userIsHidden(user)"
									v-tooltip="setup.getHiddenUserTooltip(user)"
									font-icon="icon-interface-help-question-circle"/>
							</div>

							<div
								class="text--info"
								v-html="user.user.authLogin.mark(setup.search)"/>
						</div>
						<o-label
							v-if="user.user.id === setup.self.id"
							class="ml-a"
							color="info"
							size="md">
							Vous
						</o-label>
					</div>
				</o-sticker>
			</template>
		</o-list>
	</o-section>

	<teleport :to="_modal?.slotFooter">
		<o-section align="stretch">
			<o-button
				outline
				color="danger"
				@click="_modal?.close()">
				Annuler
			</o-button>
			<o-button
				:color="setup.userSelected.length > 0 ? 'brand' : 'default'"
				:disabled="!setup.userSelected.length"
				@click="setup.addUsers()">
				{{ transferCreator ? `Définir comme Créateur` : `Ajouter ${'utilisateur'.pluralize(setup.userSelected.length)}` }}
			</o-button>
		</o-section>
	</teleport>
</template>

<script setup lang="ts">
import SharedSelectUserListSetupService from '@/setup/shared/SharedSelectUserListSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const emits = defineEmits<{(e: 'input', val: (number|undefined)[]): void}>();
const props = defineProps(SharedSelectUserListSetupService.props);
const setup = new SharedSelectUserListSetupService(props, emits, _modal);
</script>

<style lang="less">
.shared-select-user-list {
	position: relative;
  height: 100%;
  padding-bottom: 3rem;
  // background: fade(red, 10);

	&__search {
		margin-bottom: 1rem;
	}

	&__filter-section {
		display: flex;
		align-items: center;
		gap: var(--space-sm);
		margin-bottom: 1rem;
	}

	&__filter {
		cursor: pointer;
		&:hover {
			opacity: 0.7;
		}
	}

	&__user {
		display: flex;
		padding: 10px 1rem;
		align-items: center;
		gap: 1rem;
		cursor: pointer;

		&:first-of-type {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}
		&:last-of-type {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}
		p{
			margin: unset;
		}

		&:hover:not(.shared-select-user-list__user--selected) {
			background: var(--grey-lighter);
		}

		&--selected {
			background: rgba(var(--rgb-info), 0.15);
		}
	}

	&__user-avatar {
		height: 40px;
		width: 40px;
	}

	&__actions {
		position: absolute;
		width: 100%;
		bottom: 0;
	}

	&__user-name {
		color: var(--grey-darker);
		font-weight: 700;
	}

	&__user-mail {
		color: var(--info);
		text-decoration: underline;
	}

	.arm-list {
		max-height: 400px;
		overflow: scroll;
	}

  .arm-paginate {
    margin: 20px auto 10px;
  }

  .arm-footer-fixed {
    display: none;
  }

	&__user-self {
		position: absolute;
		right: 25px;
	}
}
</style>
