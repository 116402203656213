import axios from 'axios';

const baseURL = 'https://geo.api.gouv.fr';
const API = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
	},
});

class GouvApi {
	static getListCity (postalCode: string) {
		return API.get<{ nom: string }[]>(`communes?codePostal=${postalCode}`);
	}

	static getPostalCode (city: string) {
		return API.get(`?q=${city}`);
	}

	// eslint-disable-next-line @orion.ui/orion/async-suffix
	static getHolidays (year?: number) {
		/**
     * https://gitlab.armado.fr/qa/support/-/issues/108#note_9562
     * Cas particulier pour certains clients qui ne peuvent pas accéder à l'API du gouvernement (blocage parefeu).
     */
		return new Promise<{ data: ApiGouvHolidays }>(async (resolve) => {
			try {
				const result = await axios.create({
					baseURL: `https://calendrier.api.gouv.fr/jours-feries/metropole`,
					headers: { 'Content-Type': 'application/json' },
				}).get<ApiGouvHolidays>(`${year}.json`);
				resolve(result);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(`L'API du gouvernement n'a pas pu être contactée. Veuillez réessayer plus tard ou vérifier vos paramètres de parefeu.`);
				// eslint-disable-next-line no-console
				console.error(error);
				resolve({ data: {} as ApiGouvHolidays });
			}
		});
	}
}

export default GouvApi;
