import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import type OrderEntity from '@/entity/order/OrderEntity';
import { forEach } from 'lodash-es';
import { ORDER_TEMPLATE_TYPE } from '@/typings/enums/ORDER_TEMPLATE_TYPE';
import { Emits } from './OrderCreationAsideSetupService';
import useTagUserService from '@/services/tag/TagUserService';
import { useNotif } from '@orion.ui/orion';
import useOrderToolboxJobDescriptionService from '@/services/order/OrderToolboxJobDescriptionService';

type Props = SetupProps<typeof OrderCreationStep5SetupService.props>

export default class OrderCreationStep5SetupService extends BaseSetupService<Props> {
	static readonly props = {
		order: {
			type: Object as PropType<OrderEntity>,
			required: true as const,
		},
	};

	private emits: Emits;

	private readonly state = reactive({
		jobDescriptionTemplates: [] as IOrderTemplateModel[],
		jobDescriptionId: null,
		templateSelected: false,
		selfTags: [] as ITagUserSelfModel[],
	});


	get templateSelected () { return this.state.templateSelected; }
	get jobDescriptionTemplates () { return this.state.jobDescriptionTemplates; }
	get orderNeedTag () { return !this.self.isCreator() && this.state.selfTags.length > 0; }

	get jobDescriptionId () { return this.state.jobDescriptionId; }
	set jobDescriptionId (val) { this.state.jobDescriptionId = val; }

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	protected async onBeforeMountAsync (): Promise<void> {
		this.state.selfTags = await useTagUserService().selfAsync();
	}


	async updateListAsync () {
		const data = this.self.isAgency()
			? await useOrderToolboxJobDescriptionService().listAsync({})
			: await useOrderToolboxJobDescriptionService().listAsync({ type: ORDER_TEMPLATE_TYPE.JobDescription });

		if (!data) return;
		this.state.jobDescriptionTemplates = data;
		this.state.jobDescriptionTemplates = this.orderTemplates(this.state.jobDescriptionTemplates);
	}

	orderTemplates (array: IOrderTemplateModel[]) {
		const withOrderSkill = [];
		const withSkill = [];
		const withoutSkill = [];

		for (let i=0; i<array.length; i++) {
			let test = false;

			if (array[i].professionalSkills.length === 0) {
				withoutSkill.push(array[i]);
				continue;
			}

			forEach(array[i].professionalSkills, (skill) => {
				if (skill.id === this.props.order.professionalSkill?.id) {
					test = true;
				}
			});
			if (test)
				withOrderSkill.push(array[i]);
			else
				withSkill.push(array[i]);
		}
		return withOrderSkill.concat(withSkill).concat(withoutSkill);
	}

	filterTemplates (a: IOrderTemplateModel) {
		if (a.professionalSkills.length > 0) {
			for (let i=0; i<a.professionalSkills?.length; i++) {
				if (a.professionalSkills[i].id === this.props.order.professionalSkill?.id) {
					return 1;
				}
			}
			return 0;
		} else {
			return -1;
		}
	}

	selectTemplate () {
		if (this.state.jobDescriptionId)
			this.props.order.jobDescription = this.state.jobDescriptionTemplates.find(x => x.id === this.state.jobDescriptionId)?.content;
		this.state.templateSelected = true;
	}

	clearTemplate () {
		this.props.order.jobDescription = undefined;
		this.state.templateSelected = false;
	}

	next () {
		if (this.orderNeedTag && this.props.order.tagIds.length < 1) {
			useNotif.warning(`Vous devez assigner au moins un de vos tags à la commande`);
			return;
		}

		this.props.order.asideActivePane = 6;
		this.emits('current', 6);
	}

}
