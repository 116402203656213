import { PropType, reactive, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import CompanyApi from '@/api/CompanyApi';
import OrganizationUserListItemEntity from '@/entity/organization-user/OrganizationUserListItemEntity';
import type OrganizationUserEntity from '@/entity/organization-user/OrganizationUserEntity';
import { useNotif } from '@orion.ui/orion';
import { PERMISSION_ACTION } from '@/typings/enums/PERMISSION_ACTION';
import { FlatSelectOptionItem } from './SharedFlatSelectSetupService';
import AjaxCooler from '@/utils/AjaxCooler';
import useMembershipService from '@/services/MembershipService';

type Props = SetupProps<typeof SharedCompanyFlatSelectSetupService.props>
type Emits = {
	(e: 'update:opened', val: boolean): void
	(e: 'update:modelValue', val?: ICompanyModel[]): void
	(e: 'add' | 'delete', val?: ICompanyModel): void,
}

export default class SharedCompanyFlatSelectSetupService extends BaseSetupService<Props> {
	static readonly props = {
		displayAddAll: Boolean,
		opened: Boolean,
		modelValue: {
			type: Array as PropType<ICompanyWithMembershipModel[]>,
			default: undefined,
		},
		user: {
			type: Object as PropType<OrganizationUserListItemEntity | OrganizationUserEntity>,
			default: undefined,
		},
		placement: {
			type: String,
			default: 'bottom-start',
		},
	};

	_popover = ref<Dropdown>();

	private cooler = new AjaxCooler(CompanyApi.get);
	private emits: Emits;
	private readonly state = reactive({
		loading: false,
		search: undefined as Undef<string>,
		companies: [] as ICompanyModel[],
	});

	get loading () { return this.state.loading; }
	get companies () { return this.state.companies as ICompanyModel[]; }

	get companiesList () {
		return this.companies
			.filter(x => !this.search?.trim().length || x.name?.toLowerCase().includes(this.search))
			.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
	}

	get companiesListOptionsForFlatSelect (): FlatSelectOptionItem[] {
		return this.companiesList.map(x => ({
			...x,
			name: x.name ?? '',
		}));
	}

	get search () { return this.state.search; }
	set search (val) { this.state.search = val; }


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}


	async handlePopoverShowAsync () {
		this.emits('update:opened', true);
		await this.getCompaniesAsync();
	}

	handlePopoverHide () {
		this.emits('update:opened', false);
	}

	companyIsSelected (company: ICompanyForDetailOrganizationClientUserModel) {
		return this.props.modelValue
			?.filter(x => !!x.membershipId)
			.mapKey('id')
			.includes(company.id);
	}

	async getCompaniesAsync () {
		this.state.loading = true;

		const permissionAction: OmitUserId<ICompanyListByUserIdParameters> = {
			checkOrderPlugin: false,
			permissionAction: PERMISSION_ACTION.UserRead,
		};

		const companies = await useMembershipService().listAsync(false, {
			...permissionAction,
			organizationId: this.self.organization?.id,
		});
		this.state.companies = companies;

		this.state.loading = false;
	}

	async addCompanyAsync (company: ICompanyModel) {
		if (!this.props.user?.userId || !this.organizationGuard()) return;

		try {
			const res = await useMembershipService().addAsync({
				companyIds: [company.id],
				userId: this.props.user.userId,
				organizationId: this.self.organization.id,
			});

			if (!res) return;

			useNotif.success('Modification enregistrée', `Le lien avec la société ${company.name} a bien été ajouté.`);

			this.emits('update:modelValue', [
				...(this.props.modelValue ?? []),
				{
					...company,
					membershipId: res[0].id,
				},
			]);
			this.emits('add', company);
		} catch (e: any) {
			useNotif.danger(e?.response?.data);
		}
	}

	async addAllCompaniesAsync () {
		if (!this.props.user?.userId || !this.organizationGuard()) return;

		try {
			const res = await useMembershipService().addAsync({
				companyIds: this.props.modelValue?.filter(x => !x.membershipId).mapKey('id') ?? [],
				userId: this.props.user.userId,
				organizationId: this.self.organization.id,
			});

			if (!res) return;

			useNotif.success('Modification enregistrée', `Toutes les sociétés ont bien été liées.`);

			this.emits('update:modelValue', [
				...this.companiesList.map(x => ({
					...x,
					membershipId: res.findByKey(x.id, 'companyId'),
				})),
			]);
			this.emits('add');
		} catch (e: any) {
			useNotif.danger(e?.response?.data);
		}
	}

	async deleteCompanyAsync (company: ICompanyModel) {
		const membershipIdToDelete = this.props.modelValue?.filter(x => x.id === company.id)?.[0].membershipId as number;
		await this.props.user?.deleteMembershipAsync(membershipIdToDelete);

		useNotif.success('Modification enregistrée', `La société a bien été dissociée de l'utilisateur`);
		this.emits('update:modelValue', this.props.modelValue?.filter(x => x.id !== company.id));
		this.emits('delete', company);
	}
}
