import { nextTick, PropType, reactive, ref, watch } from 'vue';
import GouvApi from '@/api/GouvApi';
import BaseSetupService from '@/setup/BaseSetupService';
import type OrderEntity from '@/entity/order/OrderEntity';
import { regexEmail, useCountry, useNotif, useValidation } from '@orion.ui/orion';
import { Emits } from './OrderCreationAsideSetupService';
import { regexMobile, regexPhone } from '@/utils/tools';

type Props = SetupProps<typeof OrderCreationStep4SetupService.props>
type Cities = { nom: string }

export default class OrderCreationStep4SetupService extends BaseSetupService<Props> {
	static readonly props = {
		order: {
			type: Object as PropType<OrderEntity>,
			required: true as const,
		},
	};

	readonly _citySelect = ref<OrionSelect>();


	validationRules = reactive({
		countryCode: 'required',
		postalCode: 'required',
		address: 'required',
		city: this.props.order.countryCode === 'FR' ? 'required' : undefined,
	});

	private readonly state = reactive({
		countries: [] as Orion.Country[],
		missionPlaceOptions: [],
		cityOptions: [] as Cities[],
		googleApiAddress: null,
		interlocutor: {} as IOrderInterlocutorModel,
		validator: (!this.props.order.missionPlace?.id)
			? useValidation(this.props.order, this.validationRules)
			: undefined,
		interlocutorPhoneNumber: {
			phoneCountryCode: 'FR',
			phoneNumber: this.props.order.orderInterlocutor?.telephone,
		},
	});

	private emits: Emits;

	get validator () { return this.state.validator;}

	get cityOptions () { return this.state.cityOptions; }
	get countries () { return this.state.countries; }

	get interlocutorPhoneNumber () { return this.state.interlocutorPhoneNumber; }
	set interlocutorPhoneNumber (val) {
		this.state.interlocutorPhoneNumber = val;
		if (this.props.order.orderInterlocutor && val.phoneNumber !== '+33') {
			this.interlocutor.telephone = val.phoneNumber;
			this.props.order.orderInterlocutor.telephone = val.phoneNumber;
		}
	}

	get interlocutor () { return this.state.interlocutor; }
	set interlocutor (val) { this.state.interlocutor = val; }

	interlocutorValidator = useValidation(this.interlocutor, {
		name: () => {
			return (this.props.order.orderInterlocutor?.firstName?.length
				|| this.props.order.orderInterlocutor?.email?.length
				|| this.props.order.orderInterlocutor?.telephone?.length)
				? !!this.props.order.orderInterlocutor?.lastName?.length
				: true;
		},
		firstName: () => {
			return (this.props.order.orderInterlocutor?.lastName?.length
				|| this.props.order.orderInterlocutor?.email?.length
				|| this.props.order.orderInterlocutor?.telephone?.length)
				? !!this.props.order.orderInterlocutor?.firstName?.length
				: true;
		},
		email: () => {
			return this.props.order.orderInterlocutor?.email?.length
				? regexEmail.test(this.props.order.orderInterlocutor?.email)
				: true;
		},
		telephone: () => {
			return (this.props.order.orderInterlocutor?.telephone && this.props.order.orderInterlocutor.telephone?.length)
				? regexMobile.test(this.props.order.orderInterlocutor?.telephone) || regexPhone.test(this.props.order.orderInterlocutor?.telephone)
				: true;
		},
	});

	get missionPlaceForAgency () {
		return this.props.order.missionPlace
			? `${this.props.order.missionPlace.address}\n${this.props.order.missionPlace.postalCode} ${this.props.order.missionPlace.city}`
			: undefined;
	}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
		watch(() => this.props.order.missionPlace, (val) => {
			if (!!val) {
				this.handleMissionPlaceSelectAsync();
			}
		});

		watch(this.state.interlocutor, (val) => {
			this.props.order.orderInterlocutor = {
				...this.props.order.orderInterlocutor,
				...val,
			};
		});

	}

	protected async onBeforeMountAsync () {
		this.state.countries = useCountry().countries;
	}

	protected onMounted () {
		Object.assign(this.state.interlocutor, this.props.order.orderInterlocutor ?? {
			name: undefined,
			firstName: undefined,
			email: undefined,
			telephone: undefined,
		});

	}

	async handleGoogleAddressAsync (payload: GoogleAutoCompleteFoundPayload) {
		this.props.order.missionPlace = undefined;
		this.props.order.address = payload.address;
		if (payload.latitude) this.props.order.latitude = payload.latitude;
		if (payload.longitude) this.props.order.longitude = payload.longitude;
		this.props.order.postalCode = payload.postalCode;
		const countryCode = useCountry().countries.find(x => x.name === payload.country)?.code;
		if (countryCode) this.props.order.countryCode = countryCode;

		if (this.props.order.countryCode === 'FR' && payload.postalCode !== null) {
			this.getCitiesAsync(payload.postalCode, payload.city);
		} else {
			this.props.order.city = payload.city;
		}
	}

	async handleMissionPlaceSelectAsync () {
		this.props.order.countryCode = undefined;
		this.props.order.address = undefined;
		this.props.order.postalCode = undefined;
		this.props.order.city = undefined;
		this.props.order.latitude = 0;
		this.props.order.longitude = 0;
	}

	async getCitiesAsync (postalCode?: string, city?: string) {
		if (!postalCode) return;
		const { data } = await GouvApi.getListCity(postalCode);
		if (!data.length) {
			this.state.cityOptions = [];
			return;
		} else {
			this.state.cityOptions = data;
			if (city) {
				this.props.order.city = data.find(x => x.nom === city)?.nom ?? undefined;
			} else {
				this.props.order.city = data[0].nom;
				nextTick(() => {
					this._citySelect.value?.focus();
				});
			}
		}
	}

	switchingCountry () {
		this.props.order.postalCode = undefined;
		this.props.order.city = undefined;
	}

	next (step = 5) {
		const validator = this.props.order.countryCode === 'FR' || !this.props.order.countryCode
			? useValidation(this.props.order, this.validationRules)
			: undefined;

		if (!this.props.order.missionPlace?.id && validator && !validator.validate()) {
			validator?.showValidationState();
			useNotif.danger('Veuillez remplir les champs obligatoires');
			return;
		}

		if (this.props.order.orderInterlocutor && !this.interlocutorValidator.validate()) {
			this.interlocutorValidator?.showValidationState();
			useNotif.danger(`Les champs 'Interlocuteur' sont incomplets`);
			return;
		}
		this.props.order.asideActivePane = 5;
		this.emits('current', step);
	}

}
