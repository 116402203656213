import ApiClient from '@/api/ApiClient';

const client = new ApiClient('tempworker/discussion');

class DiscussionApiTempWorker {

	static add (addDiscussionModel: IAddDiscussionModel) {
		return client.post<number>('', addDiscussionModel);
	}

	static grid (filter: IDiscussionPagedListParameters) {
		return client.post<IPagedList<IDiscussionItemModel>>('grid', filter);
	}

	static selectById (id: number) {
		return client.get<IDiscussionItemModel>(`${id}`);
	}

	static search (searchTempWorkerParameters: ISearchDiscussionTempWorkerParameters) {
		return client.get<IDiscussionItemModel>('search', { params: searchTempWorkerParameters });
	}
}

export default DiscussionApiTempWorker;
