import useOrderService from '@/services/OrderService';
import { ORDER_STATUS } from '@/typings/enums/ORDER_STATUS';
import { USER_TYPE } from '@/typings/enums/USER_TYPE';
import { getUid, itemIs, useNotif } from '@orion.ui/orion';

import { pickFrom } from '@/utils/tools';
import { ORDER_USER_STATUS } from '@/typings/enums/ORDER_USER_STATUS';
import useDiscussionService from '@/services/DiscussionService';
import OrderScheduleEntity from './OrderScheduleEntity';
import OrderSpecificScheduleEntity from './OrderSpecificScheduleEntity';
import { Reactive } from '@/utils/decorators';
import ScheduleOrderRangeEntity from './ScheduleOrderRangeEntity';
import useScheduleOrderRangesService from '@/services/ScheduleOrderRangesService';
import useBusService from '@/services/BusService';
import useTagUserService from '@/services/tag/TagUserService';
import BaseOrderEntity from './BaseOrderEntity';

type MoveOrderUserPayload = IMoveOrderUserModel & {
  orderStepIndex: number
  orderStepTitle: string
}

export default class OrderEntity extends BaseOrderEntity<Armado.OrderDetails> {

	// eslint-disable-next-line local-rules/state-are-private-readonly
	@Reactive protected readonly orderState = {
		selectedCandidates: [] as number[],
		asideActivePane: undefined as Undef<Number>,
		clientCandidateProposition: 0,
	};

	override isCompanyOrder = (): this is OrderEntity => true;

	get createdDate () { return new Date(this.state.createdDate); }
	get creatorUser () { return this.state.creatorUser; }
	get richStatus () { return useOrderService().getRichStatus(this.state.status); }
	get selectedCandidates () { return this.orderState.selectedCandidates; }
	get clientCandidateProposition () { return this.orderState.clientCandidateProposition; }
	set clientCandidateProposition (val) { this.orderState.clientCandidateProposition = val; }
	get hasContract () { return this.state.hasContract; }

	get orderCancelRequest () { return this.state.orderCancelRequest; }
	get orderTempWorkerProposals (): IOrderTempWorkerProposalWithIdModel[] | undefined {
		if (itemIs<IOrderAgencyOverPageModel>(this.state, 'orderTempWorkerProposals'))
			return this.state.orderTempWorkerProposals;
		return undefined;
	}

	get orderClientProposals (): IOrderClientProposalForAgenceWithIdModel[] | undefined {
		if (itemIs<IOrderAgencyOverPageModel>(this.state, 'orderClientProposals'))
			return this.state.orderClientProposals;
		return undefined;
	}

	get users () {
		if (itemIs<IOrderAgencyOverPageModel>(this.state, 'users'))
			return this.state.users;
		return undefined;
	}

	get creatorIsAgency () {
		return this.state.creatorUser?.userType === USER_TYPE.Agency || this.state.creatorUser?.userType === null;
	}

	get creatorIsClient () {
		return this.state.creatorUser?.userType === USER_TYPE.Client;
	}

	get updateAsyncPayload () {
		if (itemIs<IOrderAgencyOverPageModel>(this.state, 'users'))
			return this.state as unknown as IUpdateOrderAgencyModel;
		return undefined;
	}

	get numberOfValidatedCandidates () {
		if (itemIs<IOrderAgencyOverPageModel>(this.state, 'users'))
			return this.state.users.filter(x => x.status === ORDER_USER_STATUS.Validated).length;
		return undefined;
	}

	get publicState () {
		return {
			...pickFrom(
				this,
				'id',
				'agencyCompanyId',
				'clientCompanyId',
				'gearIds',
				'justificatoryIds',
				'professionalSkillId',
				'languageIds',
				'start',
				'end',
				'hourlyRate',
				'requiredTempWorker',
				'countryCode',
				'postalCode',
				'address',
				'city',
				'latitude',
				'longitude',
				'jobDescription',
				'comment',
				'warnClient',
				'needPersonalVehicle',
				'missionPlaceId',
				'tagIds',
				'orderProfessionalSkillsProfessionalSkillIds',
				'motiveId',
				'justificationMotive',
			),
			scheduleDefaults: this.selfState.scheduleDefaultEntities.filter(x => x.isActive).mapKey('entityState'),
			scheduleOrderRanges: this.selfState.scheduleOrderRangesEntities.mapKey('entityState'),
		};
	}

	get canCancel () {
		return this.self.isAgency()
			&& this.state.status !== ORDER_STATUS.Canceled
			&& this.state.status !== ORDER_STATUS.Validated;
	}


	get agencyCompanyName () { return this.state.agencyCompanyName; }
	set agencyCompanyName (val) { this.state.agencyCompanyName = val; }

	get agencyCompanyId () { return this.state.agencyCompanyId; }
	set agencyCompanyId (val) { this.state.agencyCompanyId = val; }

	get comment () { return this.state.comment; }
	set comment (val) { this.state.comment = val; }


	get asideActivePane () { return this.orderState.asideActivePane as Undef<number>; }
	set asideActivePane (val) { this.orderState.asideActivePane = val; }

	get requiredTempWorker () { return this.state.requiredTempWorker; }
	set requiredTempWorker (val) { this.state.requiredTempWorker = val; }

	get tagLinks () { return this.state.tagLinks ?? []; }
	set tagLinks (val) { this.state.tagLinks = val; }

	get tagIds () { return this.tagLinks.mapKey('tagId') ?? [];}
	set tagIds (val) {
		this.tagLinks = val.map((x) => {
			return {
				id: 0,
				linkId: 0,
				tagId: x,
			};
		});
	}


	get agencyNote () {
		if (itemIs<IOrderAgencyOverPageModel>(this.state, 'agencyNote'))
			return this.state.agencyNote;
		return undefined;
	}

	set agencyNote (val) {
		if (val !== undefined && this.isAgency()) {
			this.state.agencyNote = val;
		}
	}

	get warnClient () {
		if (this.isAgency() && this.isNew) {
			return this.state.warnClient ?? true;
		} else if (itemIs<IOrderAgencyOverPageModel>(this.state, 'warnClient')) {
			return this.state.warnClient;
		}
		return undefined;
	}

	set warnClient (val) {
		if (val !== undefined && this.isAgency()) {
			this.state.warnClient = val;
		}
	}

	get canValidateOrder () {
		return (this.self.isAgency() && this.self.can('OrderUpdate'))
		&& this.status !== ORDER_STATUS.Validated && this.status !== ORDER_STATUS.Canceled;
	}

	protected override onDataAssigned () {
		if (this.selfState) {
			this.selfState.scheduleDefaultEntities = OrderScheduleEntity.map(this.state.scheduleDefaults);
			this.selfState.scheduleOrderRangesEntities = ScheduleOrderRangeEntity.map(this.state.scheduleOrderRanges);
		}
		useBusService().on(`${this.id}:details-status-update`, (payload: {status: ORDER_STATUS, reason?: string}) => {
			this.state.status = payload.status;
			if (payload.reason)
				this.state.orderCancelRequest = {
					reason: payload.reason,
					createdDate: new Date().toPost(),
				};
			else {
				this.state.orderCancelRequest = undefined;
			}
		});
	}

	setSelectedCandidates (selected: number[]) {
		this.orderState.selectedCandidates = selected;
	}

	toggleCandidate (candidate: number) {
		this.orderState.selectedCandidates.toggle(candidate);
	}

	isCandidateSelected (orderId: number, candidateId: number) {
		return this.orderState.selectedCandidates.includes(candidateId);
	}

	canModify () {
		return (this.richStatus?.status !== ORDER_STATUS.Canceled && this.richStatus?.status !== ORDER_STATUS.Validated)
			&& this.self.isAgency()
			&& this.self.canOneOf('OrderCreate', 'OrderUpdate');
	}

	setDefaultSchedule (isNew = true) {
		if (!this.start || !this.end) return;
		if (isNew) this.scheduleDefaultsEntities.empty();

		let diff = Math.floor(this.end.toMidnight().getTime() - this.start.toMidnight().getTime()) / (1000 * 3600 * 24);
		if (diff >= 7) diff = 6;

		const id = getUid();
		const defaultTimeSlot = {
			id: getUid(),
			scheduleDefaultId: id,
			startTime: this.start.toPost(true),
			endTime: this.end.toPost(true),
		};

		const tab = isNew ? this.selfState.scheduleDefaultEntities : [];

		for (let i = 0; i <= diff; i++) {
			const date = new Date(this.start);
			date.setDate(new Date(this.start).getDate() + i);

			const slotValue = [defaultTimeSlot];
			const schedule = this.selfState.scheduleDefaultEntities.findByKey(date.getDay(), 'weekDay');

			if (!schedule && isNew) {
				tab.push(new OrderScheduleEntity({
					id: id,
					weekDay: date.getDay(),
					scheduleDefaultTimeSlots: slotValue,
				}));
			} else if (schedule) {
				tab.push(schedule);
			} else {
				tab.push(new OrderScheduleEntity({
					id: id,
					weekDay: date.getDay(),
					scheduleDefaultTimeSlots: [],
				}));
			}
		}

		this.selfState.scheduleDefaultEntities = tab;
	}

	setSpecificSchedule (daterange: Orion.DateRange, scheduleToDuplicate?: OrderSpecificScheduleEntity) {
		if (!daterange.start || !daterange.end) return;
		if (this.isOverlapping(daterange)) {
			useNotif.danger('La période saisie chevauche une période existante.');
			return;
		}

		const diff = Math.ceil(daterange.end.getTime() - daterange.start.getTime()) / (1000 * 3600 * 24);
		const scheduleId = getUid();


		const newOrderRangeId = getUid();
		const orderRange = this.getScheduleOrderRangeEntity(daterange) ?? new ScheduleOrderRangeEntity({
			startRange: daterange.start.toMidnight().toPost(true),
			endRange: daterange.end.toEndOfDay().toPost(true),
			id: newOrderRangeId,
			scheduleOrders: [],
		});

		for (let i = 0; i <= diff; i++) {

			const date = new Date(daterange.start);
			date.setDate(daterange.start.getDate() + i);
			const scheduleDefaultTimeslot = this.getScheduleDefaultEntity(date.getDay())?.timeSlotEntities;

			scheduleDefaultTimeslot?.forEach((timeslot) => {
				timeslot.startTime = new Date(date.setHours(timeslot.startHours)).toPost(true);
				timeslot.endTime = new Date(date.setHours(timeslot.endHours)).toPost(true);
			});
			const defaultTimeSlot = {
				id: getUid(),
				startTime: new Date(new Date(date).setHours(8)).toPost(true),
				endTime: new Date(new Date(date).setHours(18)).toPost(true),
				scheduleOrderId: scheduleId,
			};

			const slotValue = scheduleToDuplicate
				? scheduleToDuplicate.scheduleOrderTimeSlots
				: scheduleDefaultTimeslot
					? scheduleDefaultTimeslot.mapKey('entityState')
					:[defaultTimeSlot];

			if (scheduleToDuplicate) {
				if (date.getDay() !== new Date(scheduleToDuplicate.date).getDay())
					continue;
			}

			const entity = orderRange.scheduleOrderEntities.findByKey(date.toPost(), 'date');
			if (!entity) {
				const newEntity = new OrderSpecificScheduleEntity({
					id: getUid(),
					weekDay: date.getDay(),
					date: date.toPost(),
					scheduleOrderTimeSlots: slotValue,
				});

				newEntity.setDaterange(daterange);
				orderRange.pushOrderSpecificScheduleEntity(newEntity);
			} else {
				entity.scheduleOrderTimeSlots = slotValue;
			}
		}


		if (orderRange.id === newOrderRangeId)
			this.selfState.scheduleOrderRangesEntities.push(orderRange);

	}

	isOverlapping (daterange: Orion.DateRange) {

		return this.selfState.scheduleOrderRangesEntities.filter((x) => {
			if (!daterange.start || !daterange.end) return false;

			return (new Date(x.startRange) < daterange.end && new Date(x.endRange) > daterange.start) ||
			(daterange.start < new Date(x.endRange) && daterange.end > new Date(x.startRange)) ||
			(new Date(x.startRange) <= daterange.start && new Date(x.endRange) >= daterange.end) ||
			(daterange.start <= new Date(x.startRange) && daterange.end >= new Date(x.endRange));
		}).length;
	}

	updateSpecificScheduleDaterange (daterange: Orion.DateRange, scheduleRange: ScheduleOrderRangeEntity) {
		if (!daterange.start || !daterange.end) return;
		this.selfState.scheduleOrderRangesEntities.deleteWhere('id', scheduleRange.id);
		this.setSpecificSchedule(daterange);
	}



	addSpecificSchedule (daterange: Orion.DateRange) {
		this.setSpecificSchedule(daterange);
	}

	async moveUsersInPipelineAsync (payload: MoveOrderUserPayload) {
		await useOrderService().moveCandidateAsync(payload);

		payload.userIds.forEach((userId) => {
			if (itemIs<IOrderAgencyOverPageModel>(this.state, 'users')) {
				const user = this.state.users.findByKey(userId, 'userId');
				if (user) {
					user.status = payload.orderStepIndex === 3 ? ORDER_USER_STATUS.Validated : ORDER_USER_STATUS.InSteps;
				}
			}
		});

		if (this.requiredTempWorker === this.numberOfValidatedCandidates && this.state.status === ORDER_STATUS.Created) {
			this.state.status = ORDER_STATUS.Filled;
		} else if (this.requiredTempWorker !== this.numberOfValidatedCandidates && this.state.status === ORDER_STATUS.Filled) {
			this.state.status = ORDER_STATUS.Created;
		}

		useNotif.success(`Modification enregistrée`);
	}

	async getStepsAsync (showRejectedUsers = false) {
		return await useOrderService().getStepAsync(this.state.id, { showRejectedUsers: showRejectedUsers });
	}

	async updateAsync (payload?: IUpdateOrderAgencyModel) {
		const data = (payload ?? this.state) as Undef<IUpdateOrderAgencyModel>;
		if (data) await useOrderService().updateAsync(this.state.id, data);
	}

	async updateProfessionalSkillAsync (payload: IUpdateOrderProfessionalSkillModel) {
		useOrderService().updateProfessionalSkillAsync(this.state.id, payload);
	}

	async updateSecondaryProfessionalSkillAsync (payload: IUpdateOrderSecondaryProfessionalSkillModel) {
		useOrderService().updateSecondaryProfessionalSkillAsync(this.state.id, payload);
	}

	async addLanguageAsync (payload: number[]) {
		useOrderService().addLanguageAsync(this.state.id, { languageIds: payload });
	}

	async addGearAsync (payload: IGearModel) {
		useOrderService().addGearAsync(this.state.id, { gearId: payload.id });
	}

	async deleteGearAsync (id: number) {
		useOrderService().removeGearAsync(this.state.id, id);
	}

	async addDocumentAsync (payload: IJustificatoryModel) {
		useOrderService().addDocumentAsync(this.state.id, { justificatoryId: payload.id });
	}

	async removeDocumentAsync (id: number) {
		useOrderService().removeDocumentAsync(this.state.id, id);
	}


	async updateScheduleDefaultAsync (payload: IAddScheduleDefaultModel[]) {
		useOrderService().updateScheduleDefaultAsync(this.state.id, payload);
	}

	async updateScheduleOrderRangeAsync (payload: IUpdateScheduleOrderRangeModel[]) {
		await useScheduleOrderRangesService().updateAsync({
			orderId: this.state.id,
			scheduleOrderRanges: payload,
		});
	}

	async updateInterlocutorAsync (payload: IOrderInterlocutorModel) {
		await useOrderService().updateInterlocutorAsync(this.state.id, payload);
	}

	async deleteInterlocutorAsync () {
		await useOrderService().deleteInterlocutorAsync(this.state.id);
	}

	private isAgency (): this is { state: IOrderAgencyOverPageModel } {
		return this.self.isAgency();
	}

	openDiscussion (candidate?: Armado.OrderDiscussionContextCandidate) {
		const discussionContext = useOrderService().getOrderDiscussionContext(this.state, candidate);
		useDiscussionService().openDiscussionFromContextAsync(discussionContext);
	}

	openNoteModal () {
		useOrderService().openNoteModalAsync(this);
	}

	openScheduleModal () {
		useOrderService().openScheduleModalAsync(this);
	}

	async validateAsync () {
		if (await useOrderService().validateOrderAsync(this.id)) {
			this.state.status = ORDER_STATUS.Validated;
		}
	}

	async cancelAsync () {
		if (this.state.status !== ORDER_STATUS.CancellationRequest || this.self.isAgency())
			await useOrderService().cancelOrderAsync(this.id);
		else
			await useOrderService().deleteCancelOrderRequestAsync(this.id);
	}

	async filterSelfTagsAsync () {
		const selfTags = await useTagUserService().selfAsync();
		if (!this.self.isCreator() && selfTags.length) {
			[...this.tagLinks].reverse().forEach((x) => {
				if (!selfTags.mapKey('tagId').includes(x.tagId)) {
					this.state.tagLinks.deleteWhere('tagId', x.tagId);
				}
			});
		}
	}

	afterCloneHook () {
		this.state.status = ORDER_STATUS.Created;
	}
}
