import { watch } from 'vue';
import BaseEntity from '../BaseEntity';
import OrderTimeSlotEntity from './OrderTimeSlotEntity';
import { pickFrom } from '@/utils/tools';
import { Reactive } from '@/utils/decorators';

export default class OrderScheduleEntity extends BaseEntity<IScheduleDefaultModel> {

	// eslint-disable-next-line local-rules/state-are-private-readonly
	@Reactive protected readonly selfState = {
		timeSlotEntities: [] as OrderTimeSlotEntity[],
		isActive: true,
	};

	get weekDay () { return this.state.weekDay; }

	get isActive () { return this.selfState.isActive; }
	set isActive (val) { this.selfState.isActive = val; }

	get scheduleDefaultTimeSlots () { return this.selfState.timeSlotEntities.mapKey('entityState');}
	set scheduleDefaultTimeSlots (val) {
		this.state.scheduleDefaultTimeSlots = val;
		this.selfState.timeSlotEntities = OrderTimeSlotEntity.map(this.state.scheduleDefaultTimeSlots ?? []);
	}

	get timeSlotEntities () { return this.selfState.timeSlotEntities;}
	set timeSlotEntities (val) { this.selfState.timeSlotEntities = val;}

	get workedHours () {
		return this.selfState.timeSlotEntities.reduce((acc, val) => {
			return acc + Math.abs(val.endHours - val.startHours);
		}, 0);
	}

	get readableDay () {
		switch (this.state.weekDay) {
		case 1:
			return 'Lundi';
		case 2:
			return 'Mardi';
		case 3:
			return 'Mercredi';
		case 4:
			return 'Jeudi';
		case 5:
			return 'Vendredi';
		case 6:
			return 'Samedi';
		case 0:
			return 'Dimanche';
		}
	}

	get entityState () {
		return {
			... pickFrom(
				this,
				'weekDay',
			),
			scheduleDefaultTimeSlots: this.scheduleDefaultTimeSlots,
		};
	}

	constructor (payload?: IScheduleDefaultModel) {
		super(payload);

		if (!payload?.scheduleDefaultTimeSlots.length)
			this.selfState.isActive = false;

		watch(() => this.selfState.timeSlotEntities.length, (val) => {
			this.selfState.isActive = val > 0;
		});
	}

	protected override onDataAssigned () {
		this.selfState.timeSlotEntities = OrderTimeSlotEntity.map(this.state.scheduleDefaultTimeSlots ?? []);
	}

}
