<template>
	<div
		v-if="setup.clone && setup.dataLoaded"
		class="specific-schedule flex g-sm fd-c">
		<div class="flex jc-fe">
			<o-datepicker
				v-if="setup.showDatepicker"
				:ref="setup._periodRange"
				v-model:range="setup.daterange"
				class="specific-schedule__datepicker"
				:min-date="setup.clone.start"
				:max-date="setup.clone.end"
				size="lg"
				label="Saissez votre période"
				type="range"
				@blur="setup.showDatepicker = false"/>
			<o-button
				v-if="!setup.showDatepicker && setup.order?.canModify() && setup.self.can('OrderUpdate')"
				suffix-font-icon="icon-interface-add-circle"
				nude
				color="info"
				@click="setup.togglePeriodSelection()">
				Ajouter des horaires spécifiques
			</o-button>
		</div>
		<div
			v-if="!setup.clone.scheduleOrderRangesEntities.length"
			class="specific-schedule--undefined">
			<o-icon font-icon="icon-interface-calendar-mark"/>
			<p>Aucun horaire spécifique pour cette commande</p>
		</div>


		<order-creation-schedule
			v-for="range in setup.clone.scheduleOrderRangesEntities"
			v-else
			:key="range.id"
			:order="setup.clone"
			:schedule-range="range"
			specific/>
	</div>

	<o-footer-fixed
		:visible="setup.orderHasChanged"
		class="specific-schedule__footer">
		<div class="flex">
			<div class="specific-schedule__footer-recap">
				<o-icon font-icon="icon-interface-alert-information-circle"/>
				<div>Vous avez modifié les horaires</div>
			</div>
			<o-section align="center">
				<o-button
					color="danger"
					outline
					@click="setup.order?.resetClone()">
					Annuler
				</o-button>
				<o-button
					color="info"
					@click="setup.saveChangesAsync()">
					Enregistrer
				</o-button>
			</o-section>
		</div>
	</o-footer-fixed>
</template>

<script setup lang="ts">
import OrderDetailsSpecificScheduleModalSetupService from '@/setup/order/OrderDetailsSpecificScheduleModalSetupService';
import OrderCreationSchedule from '@/components/order/creation/OrderCreationSchedule.vue';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(OrderDetailsSpecificScheduleModalSetupService.props);
const setup = new OrderDetailsSpecificScheduleModalSetupService(props, _modal);
</script>

<style scoped lang="less">
@import '@/styles/variables';


.specific-schedule {

	padding-bottom: 3rem;

	&__footer {
    display: flex;
		left: 0;
  }

	&__datepicker {
		min-width: 12rem;
	}

  &__footer-recap {
    display: flex;
		justify-content: space-between;
    align-items: center;
    margin-right: 3rem;

    .orion-icon {
      font-size: 2.5rem;
      margin-right: 2rem;
      color: var(--grey);
    }
  }

	&--undefined {
    display: flex;
    flex-direction: column;
    margin-top: 6.3rem;

    .orion-icon {
      font-size: 4.4rem;

      margin: auto;
      color: var(--grey-light);
    }

    p {
			margin-top: 1rem;
      color: var(--grey);
      text-align: center;
      font-style: italic;
    }
	}
}
</style>
