import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/ordertemplate');

class OrderTemplateApiAgency {

	static grid (orderTemplatePagedList: IOrderTemplatePagedListParameters) {
		return client.post<IPagedList<IOrderTemplateItemModel>>('grid', orderTemplatePagedList);
	}

	static list (orderTemplateList: IOrderTemplateListParameters) {
		return client.get<IOrderTemplateModel[]>('list', { params: { orderTemplateList } });
	}

	static add (addOrderTemplateModel: IAddOrderTemplateModel) {
		return client.post<IOrderTemplateItemModel>('', addOrderTemplateModel);
	}

	static update (id: number, updateOrderTemplateModel: IUpdateOrderTemplateModel) {
		return client.put<IOrderTemplateItemModel>(id.toString(), updateOrderTemplateModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}
}

export default OrderTemplateApiAgency;
