import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/companysetting');

class CompanySettingApiClient {
	static selectSetting (settingType: number, agencyCompanyId: number) {
		return client.get<ICompanySettingModel | undefined>(`?settingType=${settingType}&agencyCompanyId=${agencyCompanyId}`);
	}

	static currencyList () {
		return client.get<ICompanySettingModel[]>(`currency`);
	}
}

export default CompanySettingApiClient;
