import AppSignalR from '@/signalr/App.signalr';
import signalrClientMethod from './names/Signalr.clientMethod';
import signalrServerMethod from './names/Signalr.serverMethod';

class SignalRDiscussion {

	static onTyping (listener: () => void) {
		return AppSignalR.on(signalrClientMethod.DiscussionTyping, listener);
	}

	static offTyping (listener: () => void) {
		return AppSignalR.off(signalrClientMethod.DiscussionTyping, listener);
	}

	static sendTyping (discussionId: number) {
		return AppSignalR.invoke(signalrServerMethod.OnDiscussionTyping, discussionId);
	}

	static join (discussionId: number) {
		return AppSignalR.invoke(signalrServerMethod.OnDiscussionJoin, discussionId);
	}

	static leave (discussionId: number) {
		return AppSignalR.invoke(signalrServerMethod.OnDiscussionLeave, discussionId);
	}
}

export default SignalRDiscussion;
