import UserLanguageApiTempWorker from '@/api/UserLanguageApiTempWorker';
import LanguageApi from '@/api/LanguageApi';
import { LANGUAGE_LEVEL } from '@/typings/enums/LANGUAGE_LEVEL';

const languagesLevels = [
	['A1', 'Basique'],
	['A2', 'Débutant'],
	['B1', 'Intermédiaire'],
	['B2', 'Courant'],
	['C1', 'Avancé'],
	['C2', 'Bilingue'],
] as const;

class LanguageService {
	get readableLanguageLevelOptions () {
		return languagesLevels.map(x => ({
			code: LANGUAGE_LEVEL[x[0]],
			label: `${x[0]} - ${x[1]}`,
		}));
	}

	getReadableLanguageLevel (level: LANGUAGE_LEVEL) {
		return this.readableLanguageLevelOptions.findByKey(level, 'code')?.label;
	}

	async getLanguageListAsync () {
		const { data } = await LanguageApi.list();
		return data;
	}

	async addUserLanguageAsync (...args: Parameters<typeof UserLanguageApiTempWorker.add>) {
		const { data } = await UserLanguageApiTempWorker.add(...args);
		return data;
	}

	async deleteUserLanguageAsync (...args: Parameters<typeof UserLanguageApiTempWorker.delete>) {
		const { data } = await UserLanguageApiTempWorker.delete(...args);
		return data;
	}
}

const languageServiceSingleton = new LanguageService();

export default function useLanguageService () {
	return languageServiceSingleton;
}
