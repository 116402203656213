<template>
	<div
		v-if="order"
		class="order-recap-card">
		<h4 class="order-step__title">
			<span class="text--brand">
				Récapitulatif
			</span> - Vérifiez les champs de votre commande
		</h4>

		<div class="order-recap-card__section">
			<o-icon
				ripple="info"
				class="order-recap-card__section-modifier"
				font-icon="icon-interface-edit-pencil"
				@click="$emit('current', 1)"/>
			<strong class="order-recap-card__section-title">
				<span class="text--brand">Etape 1</span> - Sélectionnez l'agence et l'entreprise à solliciter :
			</strong>
			<div class="order-recap-card__section-content">
				<o-icon-section font-icon="icon-shopping-store-phone">
					<p>
						Agence <br>
						<strong>{{ order.agencyCompanyName }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section fon-icon="icon-interface-user-home">
					<p>
						Entreprise - EU <br>
						<strong>{{ order.clientCompanyName }}</strong>
					</p>
				</o-icon-section>
			</div>
		</div>

		<div
			class="order-recap-card__section"
			:class="{ 'order-recap-card__section--warning' : !order.requiredTempWorker }">
			<o-icon
				ripple="info"
				class="order-recap-card__section-modifier"
				font-icon="icon-interface-edit-pencil"
				@click="$emit('current', 2)"/>
			<strong class="order-recap-card__section-title">
				<span class="text--brand">Etape 2</span> - Quel est votre besoin :
			</strong>
			<o-alert
				v-if="!order.requiredTempWorker"
				color="warning">
				Vous devez renseigner le nombre d'intérimaires souhaité pour continuer
			</o-alert>
			<div
				v-if="order.professionalSkill"
				class="order-recap-card__section-content">
				<o-icon-section font-icon="icon-interface-user-multiple">
					<p>
						Nombre d'intérimaires <br>
						<strong>{{ `intérimaire`.pluralize(order.requiredTempWorker ? order.requiredTempWorker : 0) }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section font-icon="icon-money-cash-file-dollar">
					<p>
						Revenu / Taux horaire<br>
						<strong>{{ order.hourlyRate.toCurrency(setup.currency) }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section font-icon="icon-programming-script-2">
					<p>
						Qualification<br>
						<strong>{{ order.professionalSkill.label }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section font-icon="icon-programming-script-2">
					<p>
						{{ `${'Qualification secondaire'.pluralize(order.orderProfessionalSkillsProfessionalSkill.length, false)}` }}<br>
						<strong v-if="order.orderProfessionalSkillsProfessionalSkill.length">
							{{ order.orderProfessionalSkillsProfessionalSkill?.mapKey('label').join(', ') }}
						</strong>
						<strong v-else>Non renseignée</strong>
					</p>
				</o-icon-section>

				<o-icon-section font-icon="icon-travel-map-earth-2">
					<p>
						Langues<br>
						<strong v-if="order.languages">{{ order.languages.map(x => x.name).join(', ') }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section
					v-if="order.needPersonalVehicle"
					font-icon="icon-travel-transportation-car-1">
					<p>
						Véhicule<br>
						<strong>Moyen de locomotion requis</strong>
					</p>
				</o-icon-section>
				<o-icon-section
					font-icon="icon-interface-file-clipboard-text">
					<p>
						Motif<br>
						<strong>{{ order.motive?.motive ?? "Non renseingé" }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section
					font-icon="icon-interface-file-clipboard-text">
					<p>
						Justificatif<br>
						<strong>{{ order.justificationMotive ?? "Non renseingé" }}</strong>
					</p>
				</o-icon-section>
				<div class="order-recap-card__section-docs">
					<div>
						<h5>Equipements de protection individuelle (EPI)</h5>
						<div class="order-recap-card__section-pictos">
							<shared-picto-card
								v-for="gear in order.gears"
								:key="gear.id"
								:picto="gear"
								tag="icon"
								label/>
						</div>
						<o-chips
							v-if="setup.gearsAreEmpty"
							outline>
							Non renseigné
						</o-chips>
					</div>

					<div v-if="setup.self.isAgency()">
						<h5>Documents spécifiques</h5>
						<div
							v-if="setup.self.isAgency()"
							class="order-recap-card__section-pictos">
							<shared-picto-card
								v-for="justif in order.justificatories"
								:key="justif.id"
								:picto="justif"
								tag="icon"
								label/>
						</div>
						<o-chips
							v-if="setup.justificatoriesAreEmpty"
							outline>
							Non renseigné
						</o-chips>
					</div>
				</div>
			</div>
		</div>

		<div
			class="order-recap-card__section"
			:class="{ 'order-recap-card__section--warning' : setup.datesArePast }">
			<o-icon
				ripple="info"
				class="order-recap-card__section-modifier"
				font-icon="icon-interface-edit-pencil"
				@click="$emit('current', 3)"/>
			<strong class="order-recap-card__section-title">
				<span class="text--brand">Etape 3</span> - Quand en avez-vous besoin :
			</strong>
			<o-alert
				v-if="setup.datesArePast"
				color="warning">
				Attention les dates saisies sont passées
			</o-alert>
			<div
				class="order-recap-card__section-content">
				<o-icon-section font-icon="icon-interface-calendar-mark">
					<p>
						Date de début <br>
						<strong>{{ order.start.toReadable() }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section font-icon="icon-interface-time-clock-circle">
					<p>
						Heure de rendez-vous <br>
						<strong>{{ setup.startHour }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section font-icon="icon-interface-calendar-mark">
					<p>
						Date de fin <br>
						<strong>{{ order.end.toReadable() }}</strong>
					</p>
				</o-icon-section>
				<o-icon-section font-icon="icon-interface-time-clock-circle">
					<p>
						Heure de fin de mission <br>
						<strong>{{ setup.endHour }}</strong>
					</p>
				</o-icon-section>
			</div>
			<o-button
				color="info"
				outline
				@click="$emit('current', 3)">
				Voir les horaires saisis
			</o-button>
		</div>

		<div class="order-recap-card__section">
			<o-icon
				ripple="info"
				class="order-recap-card__section-modifier"
				font-icon="icon-interface-edit-pencil"
				@click="$emit('current', 4)"/>
			<strong class="order-recap-card__section-title">
				<span class="text--brand">Etape 4</span> - Où en avez-vous besoin :
			</strong>
			<div class="order-recap-card__section-content">
				<o-icon-section font-icon="icon-travel-map-location-pin">
					<p>
						<strong>{{ setup.location.address }}</strong> <br>
						<strong>{{ setup.location.city }} {{ setup.location.postalCode }}, {{ setup.location.countryCode }}</strong>
					</p>
				</o-icon-section>
			</div>
			<div class="order-recap-card__section-content">
				<div class="order-recap-card__section-paragraph flex fd-c g-xs">
					<p>Interlocuteur chez l'entreprise utilisatrice <em class="tip">(partagé avec l'intérimaire)</em></p>
					<div
						v-if="order.orderInterlocutor?.firstName"
						class="flex g-xl">
						<div class="flex fd-c g-xs">
							<div>
								<o-icon
									font-icon="icon-user-single-neutral"
									class="classic"/>
								{{ order.orderInterlocutor?.firstName }} {{ order.orderInterlocutor?.lastName }}
							</div>
							<div>
								<o-icon
									font-icon="icon-mail-inbox-envelope-open"
									class="classic"/>
								{{ order.orderInterlocutor?.email?.length ? order.orderInterlocutor?.email : 'non renseignée' }}
							</div>
						</div>
						<div>
							<o-icon
								class="classic"
								font-icon="icon-phone-telephone"/>
							{{ order.orderInterlocutor?.telephone?.length ? order.orderInterlocutor?.telephone : 'non renseigné' }}
						</div>
					</div>
					<div v-else>
						Non renseigné
					</div>
				</div>
			</div>
		</div>


		<div class="order-recap-card__section">
			<o-icon
				ripple="info"
				class="order-recap-card__section-modifier"
				font-icon="icon-interface-edit-pencil"
				@click="$emit('current', 5)"/>
			<strong class="order-recap-card__section-title">
				<span class="text--brand">Etape 5</span> - Description et commentaire :
			</strong>
			<div class="order-recap-card__section-content">
				<div class="order-recap-card__section-paragraph">
					<p>Description</p>
					<div class="formatted-text">{{ order.jobDescription ?? 'Non renseignée' }}</div>
				</div>
			</div>
			<div class="order-recap-card__section-content">
				<div class="order-recap-card__section-paragraph">
					<p>Commentaire</p>
					<div class="formatted-text">{{ order.comment ?? 'Non renseigné' }}</div>
				</div>
			</div>
			<div class="order-recap-card__section-content">
				<div class="order-recap-card__section-paragraph">
					<shared-tag-select
						v-if="order.tagIds.length"
						v-model="order.tagIds"
						disabled
						multiple/>
				</div>
			</div>
			<div
				v-if="setup.self.isAgency()"
				class="order-recap-card__section-content">
				<div class="order-recap-card__section-paragraph">
					<o-alert
						v-if="order.warnClient"
						color="info">
						Le client sera prévenu de la création de la commande
					</o-alert>
					<o-alert
						v-else
						color="warning">
						Le client ne sera pas prévenu de la création de la commande
					</o-alert>
				</div>
			</div>
		</div>

		<div
			v-if="setup.candidatesToAdd.length && setup.self.isAgency()"
			class="order-recap-card__section">
			<strong class="order-recap-card__section-title">
				Candidats positionnés
			</strong>
			<o-button
				nude
				:color="setup.removeAllCandidates ? `danger`: `info`"
				class="order-recap-card__section-modifier order-recap-card__section-modifier--button"
				:prefix-font-icon="!setup.removeAllCandidates ? 'icon-interface-arrows-round-left' : 'icon-interface-block'"
				@click="setup.toggleCandidates()">
				{{ setup.removeAllCandidates ? `Supprimer tous les candidats` : `Annuler la suppression` }}
			</o-button>
			<div
				class="order-recap-card__section-content">
				<o-sticker
					v-for="candidate in setup.candidatesToAdd"
					:key="candidate.userId"
					class="order-recap-card__candidate-sticker"
					:class="{ 'order-recap-card__candidate-sticker--removed' : candidate.removed }">
					<o-avatar
						:name="candidate.userName"
						size="xs"
						:avatar="candidate.userAvatarId"/>
					<div>
						<strong>{{ candidate.userName }}</strong><br>
						Etape : <span :class="{ 'text--success' : candidate.orderStepTitle === 'Validé' }">{{ candidate.orderStepTitle }}</span>
					</div>
					<o-icon
						v-tooltip="candidate.removed ? 'Annuler' : `Supprimer de la commande` "
						:ripple="candidate.removed ? 'info' : 'danger'"
						:font-icon="candidate.removed ? 'icon-interface-arrows-round-left' : 'icon-interface-block'"
						@click="candidate.removed = !candidate.removed"/>
				</o-sticker>
			</div>
		</div>

		<o-section align="center">
			<o-button
				prefix-font-icon="icon-interface-arrows-button-left"
				outline
				@click="$emit('current', 5)">
				Précédent
			</o-button>
			<o-button
				prefix-font-icon="icon-mail-send-email"
				:disabled="!setup.isValid"
				:outline="!setup.isValid"
				:color="!setup.isValid ? 'default' : `success`"
				@click="setup.submitAsync()">
				Envoyer la commande
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import OrderCreationRecapCardSetupService from '@/setup/order/creation/OrderCreationRecapCardSetupService';
import { inject } from 'vue';
type Emits = { (e: 'current' | 'origin', val: number): void }
const emits = defineEmits<Emits>();
const _aside = inject<OrionAside>('_aside');
const props = defineProps(OrderCreationRecapCardSetupService.props);
const setup = new OrderCreationRecapCardSetupService(props, emits, _aside);
</script>

<style lang="less">
@import '@/styles/mixins';
@import '@/styles/variables';

.order-recap-card {
	max-width: 80%;
	margin: auto;

	@media @phone, @tabletPortraitOnly {
		max-width: 100%;
	}

	p {
		margin: unset;
	}

	.orion-icon-section {
		flex: 0 0 18.8rem;
		margin-bottom: 1.3rem;

		@media @tabletPortraitOnly {
			flex: 0 0 15.6rem;
		}

		.orion-icon {
			margin-left: 0;
		}

		strong {
			font-weight: 600;
		}
	}

	.orion-icon-section + .orion-icon-section {
		margin-top: unset;
	}

	&__section {
		margin: 0 0 1.3rem;
		padding: 1.3rem;
		position: relative;
		border: 0.06rem solid var(--grey-light);
		border-radius: 0.3rem;
		transition: background .2s;
		.orion-alert {
			margin-bottom: 1.3rem;
		}

		&:not(.order-recap-card__section--warning):hover {
			background: rgba(var(--rgb-grey-lighter), 0.7);
		}

		&-title {
			display: block;
			margin-bottom: 1.9rem;
		}

    &-docs {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-pictos {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, 4rem);
      gap: 1rem;

      > .picto-card {
        height: 100%;
      }
    }

		&-content {
			display: flex;
			flex-wrap: wrap;
			gap: 0.6rem;
		}

		&-paragraph {
			margin-bottom: 1.9rem;
			color: var(--grey-dark);
			flex:1;
			p {
				color: var(--grey-darker);
				font-weight: 600;
			}

			.tag-dots-expandable {
				display: flex;
				gap: 0.5rem;
				margin-top: 0.5rem;
			}
		}

		&-modifier {
			position: absolute;
			top: 1.7rem;
			right: 1.25rem;

			&--button {
				top: 0.6rem;
			}
		}

		&--warning {
			border-color: var(--warning);
			background: rgba(var(--rgb-warning), 0.1);
		}
	}

	&__candidate-sticker {
		width: calc(50% - 0.3rem);
		position: relative;
		transition: all .2s;
		.orion-sticker__content {
			display: flex;
			align-items: center;
			gap: 0.75rem;
		}
		.orion-icon {
			font-size: 1.25rem;
			position: absolute;
			right: 1.25rem;
		}

		&--removed {
			background: var(--grey-light);
			opacity: 0.4;

			&:hover{
				opacity: 1;
			}
		}
	}

	.orion-sticker + .orion-sticker {
		margin: unset;
	}
}
</style>
