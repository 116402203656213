import { PropType, reactive, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import CompanyApi from '@/api/CompanyApi';
import CompanyApiClient from '@/api/CompanyApiClient';
import CompanyApiAgency from '@/api/CompanyApiAgency';

type Props = SetupProps<typeof SharedSelectUserCompanySetupService.props>

type Emits = {
	(e: 'update:modelValue' | 'input', id: number | number[] | undefined): void,
	(e: 'update:selected', companyModel?: ICompanyModel | ICompanyModel[]): void,
	(e: 'select', companyModel: ICompanyModel | ICompanyModel[]): void
}
type CompanyElement = ICompanyWithCheckPluginOrderModel & {
	disabled: boolean
}


export default class SharedSelectUserCompanySetupService extends BaseSetupService<Props> {
	static readonly props = {
		hideAgencyName: Boolean,
		setToFirst: Boolean,
		autoSelectSingle: Boolean,
		displayValidation: Boolean,
		organizationOnly: Boolean,
		agency: Boolean,
		client: Boolean,
		agencyCompanyId: {
			type: Number,
			default: null,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		modelValue: {
			type: [Number, Array] as PropType<number[] | number | undefined>,
			default: null,
		},
		options: {
			type: Array as PropType<Nullable<ICompanyModel[]>>,
			default: null,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		admin: {
			type: Boolean,
			default: false,
		},
		permissionAction: {
			type: Number,
			default: null,
		},
		checkOrderPlugin: {
			type: Boolean,
			default: false,
		},
	};

	private emits: Emits;
	private readonly state = reactive({ companies: [] as Partial<CompanyElement>[] });


	get searchable () { return this.companies.length >= 10; }

	get selectOptions () {
		if (this.props.options) return this.props.options;
		else return this.companies;
	}

	get publicInstance () {
		return { getOptions: () => this.selectOptions as ICompanyWithCheckPluginOrderModel[] };
	}

	get vModel () { return this.props.modelValue; };
	set vModel (val) { this.emits('update:modelValue', val); };

	get companies () { return this.state.companies; }
	set companies (val) { this.state.companies = val; }


	constructor (props: Props, emits: Emits) {
		super(props);

		this.emits = emits;

		watch(() => this.props.agencyCompanyId, (val) => {
			if (val) this.vModel = undefined;
			this.getCompaniesAsync();
		});

		watch(() => this.props.modelValue, (val) => {
			if (typeof val === 'number') {
				this.emits('update:selected', this.companies.findByKey(val, 'id') as Undef<ICompanyModel>);
			} else {
				this.emits('update:selected', this.companies.filter(x => x.id && val?.includes(x.id)) as Undef<ICompanyModel[]>);
			}
		});
	}

	async onBeforeMountAsync () {
		await this.getCompaniesAsync(true);
	}

	computedLabel () {
		if (this.props.agency) return 'Agence';
		else if (this.props.client) return 'Entreprise';
		else return this.self.isClient() ? 'Entreprise' : 'Agence';
	}

	handleAutoSelect () {
		if (this.selectOptions?.length) {
			const firstCompany = this.selectOptions.first() as CompanyElement;
			if (firstCompany && !this.props.modelValue
        && (this.props.setToFirst || (this.props.autoSelectSingle && this.selectOptions.length === 1)) && !firstCompany.disabled
			) {
				if (this.props.multiple) {
					this.emits('input', [firstCompany.id]);
					this.emits('update:modelValue', [firstCompany.id]);
					this.emits('update:selected', [firstCompany]);
					this.emits('select', [firstCompany]);
				} else {
					this.emits('input', firstCompany.id);
					this.emits('update:modelValue', firstCompany.id);
					this.emits('update:selected', firstCompany);
					this.emits('select', firstCompany);
				}
			}
		}
	}

	async getCompaniesAsync (autoSelect = false) {
		const {
			permissionAction, agencyCompanyId, checkOrderPlugin,
		} = this.props;

		if ((!this.props.agency && !this.props.client)
		|| (this.self.isAgency() && this.props.agency)
		|| (this.self.isClient() && this.props.client && !this.props.agencyCompanyId)
		|| this.self.isTempWorker()
		) {
			const { data } = await CompanyApi.get({
				permissionAction,
				checkOrderPlugin,
				organizationId: this.props.organizationOnly ? this.self.organization?.id : undefined,
				agencyCompanyId: this.props.agencyCompanyId,
			});

			(data as CompanyElement[]).forEach((element) => {
				element.disabled = element.canCreateOrder !== undefined ? !element.canCreateOrder : false;
			});
			this.companies = data;
		} else if (this.self.isClient()) {
			if (this.props.agency) {
				const { data } = await CompanyApiClient.getAgencyList(permissionAction, checkOrderPlugin);
				(data as CompanyElement[]).forEach((element) => {
					element.disabled = element.canCreateOrder !== undefined ? !element.canCreateOrder : false;
				});
				this.companies = data;
			} else if (this.props.client) {
				const { data } = await CompanyApi.get({
					agencyCompanyId,
					permissionAction,
					checkOrderPlugin,
				});
				this.companies = data;
			}
		} else if (this.self.isAgency() && this.props.client) {
			const { data } = await CompanyApiAgency.clientList({
				agencyCompanyId,
				checkOrderPlugin: false,
			});
			this.companies = data;
		}
		this.companies.sort(function (a, b) {
			if (a.name && b.name) {
				if (a.name < b.name) { return -1; }
				if (a.name > b.name) { return 1; }
			}
			return 0;
		});

		if (autoSelect) {
			this.handleAutoSelect();
		}
	}
}
