import { PropType, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import type OrderEntity from '@/entity/order/OrderEntity';
import { useNotif, useValidation } from '@orion.ui/orion';
import { Emits } from './OrderCreationAsideSetupService';

type Props = SetupProps<typeof OrderCreationStep1SetupService.props>

export default class OrderCreationStep1SetupService extends BaseSetupService<Props> {
	static readonly props = {
		order: {
			type: Object as PropType<OrderEntity>,
			required: true as const,
		},
	};

	readonly _aside?: OrionAside;
	private emits: Emits;

	validationRules = {
		agencyCompanyId: 'required',
		clientCompanyId: 'required',
	};

	validator = useValidation(this.props.order, this.validationRules);

	constructor (props: Props, emits: Emits, aside?: OrionAside) {
		super(props);
		this._aside = aside;
		this.emits = emits;

		watch(() => this.props.order.agencyCompanyId, () => {
			if (this.props.order.asideActivePane === 6) return;
			this.props.order.clientCompanyId = undefined as unknown as number; // bypass type from back when creating order;
		});
	}

	selectAgency (payload: ICompanyModel) {
		this.props.order.agencyCompanyName = payload.name;
	}

	selectClient (payload: ICompanyModel) {
		this.props.order.clientCompanyName = payload.name;
	}

	next () {
		if (!this.validator.validate()) {
			this.validator.showValidationState();
			useNotif.danger('Vous devez choisir une Agence et un Client');
			return;
		}
		this.props.order.asideActivePane = 2;
		this.emits('current', 2);
	}
}
