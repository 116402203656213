import ScheduleOrderRangeApi from '@/api/ScheduleOrderRangeApi';

class ScheduleOrderRangesService {
	async updateAsync (...args: Parameters<typeof ScheduleOrderRangeApi.update>) {
		const { data } = await ScheduleOrderRangeApi.update(...args);
		return data;
	}
}

export default function useScheduleOrderRangesService () {
	return new ScheduleOrderRangesService();
}

