<template>
	<div class="order-step">
		<o-section id="order-step-4">
			<h4 class="order-step__title">
				<span class="text--brand">
					Étape 4
				</span> - Où en avez-vous besoin ?
			</h4>
			<div class="row row--grid">
				<div class="col-sm-12">
					<div class="mission-place">
						<template v-if="setup.self.isClient()">
							<shared-mission-place-select
								v-model:mission-place="order.missionPlace"
								:value="order.missionPlace?.id"
								clearable/>
							<span>ou</span>
						</template>

						<template v-if="setup.self.isAgency() && !!order.missionPlace">
							<o-textarea
								v-model="setup.missionPlaceForAgency"
								label="Lieu de mission"
								disabled/>
							<span>ou</span>
						</template>

						<shared-google-auto-complete
							v-model="order.address"
							label="Recherchez une adresse"
							:required="order.missionPlace?.id ? false : true"
							:validation="setup.validator?.rule('address')"
							@google-found="setup.handleGoogleAddressAsync($event)"/>
					</div>
				</div>

				<template v-if="!order.missionPlace">
					<div
						v-show="order.address"
						class="col-sm-4">
						<o-select
							v-show="order.address"
							v-model="order.countryCode"
							label="Pays"
							track-key="code"
							value-key="code"
							display-key="name"
							required
							searchable
							:options="setup.countries"
							@input="setup.switchingCountry()"/>
					</div>

					<div
						v-show="order.countryCode"
						class="col-sm-4">
						<o-input
							v-model="order.postalCode"
							required
							label="Code postal"
							:max-length="order.countryCode === 'FR' ? 5 : 10"
							@input="setup.getCitiesAsync(order.postalCode)"/>
					</div>

					<div
						v-show="order.countryCode"
						class="col-sm-4">
						<o-select
							v-if="order.countryCode === 'FR' && setup.cityOptions.length"
							:ref="setup._citySelect"
							v-model="order.city"
							required
							label="Ville"
							track-key="code"
							display-key="nom"
							value-key="nom"
							searchable
							:options="setup.cityOptions"/>
						<o-input
							v-else
							v-model="order.city"
							required
							label="Ville"/>
					</div>
				</template>
			</div>
		</o-section>

		<o-section>
			<h4 class="order-step__title">
				Qui sera votre interlocuteur chez l'entreprise utilisatrice ?<br>
				<em class="tip">(partagé avec l'intérimaire)</em>
			</h4>

			<div
				v-if="setup.interlocutor"
				class="order-step__form">
				<div
					class="row row--grid">
					<div class="col-sm-6">
						<o-input
							v-model="setup.interlocutor.lastName"
							:required="!!setup.interlocutor.firstName"
							:validation="setup.interlocutorValidator?.rule('name')"
							label="Nom"/>
					</div>
					<div
						class="col-sm-6">
						<o-input
							v-model="setup.interlocutor.firstName"
							:required="!!setup.interlocutor.lastName"
							:validation="setup.interlocutorValidator?.rule('firstName')"
							label="Prénom"/>
					</div>
				</div>
				<div class="row row--grid">
					<div class="col-sm-6">
						<o-input
							v-model="setup.interlocutor.email"
							:validation="setup.interlocutorValidator?.rule('email')"
							label="Adresse email"/>
					</div>
					<div class="col-sm-6">
						<o-phone
							v-model="setup.interlocutorPhoneNumber"
							:validation="order.orderInterlocutor?.telephone ? setup.interlocutorValidator?.rule('telephone') : undefined"
							label="Téléphone"/>
					</div>
				</div>
			</div>
		</o-section>

		<o-section
			v-if="order.asideActivePane !== 6"
			align="center">
			<o-button
				prefix-font-icon="icon-interface-arrows-button-left"
				outline
				@click="$emit('current', 3)">
				Précédent
			</o-button>
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				outline
				@click="setup.next(5)">
				Suivant
			</o-button>
		</o-section>

		<o-section
			v-else
			align="center">
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				color="success"
				@click="setup.next(6)">
				Valider
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">

import OrderCreationStep4SetupService from '@/setup/order/creation/OrderCreationStep4SetupService';
type Emits = { (e: 'current' | 'origin', val: number): void }
const props = defineProps(OrderCreationStep4SetupService.props);
const emits = defineEmits<Emits>();
const setup = new OrderCreationStep4SetupService(props, emits);
</script>


<style lang="less" scoped>
@import '@/styles/variables';

.mission-place {
  display: flex;
  align-items: center;
  gap: 0.9rem;
  justify-content: space-between;

  > span {
    font-size: 1rem;
    color: var(--grey-dark);
  }

  > :first-child, > :last-child {
    flex: 1;
  }
}
</style>
