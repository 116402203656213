import { watch } from 'vue';
import BaseEntity from '../BaseEntity';
import OrderTimeSlotEntity from './OrderTimeSlotEntity';
import { pickFrom } from '@/utils/tools';
import { Reactive } from '@/utils/decorators';

export default class OrderSpecificScheduleEntity extends BaseEntity<IScheduleOrderModel> {

	// eslint-disable-next-line local-rules/state-are-private-readonly
	@Reactive protected readonly selfState = {
		timeSlotEntities: [] as OrderTimeSlotEntity[],
		isActive: true,
		weeknumber: undefined as Undef<number>,
		daterange: undefined as Undef<Orion.DateRange>,

	};

	get isActive () { return this.selfState.isActive; }
	set isActive (val) { this.selfState.isActive = val; }
	get weeknumber () { return this.selfState.weeknumber; }
	get daterange () { return this.selfState.daterange; }
	set daterange (val) { this.selfState.daterange = val; }
	get date () { return this.state.date; }
	set date (val) {
		this.state.date = val;
		this.selfState.weeknumber = new Date(this.date).getWeekNumber();
	}

	get scheduleOrderTimeSlots () { return this.selfState.timeSlotEntities.mapKey('entityState');}
	set scheduleOrderTimeSlots (val) {
		this.state.scheduleOrderTimeSlots = val;
		this.selfState.timeSlotEntities = OrderTimeSlotEntity.map(this.state.scheduleOrderTimeSlots ?? []);
	}

	get workedHours () {
		return this.selfState.timeSlotEntities.reduce((acc, val) => {
			return acc + Math.abs(val.endHours - val.startHours);
		}, 0);
	}

	get weekDay () { return this.state.weekDay; }

	get timeSlotEntities () { return this.selfState.timeSlotEntities;}
	set timeSlotEntities (val) { this.selfState.timeSlotEntities = val;}

	get readableDay () {return new Date(this.state.date).toReadable('$ddd');}

	get entityState () {
		return {
			... pickFrom(
				this,
				'date',
				'id',
				'weekDay'),
			weeknumber: this.selfState.weeknumber,
			scheduleOrderTimeSlots: this.selfState.timeSlotEntities.mapKey('entityState'),
		};
	}

	constructor (payload?: IScheduleOrderModel) {
		super(payload);

	}

	protected override onDataAssigned () {
		if (this.date)
			this.selfState.weeknumber = new Date(this.date).getWeekNumber();
		this.selfState.timeSlotEntities = OrderTimeSlotEntity.map(this.state.scheduleOrderTimeSlots ?? []);
	}

	protected onInstanceCreated (): void {
		watch(() => this.selfState.timeSlotEntities.length, (val) => {
			this.selfState.isActive = val > 0;
		});

	}

	setDaterange (daterange: Orion.DateRange) {
		this.selfState.daterange = daterange;
	}

}
