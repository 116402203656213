import useOrderService from '@/services/OrderService';

export const tempWorkerAsideNavigation: Armado.AsideNavigationItems = {
	'Profile': [
		{
			label: 'Informations',
			to: { name: 'UserProfileInformations' },
		},
		{
			label: 'Justificatifs',
			to: { name: 'UserProfileTempWorkerDocuments' },
		},
		{
			label: 'Agences',
			to: { name: 'UserProfileCompanies' },
		},
		{
			label: 'Disponibilités',
			to: { name: 'UserProfileAvailabilities' },
		},
		{
			label: 'Mon compte',
			to: { name: 'UserProfileAccount' },
		},
		{
			label: 'Mes appareils',
			to: { name: 'UserProfileDevices' },
		},
	],
};

export const clientAsideNavigation: Armado.AsideNavigationItems = {
	'Profile': [
		{
			label: 'Informations',
			to: { name: 'UserProfileInformations' },
		},
		{
			label: 'Mes entreprises',
			to: { name: 'UserProfileCompanies' },
		},
		{
			label: 'Mon compte',
			to: { name: 'UserProfileAccount' },
		},
		{
			label: 'Mes appareils',
			to: { name: 'UserProfileDevices' },
		},
	],
	'Organization': [
		{
			label: `Actions en attente`,
			to: { name: 'OrganizationPendingActions' },
		},
		{
			label: `Demandes de fusion`,
			to: { name: 'OrganizationFusionRequests' },
		},
		{
			label: `Fonctionnement`,
			to: { name: 'OrganizationUsage' },
		},
	],
	'OrderDetail': [
		{
			label: `Suivi de la commande`,
			to: { name: 'OrderDetailsStatus' },
		},
		{
			label: `Détail de la commande`,
			to: { name: 'OrderDetailsRecap' },
		},
		{
			label: `Détail des horaires`,
			callback: () => { useOrderService().openScheduleRecapModalAsync(); },
		},
		{
			label: `Contrats liés`,
			to: { name: 'OrderDetailsClientContract' },
		},
		/*
		{
			label: `Suivi des candidats`,
			to: { name: 'OrderDetailsPipeline' },
		},
		{
			label: `Historique`,
			to: { name: 'OrderDetailsHistory' },
		}, */
	],
};

export const agencyAsideNavigation: Armado.AsideNavigationItems = {
	'Profile': [
		{
			label: 'Informations',
			to: { name: 'UserProfileInformations' },
		},
		{
			label: 'Mes agences',
			to: { name: 'UserProfileCompanies' },
		},
		{
			label: 'Mon compte',
			to: { name: 'UserProfileAccount' },
		},
		{
			label: 'Mes appareils',
			to: { name: 'UserProfileDevices' },
		},
	],
	'CgvCtt': [
		{
			label: 'En cours',
			to: {
				name: 'CgvCtt',
				params: { status: 'en-cours' },
			},
		},
		{
			label: 'À venir',
			to: {
				name: 'CgvCtt',
				params: { status: 'a-venir' },
			},
		},
		{
			label: 'Passées',
			to: {
				name: 'CgvCtt',
				params: { status: 'passees' },
			},
		},
	],
	'Settings': [
		{
			label: 'Relances',
			to: { name: 'SettingsReminders' },
		},
		{
			label: 'Factures',
			to: { name: 'SettingsBills' },
		},
		{
			label: 'Acompte',
			to: { name: 'SettingsAdvancedPayment' },
		},
		{
			label: 'Représentant légal',
			to: { name: 'SettingsDelegates' },
		},
	],
	'Toolbox': [
		{
			label: 'Viviers',
			to: { name: 'ToolboxTalentPool' },
		},
		{
			label: 'Modèles de tâche',
			to: { name: 'ToolboxTaskTemplate' },
		},
		{
			label: 'Descriptions de poste',
			to: { name: 'ToolboxJobDescription' },
		},
	],
	'Organization': [
		{
			label: `Actions en attente`,
			to: { name: 'OrganizationPendingActions' },
		},
		{
			label: `Demandes de fusion`,
			to: { name: 'OrganizationFusionRequests' },
		},
		{
			label: `Fonctionnement`,
			to: { name: 'OrganizationUsage' },
		},
	],
	'OrderDashboard': [
		{
			label: `Tableau de bord`,
			to: { name: 'OrderDashboardOverView' },
		},
		{
			label: `Gestion intérimaire`,
			to: { name: 'OrderDashboardGestionTempWorker' },
		},
		{
			label: `Statistiques`,
			to: { name: 'OrderDashboardStats' },
		},
		{
			label: `Paramètre agences`,
			to: { name: 'OrderDashboardAgencyParams' },
		},
	],
	'OrderDetail': [
		{
			label: `Suivi des candidats`,
			to: { name: 'OrderDetailsPipeline' },
		},
		{
			label: `Vie de la commande`,
			to: { name: 'OrderDetailsStatus' },
		},
		{
			label: `Détail de la commande`,
			to: { name: 'OrderDetailsRecap' },
		},
		{
			label: `Détail des horaires`,
			callback: () => { useOrderService().openScheduleRecapModalAsync(); },
		},
		{
			label: `Historique`,
			to: { name: 'OrderDetailsHistory' },
		},
		{
			label: `Contrats liés`,
			to: { name: 'OrderDetailsContracts' },
		},
	],
};
