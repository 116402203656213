const OnDiscussionTyping = 'OnDiscussionTypingAsync';

const OnDiscussionJoin = 'OnDiscussionJoinAsync';

const OnDiscussionLeave = 'OnDiscussionLeaveAsync';

const OnUserPresenceJoin = 'OnUserPresenceJoinAsync';

const OnUserPresenceLeave = 'OnUserPresenceLeaveAsync';

export default {
	OnDiscussionTyping,
	OnDiscussionJoin,
	OnDiscussionLeave,
	OnUserPresenceJoin,
	OnUserPresenceLeave,
};
