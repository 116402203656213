import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/orderuser');

class OrderUserApiClient {
	static listValidated (orderId: number, pagedListParameters: IOrderUserClientPagedListParameters) {
		return client.post<IPagedList<IOrderUserWithTempWorkerForClientModel>>(`order/${orderId}`, pagedListParameters);
	}

	static getDetails (orderId: number, tempWorkerUserId: number) {
		return client.get<IOrderUserWithTempWorkerDetailsForClientModel>(`order/${orderId}/${tempWorkerUserId}`);
	}
}

export default OrderUserApiClient;
