<template>
	<o-section
		class="aside-order-edition"
		:title="'Créer une nouvelle commande'">
		<template
			v-if="setup.ui.onLayoutNavBottom"
			#actions>
			<div id="aside-order-edition_reset"/>
		</template>

		<div
			id="order-timeline"
			class="order-timeline">
			<o-timeline
				:ref="setup._timeline"
				v-model="orderCopy.asideActivePane"
				horizontal
				scrollable>
				<o-timeline-pane
					:name="1"
					font-icon="icon-interface-home-2">
					<order-creation-step1
						v-bind="{ order: setup.props.orderCopy }"
						@current="setup._timeline.value?.setCurrent($event)"/>
				</o-timeline-pane>

				<o-timeline-pane
					:name="2"
					lazy
					font-icon="icon-interface-user-multiple">
					<order-creation-step2
						v-bind="{ order: setup.props.orderCopy }"
						@current="setup._timeline.value?.setCurrent($event)"/>
				</o-timeline-pane>

				<o-timeline-pane
					:name="3"
					font-icon="icon-interface-calendar-mark">
					<order-creation-step3
						v-bind="{ order: setup.props.orderCopy }"
						@current="setup._timeline.value?.setCurrent($event)"/>
				</o-timeline-pane>

				<o-timeline-pane
					:name="4"
					font-icon="icon-travel-map-location-pin">
					<order-creation-step4
						v-bind="{ order: setup.props.orderCopy }"
						@current="setup._timeline.value?.setCurrent($event)"/>
				</o-timeline-pane>

				<o-timeline-pane
					:name="5"
					font-icon="icon-interface-file-clipboard-text">
					<order-creation-step5
						v-bind="{ order: setup.props.orderCopy }"
						@current="setup._timeline.value?.setCurrent($event)"/>
				</o-timeline-pane>

				<o-timeline-pane
					:name="6"
					font-icon="icon-interface-validation-check">
					<order-creation-recap-card
						title="Récapitulatif de votre commande"
						v-bind="{ order: setup.props.orderCopy, refreshing: true }"
						creating
						@current="setup._timeline.value?.setCurrent($event)"/>
				</o-timeline-pane>
			</o-timeline>
		</div>

		<teleport
			v-if="(!setup.ui.onLayoutNavBottom && _aside) || (setup.ui.onLayoutNavBottom && setup.sectionActions)"
			:order="1"
			:to="!setup.ui.onLayoutNavBottom ? `#OrionAside-${_aside?.uid}__actions` : `#aside-order-edition_reset`">
			<o-button
				v-tooltip="`Réinitialiser`"
				style="background:white"
				outline
				color="warning"
				prefix-font-icon="icon-interface-arrows-round-left"
				@click="setup.reset()"/>
		</teleport>
	</o-section>
</template>

<script setup lang="ts">
import OrderCreationAsideSetupService from '@/setup/order/creation/OrderCreationAsideSetupService';
import OrderCreationStep1 from './OrderCreationStep1.vue';
import OrderCreationStep2 from './OrderCreationStep2.vue';
import OrderCreationStep3 from './OrderCreationStep3.vue';
import OrderCreationStep4 from './OrderCreationStep4.vue';
import OrderCreationStep5 from './OrderCreationStep5.vue';
import OrderCreationRecapCard from './OrderCreationRecapCard.vue';
import { inject } from 'vue';
const props = defineProps(OrderCreationAsideSetupService.props);
const _aside = inject<OrionAside>('_aside');
const setup = new OrderCreationAsideSetupService(props, _aside);
</script>
