<template>
	<div
		v-if="setup.dataLoaded"
		class="order-creation-schedule-recap">
		<div
			v-if="_modal && !setup.self.isClient()"
			class="flex jc-c"
			center>
			Pour modifier les horaires, rendez-vous sur le
			<strong
				class="order-creation-schedule-recap__link"
				@click="setup.goToOrderDetails()">
				&nbsp; détail de la commande
			</strong>
		</div>

		<div class="order-creation-schedule-recap__schedule">
			<o-label color="info">
				Par défaut
			</o-label>
			<o-section
				:title="order ? `Sélection des horaires standards` : `Horaires standards`"
				:subtitle="order ? `Renseignez les plages horaires des jours travaillés pour la commande` : undefined">
				<order-creation-schedule
					v-if="setup.addDefaultSchedule || (!order && setup.order?.scheduleDefaultsEntities?.length)"
					:readonly="!order"
					:order="setup.order"/>


				<o-alert
					v-else-if="_modal"
					color="info"
					center>
					Horaires standards non renseignés
				</o-alert>
				<template #actions>
					<o-button
						v-if="!setup.showDatepicker && order"
						:suffix-font-icon="!setup.addDefaultSchedule ? 'icon-interface-add-circle' : 'icon-end-point-none'"
						nude
						color="info"
						@click="setup.addDefaultSchedule = !setup.addDefaultSchedule">
						{{ !setup.addDefaultSchedule ? 'Ajouter des horaires par défaut' : 'Retirer les horaires par défaut' }}
					</o-button>
				</template>
			</o-section>
		</div>

		<div class="order-creation-schedule-recap__schedule">
			<o-label color="warning">
				Spécifique
			</o-label>
			<o-section
				:title="order ? `Ajout d'horaires spécifiques`: `Horaires spécifiques`"
				:subtitle="order ? `Renseignez les horaires spécifiques à des jours particuliers / périodes particulières`: undefined">
				<template #actions>
					<o-button
						v-if="order"
						suffix-font-icon="icon-interface-add-circle"
						nude
						color="info"
						@click="setup.togglePeriodSelection()">
						Ajouter une période spécifique
					</o-button>
					<o-datepicker
						v-if="setup.showDatepicker"
						:ref="setup._periodRange"
						v-model:range="setup.daterange"
						:disabled="!order"
						class="order-creation-schedule-recap__schedule-datepicker"
						:min-date="setup.order.start"
						:max-date="setup.order.end"
						size="lg"
						label="Saissez votre période"
						type="range"
						@blur="setup.showDatepicker = false"/>
				</template>

				<order-creation-schedule
					v-for="range in setup.order.scheduleOrderRangesEntities"
					:key="range.id"
					:order="setup.order"
					:schedule-range="range"
					:readonly="!order"
					specific/>
				<o-alert
					v-if="_modal && !setup.order.scheduleDefaultsEntities.length"
					color="warning"
					center>
					Horaires spécifiques non renseignés
				</o-alert>
			</o-section>
		</div>
	</div>
	<o-loader
		:visible="!setup.dataLoaded"
		message="Chargement des horaires"/>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import OrderCreationSchedule from './OrderCreationSchedule.vue';
import OrderCreationScheduleRecapSetupService from '@/setup/order/creation/OrderCreationScheduleRecapSetupService';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(OrderCreationScheduleRecapSetupService.props);
const setup = new OrderCreationScheduleRecapSetupService(props, _modal);
</script>

<style scoped lang="less">
.order-creation-schedule-recap {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  &__link {
    cursor: pointer;
  }
  margin-bottom: 3rem;
}
</style>
