import ApiClient from '@/api/ApiClient';

const client = new ApiClient('gear');

class GearApi {
	static list () {
		return client.get<IGearModel[]>('');
	}
}

export default GearApi;
