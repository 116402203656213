import ApiClient from '@/api/ApiClient';
import { TweakedOrderHistoryAgencyListItemModel } from '@/setup/order/OrderHistoryPagedListViewSetupService';

const client = new ApiClient('agency/order');

class OrderApiAgency {
	static add (addOrderAgencyModel: IAddOrderForAgencyModel) {
		return client.post<IOrderAgencyOverPageModel>('', addOrderAgencyModel);
	}

	static step (idOrder: number, showRejectedUsers: IOrderStepListParameters) {
		return client.get<IOrderStepModel[]>(`${idOrder}/step`, { params: showRejectedUsers });
	}

	static grid (orderAgencyPagedListParameters: IOrderAgencyPagedListParameters) {
		return client.post<IPagedList<IOrderAgencyListItemModel>>('grid', orderAgencyPagedListParameters);
	}

	static getDetailed (id: number) {
		return client.get<IOrderAgencyOverPageModel>(id.toString());
	}

	static canceled (id: number) {
		return client.put<void>(`${id}/canceled`);
	}

	static update (id: number, updateOrderAgencyModel: IUpdateOrderAgencyModel) {
		return client.put(id.toString(), {
			...updateOrderAgencyModel,
			missionPlaceId: updateOrderAgencyModel.address
				? undefined
				: updateOrderAgencyModel.missionPlaceId,
		});
	}

	static updateProfessionalSkill (id: number, addOrderProfessionalSkillModel: IUpdateOrderProfessionalSkillModel) {
		return client.put<void>(`${id}/professionalSkill`, addOrderProfessionalSkillModel);
	}

	static updateSecondaryProfessionalSkill (id: number, updateOrderSecondaryProfessionalSkillModel: IUpdateOrderSecondaryProfessionalSkillModel) {
		return client.put(`${id}/secondaryProfessionalSkill`, updateOrderSecondaryProfessionalSkillModel);
	}

	static addLanguage (id: number, addOrderLanguageModel: IAddOrderLanguageModel) {
		return client.post<void>(`${id}/language`, addOrderLanguageModel);
	}

	static deleteLanguage (id: number, languageId: number) {
		return client.delete<void>(`${id}/language/${languageId}`);
	}

	static addGear (id: number, addOrderGearModel: IAddOrderGearModel) {
		return client.post<void>(`${id}/gear`, addOrderGearModel);
	}

	static deleteGear (id: number, gearId: number) {
		return client.delete<void>(`${id}/gear/${gearId}`);
	}

	static addJustificatory (id: number, addOrderJustificatoryModel: IAddOrderJustificatoryModel) {
		return client.post<void>(`${id}/justificatory`, addOrderJustificatoryModel);
	}

	static deleteJustificatory (id: number, justificatoryId: number) {
		return client.delete<void>(`${id}/justificatory/${justificatoryId}`);
	}

	static historyGrid (id: number, historyGridParameters: IOrderHistoryAgencyPagedListParameters) {
		return client.post<IPagedList<TweakedOrderHistoryAgencyListItemModel>>(`${id}/history/grid`, historyGridParameters);
	}

	static updateWarnClient (id: number) {
		return client.put<void>(`${id}/warnclient`);
	}

	static validate (id: number) {
		return client.put<void>(`${id}/validate`);
	}

	static averageCompletion () {
		return client.get<IOrderAverageCompletionModel>(`stats/averagecompletion`);
	}

	static averageTimeCompletion () {
		return client.get<IOrderAverageTimeCompletionModel>(`stats/averagetimecompletion`);
	}

	static dashboardGrid (orderDashboardPagedListParameters: IOrderDashboardPagedListParameters) {
		return client.post<IPagedList<IOrderDashboardItemModel>>(`dashboard/grid`, orderDashboardPagedListParameters);
	}

	static listMostActiveClient () {
		return client.get<IOrderClientMostActiveModel[]>(`stats/client/active`);
	}

	static statisticByProfessionalSkill (from: Date, to: Date) {
		return client.get<IOrderStatisticByProfessionalSkillModel[]>(`stats/byprofessionalskill`, {
			params: {
				from: from.toPost(),
				to: to.toPost(),
			},
		});
	}

	static dashboardGridCount () {
		return client.get<IOrderDashboardCountModel>(`dashboard/grid/count`);
	}

	static overviewCount () {
		return client.get<IOrderOverviewCountModel>(`overview/count`);
	}

	static gridCount () {
		return client.get<IOrderAgencyGridCountModel>(`grid/count`);
	}

	static updateNeedPersonalVehicle (id: number, needPersonalVehicle: IUpdateOrderNeedPersonalVehicleModel) {
		return client.put<void>(`${id}/needPersonalVehicle`, { needPersonalVehicle });
	}

	static updateNote (id: number, note: IUpdateOrderAgencyNoteModel) {
		return client.put(`${id}/note`, note);
	}

}

export default OrderApiAgency;
