<template>
	<div class="order-step">
		<o-section id="order-step-3">
			<h4 class="order-step__title">
				<span class="text--brand">
					Étape 3
				</span> - Quand en avez-vous besoin ?
			</h4>

			<div class="row row--grid order-step-3__dates">
				<o-datepicker
					:ref="setup._startDate"
					v-model="order.start"
					required
					suffix-font-icon="icon-interface-calendar-mark"
					:min-date="new Date()"
					time
					:validation="setup.validator.rule('start')"
					:class="!setup.ui.onLayoutNavBottom ?? 'col-xs-6'"
					label="Modifier la date de début"/>
				<o-datepicker
					:ref="setup._endDate"
					v-model="order.end"
					required
					suffix-font-icon="icon-interface-calendar-mark"
					:validation="setup.validator.rule('end')"
					:class="!setup.ui.onLayoutNavBottom ?? 'col-xs-6'"
					time
					:min-date="order.start"
					label="Modifier la date de fin"/>
			</div>
		</o-section>

		<order-creation-schedule-recap
			v-if="order"
			:order/>

		<o-section
			v-if="order.asideActivePane !== 6"
			align="center">
			<o-button
				prefix-font-icon="icon-interface-arrows-button-left"
				outline
				@click="$emit('current', 2)">
				Précédent
			</o-button>
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				outline
				@click="setup.next()">
				Suivant
			</o-button>
		</o-section>
		<o-section
			v-else
			align="center">
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				color="success"
				@click="$emit('current', 6)">
				Valider
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import OrderCreationStep3SetupService from '@/setup/order/creation/OrderCreationStep3SetupService';
import OrderCreationScheduleRecap from './OrderCreationScheduleRecap.vue';
type Emits = { (e: 'current' | 'origin', val: number): void }
const emits = defineEmits<Emits>();
const props = defineProps(OrderCreationStep3SetupService.props);
const setup = new OrderCreationStep3SetupService(props, emits);
</script>

<style scoped lang="less">
@import '@/styles/variables';
.order-step-3 {

	&__dates {
    > div {
      flex: 1;
			margin-bottom: 3rem;
    }

		@media @phone {
      flex-direction: column;
		}
	}
}
</style>
