<template>
	<metainfo>
		<template #description="{ content }">
			{{ content }}
		</template>
	</metainfo>
	<router-view @vue:mounted="routerViewMounted"/>
	<!-- <div class="debug">
		<div>
			reponsive :
			<span class="debug__layoutFull">@layoutFull</span>
			<span class="debug__layoutOneAside">@layoutOneAside</span>
			<span class="debug__layoutNoAside">@layoutNoAside</span>
			<span class="debug__layoutNavFull">@layoutNavFull</span>
			<span class="debug__layoutNavCollapsed">@layoutNavCollapsed</span>
			<span class="debug__layoutNavBottom">@layoutNavBottom</span>
			<span class="debug__phone">@phone</span>
		</div>
	</div> -->
</template>

<script setup lang="ts">
const SessionManager = () => import('@/utils/SessionManager');
function routerViewMounted () {
	SessionManager();
}
</script>

<style lang="less">
@import (css) url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700");
@import '@/styles/styles.less';
@import '@/styles/variables';

#app {
  height: 100vh;
  height: 100dvh;
  display: flex;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

#freshworks-frame-wrapper {
  left: calc(var(--arm-aside-width) + 2rem);
  bottom: 20px !important;
  right: unset !important;

  @media @layoutNavCollapsed {
    left: calc(var(--arm-nav-collpased-width) + 1rem);
  }

  @media @layoutNavBottom {
    left: 1rem;
  }
}

@keyframes slideupright {
  from {
    transform: scale(0);
    transform-origin: top left;
  }

  to {
    transform: scale(1);
    transform-origin: top left;
  }
}
</style>
