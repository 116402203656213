import AppSignalR from '@/signalr/App.signalr';
import signalrClientMethod from './names/Signalr.clientMethod';

class SignalRMessage {

	static onAdded (listener: (arg?: any) => void) {
		return AppSignalR.on(signalrClientMethod.MessageAdded, listener);
	}

	static offAdded (listener: (arg?: any) => void) {
		return AppSignalR.off(signalrClientMethod.MessageAdded, listener);
	}


	static onUpdated (listener: (arg?: any) => void) {
		return AppSignalR.on(signalrClientMethod.MessageUpdated, listener);
	}

	static offUpdated (listener: (arg?: any) => void) {
		return AppSignalR.off(signalrClientMethod.MessageUpdated, listener);
	}
}

export default SignalRMessage;
