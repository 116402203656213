import BaseService from './BaseService';
import OrderApiAgency from '@/api/OrderApiAgency';
import { Bus, getHoursInterval, itemIs, useAside, useConfirm, useModal, useNotif, usePrompt } from '@orion.ui/orion';
import { ORDER_STATUS } from '@/typings/enums/ORDER_STATUS';
import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import OrderApiClient from '@/api/OrderApiClient';
import OrderUserApiAgency from '@/api/OrderUserApiAgency';
import OrderUserApiClient from '@/api/OrderUserApiClient';
import OrderModifyModal from '@/components/order/OrderModifyModal.vue';
import OrderCreationAside from '@/components/order/creation/OrderCreationAside.vue';
import OrderDetailsSpecificScheduleModal from '@/components/order/details/OrderDetailsSpecificScheduleModal.vue';
import type OrderEntity from '@/entity/order/OrderEntity';
import JustificatoryApi from '@/api/JustificatoryApi';
import ProfessionalSkillApi from '@/api/ProfessionalSkillApi';
import { isSpecific } from '@/utils/tools';
import LanguageApi from '@/api/LanguageApi';
import GearApi from '@/api/GearApi';
import ScheduleDefaultApiAgency from '@/api/ScheduleDefaultApiAgency';
import OrderInterlocutorApiAgency from '@/api/OrderInterlocutorApiAgency';
import { ref } from 'vue';
import OrderCancelRequestApi from '@/api/OrderCancelRequestApi';
import useBusService from './BusService';
import router from '@/router/index.router';
import type OrderTempWorkerEntity from '@/entity/order/OrderTempWorkerEntity';
import OrderCreationScheduleRecap from '@/components/order/creation/OrderCreationScheduleRecap.vue';

type Order = IOrderTempWorkerOverPageModel | IOrderAgencyOverPageModel | IOrderClientOverPageModel

class OrderService extends BaseService {
	getRichStatus (status: ORDER_STATUS) {
		switch (status) {
		case ORDER_STATUS.Created:
			return {
				color: 'info' as Orion.Color,
				outline: true,
				label: `En cours`,
				status,
			};
		case ORDER_STATUS.Filled:
			return {
				color: 'success' as Orion.Color,
				outline: true,
				label: `Pourvue`,
				status,
			};
		case ORDER_STATUS.Canceled:
			return {
				color: 'danger' as Orion.Color,
				outline: true,
				label: `Annulée`,
				status,
			};
		case ORDER_STATUS.Validated:
			return {
				color: 'success' as Orion.Color,
				outline: true,
				label: `Validée`,
				status,
			};
		case ORDER_STATUS.CancellationRequest:
			return {
				color: 'warning' as Orion.Color,
				outline: true,
				label: `Demande d'annulation`,
				status,
			};
		default:
			return undefined;
		}
	}

	isOrderProfessionalSkill (order: OrderEntity, skillId: number) {
		return skillId === order.professionalSkill?.id;
	}

	canCancelOrder (order: IOrderAgencyListItemModel | IOrderAgencyOverPageModel) {
		return this.self.isAgency()
			&& order.status !== ORDER_STATUS.Canceled
			&& order.status !== ORDER_STATUS.Validated;
	}

	getOrderHoursInterval (start: string, end: string) {
		return getHoursInterval(new Date(start), new Date(end));
	}

	getOrderDiscussionContext (order: Order, candidate?: Armado.OrderDiscussionContextCandidate) {
		const context: Armado.DiscussionContext = {
			id: 0,
			documentId: order.id,
			documentType: DOCUMENT_TYPE.Order,
			title: this.self.isTempWorker() || candidate ? `Offre de mission n°${order.id}` : `Commande n°${order.id}`,
			subtitle: `Mission du ${new Date(order.start).toReadable()} au ${new Date(order.end).toReadable()}`,
		};

		if (this.self.isTempWorker() && itemIs<IOrderTempWorkerOverPageModel>(order, 'agencyCompany')) {
			Object.assign(context, {
				agencyCompanyId: order.agencyCompany?.id,
				agencyCompany: {
					id: order.agencyCompany?.id,
					name: order.agencyCompany?.name,
				},
				tempWorkerUser: {
					id: this.self.id,
					name: this.self.name,
					avatarId: this.self.avatarId,
				},
				// title: `Offre de mission n°${order.id}`,
			});
		} else if (itemIs<IOrderAgencyOverPageModel | IOrderClientOverPageModel>(order, 'agencyCompanyId')) {
			Object.assign(context, {
				agencyCompanyId: order.agencyCompanyId,
				agencyCompany: {
					id: order.agencyCompanyId,
					name: order.agencyCompanyName,
				},
			});

			if (candidate) {
				Object.assign(context, {
					tempWorkerUserId: candidate.userId,
					tempWorkerUser: {
						id: candidate.userId,
						name: candidate.userName,
						avatarId: candidate.userAvatarId,
					},
					// title: `Offre de mission n°${order.id}`,
				});
			} else {
				Object.assign(context, {
					clientCompanyId: order.clientCompanyId,
					clientCompany: {
						id: order.clientCompanyId,
						name: order.clientCompanyName,
					},
					// title: `Commande n°${order.id}`,
				});
			}
		}

		return context;
	}

	async openAsideCopyOrderAsync (order?: OrderEntity) {
		if (!this.self.can('OrderCreate')) return;
		await order?.filterSelfTagsAsync();

		const clone = order?.clone();
		clone?.afterCloneHook();

		useAside({
			Nested: OrderCreationAside,
			size: 'lg',
			NestedProps: { orderCopy: clone },
			events:
			{
				save: (Aside, order) => {
					useNotif.success('La commande a été créée avec succès !');
					Bus.emit('OrderPagedListViewSetupService:refresh-list', order);
				},
			},
		});

	}

	async validateOrderAsync (id: number) {
		if (await useConfirm(`Confirmez-vous la validation de la commande ?`)) {
			await OrderApiAgency.validate(id);
			useNotif.success('La commande a bien été validée');
			return true;
		} else return false;
	}

	async cancelOrderAsync (id: number) {
		if (this.self.isClient()) {
			const message = ref('');
			const { confirm, value } = await usePrompt({
				size: 'md',
				title: `Confirmez-vous l'annulation de la commande ?
				Elle n'apparaitra plus dans la liste de vos commandes en cours.`,
				message: `<span class="orion-alert orion-alert--info">Ce commentaire sera visible par l'agence.</span>`,
				prompt: {
					type: 'textarea',
					value: message.value,
					fieldProps: {
						maxLength: 500,
						required: true,
					},
				},
			});

			if (confirm) {
				await OrderCancelRequestApi.add({
					orderId: id,
					reason: value as string,
					creatorId: this.self.userId,
				});

				useBusService().emit(`${id}:status-update`, ORDER_STATUS.CancellationRequest);
				useBusService().emit(`${id}:details-status-update`, {
					status: ORDER_STATUS.CancellationRequest,
					reason: value,
				});
				Bus.emit('OrderPagedListViewSetupService:refresh-list');
				useNotif.success(`La demande d'annulation a bien été envoyée`);
			}
		} else {
			if (await useConfirm(`Confirmez-vous l'annulation de la commande ?
			Elle n'apparaitra plus dans la liste de vos commandes en cours.`)) {
				await OrderApiAgency.canceled(id);
				useBusService().emit(`${id}:status-update`, ORDER_STATUS.Canceled);
				useBusService().emit(`${id}:details-status-update`, { status: ORDER_STATUS.Canceled });
				Bus.emit('OrderPagedListViewSetupService:refresh-list');
				useNotif.success('La commande a bien été annulée');
				router.push({ name: 'Order' });
			}
		}

	}

	async deleteCancelOrderRequestAsync (id: number) {
		if (await useConfirm(
			`Souhaitez-vous vraiment retirer votre demande d'annulation pour cette commande ?`,
			{ title: 'Annulation' },
		)) {
			await OrderCancelRequestApi.delete(id);

			useBusService().emit(`${id}:status-update`, ORDER_STATUS.Created);
			useBusService().emit(`${id}:details-status-update`, { status: ORDER_STATUS.Created });
			Bus.emit('OrderPagedListViewSetupService:refresh-list');
			useNotif.success('Demande annulée');
		}
	}

	async getCountAsync () {
		const api = this.self.isAgency() ? OrderApiAgency : OrderApiClient;
		const { data } = await api.gridCount();

		return {
			inProgress: data.inProgress,
			filled: data.filled,
			validated: data.validated,
			canceled: data.canceled,
			cancellationRequest: data.cancellationRequest,
		};
	}

	async addOrderAsync (order: IAddOrderForAgencyModel | IAddOrderModel) {
		const { data: orderCreated } = this.self.isAgency()
			? await OrderApiAgency.add(order as IAddOrderForAgencyModel)
			: await OrderApiClient.add(order);
		return orderCreated;
	}

	// ORDER PIPELINE FUNCTIONS
	async removeCandidateFromOrderAsync (orderId: number, userIds: number[]) {
		await OrderUserApiAgency.deleteMultiple({
			userIds: userIds,
			orderId,
		});
	}

	async getValidatedCandidateListAsync (orderId: number, page: IOrderUserClientPagedListParameters) {
		const { data } = await OrderUserApiClient.listValidated(orderId, page);
		return data;
	}

	async moveCandidateAsync (...args: Parameters<typeof OrderUserApiAgency.move>) {
		if (!this.self.isAgency()) return;
		const { data } = await OrderUserApiAgency.move(...args);
		return data;
	}

	// ORDER UPDATE FUNCTIONS
	async updateAsync (...args: Parameters<typeof OrderApiAgency.update>) {
		if (!this.self.isAgency()) return;
		const { data } = await OrderApiAgency.update(...args);
		return data;
	}

	async updateNeedPersonalVehicleAsync (orderId: number, needVehicle: IUpdateOrderNeedPersonalVehicleModel) {
		await OrderApiAgency.updateNeedPersonalVehicle(orderId, needVehicle);
		useNotif.success('Modification enregistrée');
	}

	async updateProfessionalSkillAsync (orderId: number, payload: IUpdateOrderProfessionalSkillModel) {
		await OrderApiAgency.updateProfessionalSkill(orderId, payload);
	}

	async updateSecondaryProfessionalSkillAsync (orderId: number, payload: IUpdateOrderSecondaryProfessionalSkillModel) {
		await OrderApiAgency.updateSecondaryProfessionalSkill(orderId, payload);
	}

	async addLanguageAsync (orderId: number, payload: IAddOrderLanguageModel) {
		await OrderApiAgency.addLanguage(orderId, payload);
	}

	async addDocumentAsync (orderId:number, payload: IAddOrderJustificatoryModel) {
		await OrderApiAgency.addJustificatory(orderId, payload);
	}

	async removeDocumentAsync (orderId: number, id: number) {
		await OrderApiAgency.deleteJustificatory(orderId, id);
	}

	async addGearAsync (orderId: number, payload: IAddOrderGearModel) {
		await OrderApiAgency.addGear(orderId, payload);
	}

	async updateWarnClientAsync (orderId: number) {
		await OrderApiAgency.updateWarnClient(orderId);
	}

	async removeGearAsync (orderId: number, id: number) {
		await OrderApiAgency.deleteGear(orderId, id);
	}

	async updateInterlocutorAsync (orderId: number, payload: IUpdateOrderInterlocutorModel) {
		await OrderInterlocutorApiAgency.update(orderId, payload);
	}

	async deleteInterlocutorAsync (orderId: number) {
		await OrderInterlocutorApiAgency.delete(orderId);
	}

	async updateScheduleDefaultAsync (orderId: number, payload: IAddScheduleDefaultModel[]) {
		await ScheduleDefaultApiAgency.update({
			orderId,
			scheduleDefaultModels: payload,
		});
	}

	openModifyModal (order: OrderEntity, type: string, size = 'md') {
		useModal({
			Nested: OrderModifyModal,
			title: 'Modification de la commande',
			NestedProps: {
				tags: type,
				order: order.clone(),
			},
			size,
			events:
				{
					'updateOrder': async (Modal, payload) => {
						order.applyClone();
						await order.updateAsync(payload);
						useNotif.success(`Modification enregistrée`);
						Modal.close();
					},
					'updateProfessionalSkill': async (Modal, payload) => {
						await order.updateProfessionalSkillAsync({ professionalSkillId: payload.id });
						order.applyClone();
						Modal.close();
						useNotif.success('Qualification modifiée avec succès');
					},
					'updateSecondaryProfessionalSkill': async (Modal, payload: IProfessionalSkillModel[]) => {
						order.applyClone();
						const newProfessionalSkillsList = payload ? payload.map(x => x.id) : [];
						await order.updateSecondaryProfessionalSkillAsync({ secondaryProfessionalSkillIds: newProfessionalSkillsList });
						Modal.close();
						useNotif.success('Qualification secondaire modifiée avec succès');
					},
					'updateLanguages': async (Modal, payload: ILanguageModel[]) => {
						order.applyClone();
						const newLanguagesIdList = payload ? payload.map(x => x.id) : [];
						await order.addLanguageAsync(newLanguagesIdList);
						Modal.close();
						useNotif.success('Langues modifiées avec succès');
					},
					'updateInterlocutor': async (Modal, payload: IOrderInterlocutorModel) => {
						order.applyClone();
						await order.updateInterlocutorAsync(payload);
						Modal.close();
						useNotif.success('Interlocuteur modifié avec succès');
					},
					'deleteInterlocutor': async (Modal) => {
						await order.deleteInterlocutorAsync();
						Modal.close();
						order.orderInterlocutor = undefined;
						useNotif.success('Interlocuteur supprimé');
					},
					'updateScheduleDefault': async (Modal, payload: IScheduleDefaultModel[]) => {
						order.applyClone();
						order.scheduleDefaults = payload;
						await order.updateScheduleDefaultAsync(payload);
						useNotif.success('Horaires par défaut modifiés avec succès');
						Modal.close();
					},
				},
		});
	}

	async openNoteModalAsync (order: OrderEntity) {
		const clone = order.clone();
		const { confirm, value } = await usePrompt({
			title: 'Annotations',
			prompt: {
				type: 'textarea',
				value: clone.agencyNote,
			},
		});

		if (confirm && value !== order.agencyNote) {
			await OrderApiAgency.updateNote(order.id, { note: value as string });
			order.agencyNote = value as string;
		}
	}

	async openScheduleModalAsync (order?: OrderEntity | OrderTempWorkerEntity) {
		useModal({
			Nested: OrderDetailsSpecificScheduleModal,
			NestedProps: { order },
			title: 'Horaires spécifiques de la mission',
			size: 'lg',
			events: {
				'updated': async (modal, payload: IScheduleOrderRangeModel[]) => {
					if (!order || order.isTempWorkerOrder()) return;
					order.applyClone();
					order.scheduleOrderRanges = payload;
					await order.updateScheduleOrderRangeAsync(payload);
					useNotif.success('Modification des horaires spécifiques enregistrée.');
					modal.close();
				},
			},
		});
	}


	async openScheduleRecapModalAsync (order?: OrderEntity | OrderTempWorkerEntity) {
		useModal({
			Nested: OrderCreationScheduleRecap,
			NestedProps: { order },
			size: 'lg',
		});
	}

	// ORDER GET APIS
	async getJustificatoriesAsync () {
		const { data } = await JustificatoryApi.list();
		return data.filter(x => !isSpecific(x.tempWorkerDocumentType));
	}

	async getQualificationsAsync () {
		const { data } = await ProfessionalSkillApi.list();
		return data;
	}

	async getLanguagesAsync () {
		const { data } = await LanguageApi.list();
		return data;
	}

	async getGearsAsync () {
		const { data } = await GearApi.list();
		return data;
	}

	async getStepAsync (...args: Parameters<typeof OrderApiAgency.step>) {
		if (this.self.isAgency()) {
			const { data } = await OrderApiAgency.step(...args);
			return data;
		}
		return [];
	}

	async getDetailedAsync (id: number): Promise<IOrderAgencyOverPageModel | IOrderClientOverPageModel | undefined> {
		if (this.self.isAgency()) {
			const { data } = await OrderApiAgency.getDetailed(id);
			return data;
		} else if (this.self.isClient()) {
			const { data } = await OrderApiClient.getDetailed(id);
			return data;
		}
	}

	async overviewCountAsync (...args: Parameters<typeof OrderApiAgency.overviewCount>) {
		if (this.self.isAgency()) {
			const { data } = await OrderApiAgency.overviewCount(...args);
			return data;
		};
	}

	async averageCompletionAsync (...args: Parameters<typeof OrderApiAgency.averageCompletion>) {
		if (this.self.isAgency()) {
			const { data } = await OrderApiAgency.averageCompletion(...args);
			return data;
		};
	}

	async averageTimeCompletionAsync (...args: Parameters<typeof OrderApiAgency.averageTimeCompletion>) {
		if (this.self.isAgency()) {
			const { data } = await OrderApiAgency.averageTimeCompletion(...args);
			return data;
		};
	}

	async listCandidateStatusAsync (...args: Parameters<typeof OrderUserApiAgency.listCandidateStatus>) {
		if (this.self.isAgency()) {
			const { data } = await OrderUserApiAgency.listCandidateStatus(...args);
			return data;
		};
	}
}

const orderServiceSingleton = new OrderService();

export default function useOrderService () {
	return orderServiceSingleton;
}
