import { reactive } from 'vue';
import useTagUserService from '@/services/tag/TagUserService';
import useTagLinkService from '@/services/tag/TagLinkService';
import BaseEntity from './BaseEntity';
import useTagService from '@/services/tag/TagService';
import { TAG_LINK_TYPE } from '@/typings/enums/TAG_LINK_TYPE';
import { Bus } from '@orion.ui/orion';

export default class TagDetailEntity extends BaseEntity<ITagDetailModel> implements ResourceEntity {
	private readonly privateState = reactive({
		users: undefined as Undef<ITagUserForTagDetailModel[]>,
		links: undefined as Undef<ICountByTagLinkTypeModel[]>,
	});

	get isCloneDiff () {
		return !!this._clone &&
    (this.name !== this._clone.name
      || this.color?.id !== this._clone.color?.id
      || this.description !== this._clone.description);
	}

	get usersForRolesAndTags (): Armado.UserForRolesAndTags[] {
		return this.users.map(u => ({
			id: u.user?.id,
			avatarId: u.user?.avatarId,
			authLogin: u.user?.authLogin,
			firstName: u.user?.firstName,
			lastName: u.user?.lastName,
			hasAccessToArmado: u.user?.suiteUserRelationshipHasAccessToArmado,
			isEnabledForSuite: u.user?.suiteUserRelationshipIsEnabledForSuite,
		}));
	}

	get colorId () { return this.state.color?.id ?? 0; }
	get users () { return this.privateState.users ?? []; }
	get process () { return this.privateState.links?.filter(x => +x.type === TAG_LINK_TYPE.TimeTicket || +x.type === TAG_LINK_TYPE.Order) ?? [];}
	get processCount () { return this.process?.reduce((prev, curr) => prev + curr.count, 0) ?? 0; };
	get documents () { return this.privateState.links?.filter(x => +x.type !== TAG_LINK_TYPE.TimeTicket && +x.type !== TAG_LINK_TYPE.Order); }
	get documentsCount () { return this.documents?.reduce((prev, curr) => prev + curr.count, 0) ?? 0; }

	get name () { return this.state.name; }
	set name (val) { this.state.name = val; }

	get description () { return this.state.description; }
	set description (val) { this.state.description = val; }

	get color () { return this.state.color; }
	set color (val) { this.state.color = val; }

	get shareToAgency () { return this.state.shareToAgency; }
	set shareToAgency (val) {
		this.state.shareToAgency = val;
		if (this.id) this.updateAsync();
	}


	async updateAsync (payload?: IUpdateTagModel) {
		return await useTagService().updateAsync(this.id, {
			name: payload?.name ?? this.name,
			description: payload?.description ?? this.description,
			colorId: payload?.colorId ?? this.colorId,
			shareToAgency: payload?.shareToAgency ?? this.shareToAgency,
		});
	}

	async deleteAsync (cb?: Function) {
		await useTagService().deleteAsync(this.id);
		cb?.();
	}

	async userListAsync (showHiddenUsers = false) {
		this.privateState.users = await useTagUserService().userListAsync(this.id, showHiddenUsers);
		return this.privateState.users;
	}

	async linkListAsync () {
		this.privateState.links = await useTagLinkService().linkListAsync(this.id);
		return this.privateState.links;
	}

	async unlinkUserAsync (ids: number[]) {
		await useTagUserService().deleteAsync(ids);
		Bus.emit('TagDetailsSetupService:tag:refresh');
	}

	async promptUsersToLinkAsync (showHiddenUsers = false) {
		await useTagUserService().promptUsersToAddTagAsync(this.id, showHiddenUsers);
		Bus.emit('TagDetailsSetupService:tag:refresh');
	}
}
