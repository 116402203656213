import { useAside, useNotif } from '@orion.ui/orion';
import type OrganizationRoleDetailEntity from '@/entity/OrganizationRoleDetailEntity';
import OrganizationRoleApi from '@/api/OrganizationRoleApi';
import RoleAsideNew from '@/components/role/RoleAsideNew.vue';
import AjaxCooler from '@/utils/AjaxCooler';
import useSelfService from './SelfService';

class OrganizationRoleService implements ResourceService {
	private get api () { return OrganizationRoleApi; }
	private get self () { return useSelfService().self; }

	private cooler = new AjaxCooler(this.api.list);


	async storeAsync (payload: Omit<IAddOrganizationRoleModel, 'organizationId'>, organizationId = this.self.organization?.id) {
		return new Promise<IOrganizationRoleDetailModel>(async (resolve) => {
			if (organizationId) {
				if (payload.rolePermissions.filter(x => x.value).length === 0) {
					useNotif.warning(`Vous devez sélectionner au moins une permission`);
					return;
				}

				try {
					const { data: newTag } = await this.api.add({
						...payload,
						organizationId,
					});
					resolve(newTag);
				} catch (e: any) {
					useNotif.danger(`Oops`, e?.response?.data);
				}
			}
		});
	}

	async getAsync (id: number, withHiddenUsers = false) {
		const { data } = await this.api.selectById(id, withHiddenUsers);
		return data;
	}

	async updateAsync (id: number, payload: IUpdateOrganizationRoleModel) {
		return new Promise<void>(async (resolve) => {
			try {
				const { data } = await this.api.update(id, {
					name: payload.name,
					colorId: payload.colorId,
				});
				resolve(data);
			} catch (e: any) {
				useNotif.danger(`Oops`, e?.response?.data);
			}
		});
	}

	async deleteAsync (id: number) {
		const { data } = await this.api.delete(id);
		return data;
	}

	async listAsync (forceRefresh = false, organizationId = this.self.organization?.id) {
		if (!organizationId) return [];
		return await this.cooler.coolAsync(forceRefresh, organizationId);
	}

	async promptNewRoleAsideAsync (userType = this.self.userType) {
		if (!userType) return;
		if (!this.self.organizationGuard()) return;

		return new Promise<Undef<IOrganizationRoleDetailModel>>((resolve) => {
			useAside({
				Nested: RoleAsideNew,
				events: {
					'leave-end': () => resolve(undefined),
					'cancel': () => resolve(undefined),
					'save': async (Aside, payload: OrganizationRoleDetailEntity) => {
						if (payload.color) {
							const res = await this.storeAsync({
								userType,
								rolePermissions: payload.rolePermissions,
								name: payload.name,
								colorId: payload.color?.id,
							});

							resolve(res);
							Aside.close();
							useNotif.success('Rôle créé avec succès !');
						}
					},
				},
			});
		});
	}

	async selfAsync (...args: Parameters<typeof this.api.self>) {
		const { data } = await this.api.self(...args);
		return data;
	}
}

const organizationRoleServiceSingleton = new OrganizationRoleService();

export default function useOrganizationRoleService (): OrganizationRoleService;
// eslint-disable-next-line no-unused-vars
export default function useOrganizationRoleService (id: number, withHiddenUsers?: boolean): Promise<IOrganizationRoleDetailModel>;
export default function useOrganizationRoleService (id?: number, withHiddenUsers = false) {
	if (typeof id === 'number') {
		return organizationRoleServiceSingleton.getAsync(id, withHiddenUsers);
	}
	return organizationRoleServiceSingleton;
}
