import ApiClient from '@/api/ApiClient';

const client = new ApiClient('tempworker/message');

class MessageApiTempWorker {
	static add (addMessageModel: IAddMessageModel) {
		return client.post<IMessageForBroadcastModel>('', addMessageModel);
	}

	static grid (filter: IMessagePagedListParameters) {
		return client.post<IPagedList<IMessageModel>>('grid', filter);
	}

	static listUnReaded (unReadedMessageParameters: IUnReadedMessageParameters) {
		return client.get<IUnReadedMessageModel[]>('unreaded', { params: unReadedMessageParameters });
	}

	static read (id: number) {
		return client.put<number>(`${id}/read`);
	}

	static reaction (id: number, updateReaction: IUpdateMessageReactionModel) {
		return client.put<void>(`${id}/reaction`, updateReaction);
	}
}

export default MessageApiTempWorker;
