import { PropType, reactive, ref, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrderEntity from '@/entity/order/OrderEntity';
import OrderSpecificScheduleEntity from '@/entity/order/OrderSpecificScheduleEntity';
import { groupBy } from 'lodash-es';
import ScheduleOrderRangeEntity from '@/entity/order/ScheduleOrderRangeEntity';
import { getUid } from '@orion.ui/orion';
import OrderTempWorkerEntity from '@/entity/order/OrderTempWorkerEntity';

type Props = SetupProps<typeof OrderCreationScheduleSetupService.props>

export default class OrderCreationScheduleSetupService extends BaseSetupService<Props> {
	static readonly props = {
		specific: Boolean,
		readonly: Boolean,
		order: {
			type: Object as PropType<OrderEntity | OrderTempWorkerEntity>,
			required: true as const,
		},
		weeknumber: {
			type: Number,
			default: undefined,
		},
		daterange: {
			type: Object as PropType<Orion.DateRange>,
			default: undefined,
		},
		schedules: {
			type: Object as PropType<OrderSpecificScheduleEntity[]>,
			default: () => [],
		},
		scheduleRange: {
			type: Object as PropType<ScheduleOrderRangeEntity>,
			default: undefined,
		},
	};

	private readonly state = reactive({
		daterange: undefined as Undef<Orion.DateRange>,
		dailyPlanningClass: undefined as Undef<string>,
	});

	private _uid = getUid();

	_el = ref<Element>();

	get daterange () { return this.state.daterange;}
	set daterange (val) { this.state.daterange = val;}

	get uid () { return this._uid; }

	get specificScheduleEntityGroupedByWeeknumber () {
		if (!this.props.schedules) return;
		return groupBy(this.props.schedules, 'weeknumber');
	}

	get isReadonly () {
		return !this.props.order.canModify() || !this.self.can('OrderUpdate') || this.props.readonly;
	}

	constructor (props?: Props, private _aside?: OrionAside) {
		super(props);

		watch(() => this.daterange, (val) => {
			if (val && val.selecting === false && this.props.scheduleRange && this.props.order.isCompanyOrder()) {
				this.props.order.updateSpecificScheduleDaterange(val, this.props.scheduleRange);
			}
		});
		watch(() => this.props.scheduleRange?.startRange, (val) => {
			if (!val || !this.daterange) return;
			this.daterange.start = new Date(val);
		});
		watch(() => this.props.scheduleRange?.endRange, (val) => {
			if (!val || !this.daterange) return;
			this.daterange.end = new Date(val);
		});
	}

	protected onMounted () {
		super.onMounted();
		if (this.props.scheduleRange) {
			this.state.daterange = {
				start: new Date(this.props.scheduleRange.startRange),
				end: new Date(this.props.scheduleRange.endRange),
			};
		}

		this.registerBusEvent(`${this._uid}`, weekday => this.setHighlightClass(weekday));
	}

	deletePeriod () {
		if (this.props.scheduleRange?.id)
			this.props.order?.scheduleOrderRangesEntities.deleteWhere('id', this.props.scheduleRange.id);
	}

	getSpecificScheduleEntity (weekday: number, weeknumber: number) {
		return this.props.scheduleRange?.scheduleOrderEntities.filter(x => x.weeknumber === weeknumber).findByKey(weekday, 'weekDay');
	}

	setHighlightClass (weekday:number) {
		this._el.value?.querySelectorAll(`[data-day="${this.uid}-${weekday}"]`)
			.forEach((element) => {
				element.classList.add('arm-highlight-timeslot');
			});

		setTimeout(() => {
			this._el.value?.querySelectorAll(`[data-day="${this.uid}-${weekday}"]`)
				.forEach((element) => {
					element.classList.remove('arm-highlight-timeslot');
				});
		}, 600);
	}

}
