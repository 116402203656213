import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/discussion');

class DiscussionApiAgency {

	static add (addDiscussionAgencyModel: IAddDiscussionAgencyModel) {
		return client.post<number>('', addDiscussionAgencyModel);
	}

	static grid (filter: IDiscussionPagedListParameters) {
		return client.post<IPagedList<IDiscussionItemModel>>('grid', filter);
	}

	static selectById (id: number) {
		return client.get<IDiscussionItemModel>(`${id}`);
	}

	static search (searchAgencyParameters: ISearchDiscussionForAgencyParameters) {
		return client.get<IDiscussionItemModel>('search', { params: searchAgencyParameters });
	}
}

export default DiscussionApiAgency;
