import { PropType, nextTick, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrderEntity from '@/entity/order/OrderEntity';
import OrderTimeSlotEntity from '@/entity/order/OrderTimeSlotEntity';
import OrderSpecificScheduleEntity from '@/entity/order/OrderSpecificScheduleEntity';
import { Bus, getUid, itemIs } from '@orion.ui/orion';
import OrderScheduleEntity from '@/entity/order/OrderScheduleEntity';
import OrderTempWorkerEntity from '@/entity/order/OrderTempWorkerEntity';

type Props = SetupProps<typeof OrderCreationDailyPlanningSetupService.props>

export default class OrderCreationDailyPlanningSetupService extends BaseSetupService<Props> {
	static readonly props = {
		specific: Boolean,
		readonly: Boolean,
		order: {
			type: Object as PropType<OrderEntity | OrderTempWorkerEntity>,
			required: true as const,
		},
		weekday: {
			type: Number,
			required: true as const,
		},
		schedule: {
			type: Object as PropType<OrderSpecificScheduleEntity | OrderScheduleEntity>,
			default: undefined,
		},
		parentUid: {
			type: Number,
			required: true as const,
		},
	};

	private readonly state = reactive({ timeslotEnter: undefined as Undef<number> });

	get timeslotEnter () { return this.state.timeslotEnter; }
	get isDisabled () { return this.props.schedule === undefined; }

	get readableDay () {
		let readableDay;
		switch (this.props.weekday) {
		case 1:
			readableDay = this.props.specific ? `Lun.` : 'Lundi';
			break;
		case 2:
			readableDay = this.props.specific ? 'Mar.' : 'Mardi';
			break;
		case 3:
			readableDay = this.props.specific ? 'Mer.' : 'Mercredi';
			break;
		case 4:
			readableDay = this.props.specific ? 'Jeu.' : 'Jeudi';
			break;
		case 5:
			readableDay = this.props.specific ? 'Ven.' : 'Vendredi';
			break;
		case 6:
			readableDay = this.props.specific ? 'Sam.' : 'Samedi';
			break;
		case 0:
			readableDay = this.props.specific ? 'Dim.' : 'Dimanche';
			break;
		}

		return readableDay;
	}

	get shortDate () {
		if (this.props.schedule) {
			if (itemIs<OrderSpecificScheduleEntity>(this.props.schedule, 'date'))
				return this.props.schedule.date.toReadableDate('$DD/$MM');
		}
	}

	get dayColor () {
		return this.props.schedule?.isActive
			? this.props.specific ? 'warning' : 'info'
			: 'default';
	}

	get displayAddButton () {
		return !this.isDisabled
      && this.props.schedule
      && this.props.schedule.isActive
      && !this.props.readonly;
	}

	constructor (props?: Props) {
		super(props);
	}

	addTimeSlot () {
		const start = this.props.schedule?.timeSlotEntities.last()?.endTime;
		const end = this.props.schedule?.timeSlotEntities.last()?.endTime;
		this.props.schedule?.timeSlotEntities.push(new OrderTimeSlotEntity({
			id: getUid(),
			startTime: start
				? new Date(new Date(start).setHours(new Date(start).getHours() + 1)).toPost(true)
				: new Date(new Date().setHours(8, 0)).toPost(true),
			endTime: end
				? new Date(new Date(end).setHours(new Date(end).getHours() + 2)).toPost(true)
				: new Date(new Date().setHours(18, 0)).toPost(true),
		}));
	}

	toggleDay () {
		if (this.props.schedule) {
			this.props.schedule.isActive = !this.props.schedule?.isActive;
			if (this.props.schedule.isActive && !this.props.schedule.timeSlotEntities.length) {
				this.addTimeSlot();
			}
		}
	}

	setTimeslotEnter (id: number) {
		this.state.timeslotEnter = id;
	}

	removeTimeslotEnter () {
		this.state.timeslotEnter = undefined;
	}

	deleteTimeslot (id: number) {
		if (this.props.schedule?.timeSlotEntities.length === 1) {
			this.toggleDay();
			return;
		}
		if (this.props.schedule) {
			this.props.schedule.timeSlotEntities = this.props.schedule?.timeSlotEntities.deleteWhere('id', id);
		}
	}

	isSpecificSchedule (): this is { props: { schedule: OrderSpecificScheduleEntity } } {
		if (this.props.schedule?.entityState)
			return itemIs<IScheduleOrderModel>(this.props.schedule?.entityState, 'scheduleOrderTimeSlots');
		return false;
	}

	duplicateDay (allPeriod = false) {
		if (this.props.schedule && this.props.order.isCompanyOrder()) {
			this.props.order?.duplicateDay(this.props.schedule, allPeriod, this.props.specific);

			if (!allPeriod) {
				nextTick(() => {
					Bus.emit(`OrderCreationScheduleSetupService:${this.props.parentUid}`, this.props.schedule?.weekDay);
				});
			}
		}
	}
}
