import ApiClient from '@/api/ApiClient';

const ROUTE = 'tempworker/userlanguage';
const client = new ApiClient(ROUTE);

class UserLanguageApiTempWorker {

	static add (addUserLanguageModel: IAddUserLanguageModel) {
		return client.post<IUserLanguageModel>('', addUserLanguageModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

}

export default UserLanguageApiTempWorker;
