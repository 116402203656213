<template>
	<div class="order-step">
		<o-section id="order-step-5">
			<h4 class="order-step__title">
				<span class="text--brand">
					Étape 5
				</span> - Commentaire et validation
			</h4>
			<div class="row row--grid">
				<div class="col-md-12">
					<o-select
						v-model="setup.jobDescriptionId"
						class="order-step__select-template"
						track-key="id"
						searchable
						clearable
						display-key="title"
						value-key="id"
						label="Choix d'un modèle de description de poste"
						:options="setup.jobDescriptionTemplates"
						@input="setup.selectTemplate()"
						@click="setup.updateListAsync()"
						@clear="setup.clearTemplate">
						<template #option="{ item }">
							<div class="order-step__select-item">
								<div class="order-step__item-description">
									<strong> {{ `${item.title}` }} </strong> - {{ item.content }}
								</div>
								<template v-if="item.professionalSkills && item.professionalSkills.filter((x: IProfessionalSkillModel) => !!x).length">
									<o-label
										v-for="skill in item.professionalSkills"
										:key="skill.id"
										outline
										style="margin-top:0.3rem; margin-left:0.3rem"
										:color="skill.id === (order.professionalSkill && order.professionalSkill.id) ? 'info' : 'default'">
										{{ skill.label }}
									</o-label>
								</template>
							</div>
						</template>
						<template
							v-if="setup.templateSelected"
							#display="{ fieldValue }">
							<div v-if="fieldValue">
								<strong> {{ fieldValue.title }} </strong>
								<o-chips
									v-for="skill in fieldValue.professionalSkills"
									:key="skill.id"
									outline
									size="sm"
									style="margin-left:5px">
									{{ skill.label }}
								</o-chips>
							</div>
						</template>
					</o-select>
				</div>

				<div class="col-md-12">
					<o-textarea
						v-model="order.jobDescription"
						label="Description du poste"/>
				</div>

				<div
					v-if="setup.self.hasOrganization"
					class="col-md-12">
					<shared-tag-select
						v-model="order.tagIds"
						:link-type="order.tagLinkType"
						:required="setup.orderNeedTag"
						multiple
						label="Tag"/>
					<o-alert
						v-if="setup.orderNeedTag"
						color="warning"
						title="Vous devez posséder au moins un des tags de cette commande.">
						<span>Dans le cas contraire, vous ne pourrez pas
							voir cette commande après sa création.</span>
					</o-alert>
				</div>

				<div class="col-md-12">
					<o-textarea
						v-model="order.comment"
						label="Commentaire"/>
				</div>

				<div
					v-if="setup.self.isAgency()"
					class="col-md 12">
					<o-checkbox
						v-model="order.warnClient"
						label="Avertir et faire participer le client à la commande"/>
				</div>
			</div>
		</o-section>

		<o-section
			v-if="order.asideActivePane !== 6"
			align="center">
			<o-button
				prefix-font-icon="icon-interface-arrows-button-left"
				outline
				@click="$emit('current', 4)">
				Précédent
			</o-button>
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				outline
				@click="setup.next()">
				Suivant
			</o-button>
		</o-section>
		<o-section
			v-else
			align="center">
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				color="success"
				@click="$emit('current', 6)">
				Valider
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import OrderCreationStep5SetupService from '@/setup/order/creation/OrderCreationStep5SetupService';
type Emits = { (e: 'current' | 'origin', val: number): void }

const emits = defineEmits<Emits>();
const props = defineProps(OrderCreationStep5SetupService.props);
const setup = new OrderCreationStep5SetupService(props, emits);
</script>

<style lang="less">
@import '@/styles/variables';
@import '@/styles/mixins';

.order-step__select-item {
	width: 31rem;
}

.order-step__item-description {
	.ellipsis();
	max-height: 100%;
}

</style>
