<template>
	<div class="add-job-description">
		<o-section :title="title">
			<o-input
				v-model="orderTemplate.title"
				label="Titre"
				required
				:validation="setup.validator.rule('title')"
				@input="_modal?.trigger('resetParentTitleValidation')"/>
			<o-select
				v-model="orderTemplate.professionalSkills"
				multiple
				clearable
				label="Qualifications associées"
				track-key="id"
				display-key="label"
				searchable
				:options="setup.professionalSkillsOptions"/>
		</o-section>

		<o-textarea
			v-model="orderTemplate.content"
			label="Description"
			required
			:max-length="2000"
			:validation="setup.validator.rule('content')"
			@input="_modal?.trigger('resetParentDescriptionValidation')"/>

		<o-section align="right">
			<o-button
				color="danger"
				nude
				@click="setup.cancel()">
				Annuler
			</o-button>
			<o-button
				color="success"
				@click="setup.validate()">
				Valider
			</o-button>
		</o-section>

		<o-loader
			:visible="setup.showLoader"
			message="Chargement des qualifications..."/>
	</div>
</template>

<script setup lang="ts">
import OrderToolboxJobDescriptionAddModalSetupService from '@/setup/order/toolbox/OrderToolboxJobDescriptionAddModalSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(OrderToolboxJobDescriptionAddModalSetupService.props);
const setup = new OrderToolboxJobDescriptionAddModalSetupService(props, _modal);
</script>
