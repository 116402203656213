import { pickFrom } from '@/utils/tools';
import BaseEntity from '../BaseEntity';
import { Reactive } from '@/utils/decorators';

export default class OrderTimeSlotEntity extends BaseEntity<IScheduleDefaultTimeSlotModel> {


	// eslint-disable-next-line local-rules/state-are-private-readonly
	@Reactive protected readonly selfState = {
		startHours: new Date(this.state.startTime).getHours() + new Date(this.state.startTime).getMinutes() / 60 ?? 0,
		endHours: new Date(this.state.endTime).getHours() + new Date(this.state.endTime).getMinutes() / 60 ?? 0,
	};

	get startHours () { return this.selfState.startHours; }
	set startHours (val) {
		this.selfState.startHours = val;
		const hours = Math.floor(val);
		let decpart = val - hours;

		const min = 1 / 60;
		decpart = min * Math.round(decpart / min);
		const minutes = Math.floor(decpart * 60);
		this.state.startTime = new Date(new Date(new Date(this.state.startTime).setHours(hours)).setMinutes(minutes)).toPost(true);
	}

	get endHours () { return this.selfState.endHours; }
	set endHours (val) {
		this.selfState.endHours = val;
		const hours = Math.floor(val);
		let decpart = val - hours;

		const min = 1 / 60;
		decpart = min * Math.round(decpart / min);
		const minutes = Math.floor(decpart * 60);
		this.state.endTime = new Date(new Date(new Date(this.state.endTime).setHours(hours)).setMinutes(minutes)).toPost(true);
	}

	get startTime () { return this.state.startTime; }
	set startTime (val) { this.state.startTime = val; }

	get endTime () { return this.state.endTime; }
	set endTime (val) { this.state.endTime = val; }

	get entityState () {
		return pickFrom(
			this,
			'id',
			'startTime',
			'endTime',
		);
	}

	constructor (payload?: IScheduleDefaultTimeSlotModel) {
		super(payload);
	}

}
