import { nextTick, reactive } from 'vue';
import { Bus, useChat, useNotif } from '@orion.ui/orion';
import router from '@/router/index.router';

import DiscussionApiAgency from '@/api/DiscussionApiAgency';
import DiscussionApiClient from '@/api/DiscussionApiClient';
import DiscussionApiTempWorker from '@/api/DiscussionApiTempWorker';
import MessageApiAgency from '@/api/MessageApiAgency';
import MessageApiClient from '@/api/MessageApiClient';
import MessageApiTempWorker from '@/api/MessageApiTempWorker';
import TimeTicketWeekApiAgency from '@/api/TimeTicketWeekApiAgency';
import TimeTicketWeekApiClient from '@/api/TimeTicketWeekApiClient';
import OrderTempWorkerProposalApiTempWorker from '@/api/OrderTempWorkerProposalApiTempWorker';

import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import { MESSAGE_TYPE } from '@/typings/enums/MESSAGE_TYPE';

import SignalRMessage from '@/signalr/Messages.signalr';
import SignalRDiscussion from '@/signalr/Discussion.signalr';
import SignalrAppInstance from '@/signalr/App.signalr';

import type SharedDiscussionsSetupService from '@/setup/shared/SharedDiscussionsSetupService';
import type SelfEntity from '@/entity/SelfEntity';
import { OrionChatEntity } from '@orion.ui/orion/dist/types/packages';
import useUiService from './UiService';


type UnreadMessages = {
  id: number,
  discussionId: number
}

type DiscussionItem = IDiscussionItemModel & {
  isNew?: boolean
}

class DiscussionService {

	private get apiDiscussion () {
		if (this.user.isAgency())
			return DiscussionApiAgency;
		else if (this.user.isClient())
			return DiscussionApiClient;
		else
			return DiscussionApiTempWorker;
	}

	private get apiMessage () {
		if (this.user.isAgency())
			return MessageApiAgency;
		else if (this.user.isClient())
			return MessageApiClient;
		else
			return MessageApiTempWorker;
	}

	private get api () {
		return {
			discussion: this.apiDiscussion,
			message: this.apiMessage,
		};
	}

	readonly user: SelfEntity;
	readonly chat: ReturnType<typeof useChat>;

	private readonly state = reactive({
		rawDiscussions: [] as DiscussionItem[],
		openedDiscussionIds: [] as number[],
		minimizedDiscussions: [] as number[],
		joinedDiscussions: [] as number[],
		unreadMessages: [] as UnreadMessages[],
		chatComponent: undefined as Undef<SharedDiscussionsSetupService['publicInstance']>,
		showLoader: false,
		unreadOnly: false,
	});

	get openedDiscussionIds () { return this.state.openedDiscussionIds; }
	get minimizedDiscussions () { return this.state.minimizedDiscussions; }
	get showLoader () { return this.state.showLoader; }
	get unreadMessagesCount () { return this.state.unreadMessages.length; }
	get openedDiscussions () {
		return this.openedDiscussionIds
			.map(discussionId => this.chat.getDiscussion(discussionId))
			.filterNil() as OrionChatEntity[];
	}

	get unreadOnly () { return this.state.unreadOnly; }
	set unreadOnly (val) {
		this.state.unreadOnly = val;
		this.chat.fetchDiscussionsAsync(' ', true);
	}


	constructor (user: SelfEntity) {
		this.user = user;
		this.chat = this.initChat(user);
	}


	initChat (user: SelfEntity) {
		return useChat({
			user: {
				id: user.id,
				name: user.name,
				avatar: `/app/avatar/${user.avatarId}`,
			},
			allowDiscussionCreation: false,
			onActiveDiscussionChange: this.onActiveDiscussionChange.bind(this),
			discussionFetcherAsync: async ({ oldestDiscussionUpdatedDate, searchTerm, searchTermHasChanged }) => {
				if (searchTermHasChanged) {
					this.chat.setDiscussionsFullyLoaded(false);
				}

				this.state.showLoader = this.chat.discussions.length === 0;

				const updatedDateLowerThan = searchTermHasChanged
					? undefined
					: oldestDiscussionUpdatedDate
						? new Date(oldestDiscussionUpdatedDate).toPost(true)
						: undefined;

				const { data } = await this.api.discussion.grid({
					research: searchTerm?.trim().length ? searchTerm : undefined,
					updatedDateLowerThan,
					isUnread: this.unreadOnly,
					page: {
						index: 1,
						size: 10,
					},
					order: {
						property: 'UpdatedDate',
						ascending: false,
					},
				});

				this.state.rawDiscussions.push(...data.list);
				this.state.showLoader = false;

				if (data.list.length) {
					return data.list.map(x => this.formatDiscussion(x));
				} else {
					this.chat.setDiscussionsFullyLoaded(true);
					return [];
				}

			},
			messageFetcherAsync: async ({ discussionId, oldestMessageId, discussion }) => {
				if (!discussion.createdDate) return [];

				const rawDiscussion = this.getRawDiscussionFromId(discussionId);
				if (rawDiscussion?.isNew) return [];

				const { data } = await this.api.message.grid({
					discussionId,
					idLowerThan: oldestMessageId,
					page: {
						index: 1,
						size: 10,
					},
					order: {
						property: 'Id',
						ascending: false,
					},
				});

				return data.list.map(x => this.formatMessage(x, discussionId)).filterNil() as Orion.Chat.Message[];
			},
			onMessageReadAsync: async (message) => {
				if (message.author.id !== this.user.id) {
					this.api.message.read(message.id);
					this.deleteUnreadMessage(message.id, message.discussion.id);
				}
			},
			onNewMessageAsync: async ({ discussion, content }) => {
				const rawDiscussion = this.getRawDiscussionFromId(discussion.id);

				if (!rawDiscussion?.agencyCompany?.id
          || !rawDiscussion.discussionContext?.documentId
          || rawDiscussion.discussionContext?.documentType === undefined) return;

				if (rawDiscussion.isNew) {
					const { data } = await this.api.discussion.add({
						...rawDiscussion.discussionContext,
						agencyCompanyId: rawDiscussion?.agencyCompany?.id,
						clientCompanyId: rawDiscussion?.clientCompany?.id as number,
						tempWorkerUserId: rawDiscussion?.tempWorkerUser?.id as number,
						messageContent: content,
						messageType: MESSAGE_TYPE.Infos,
					});

					if (data) {
						this.chat.deleteDiscussion(discussion.id);
						this.close(discussion.id);

						if (SignalrAppInstance.connection.state !== 'Connected') {
							await this.chat.fetchDiscussionsAsync(undefined, true);
						}

						nextTick(() => this.open(data));
					}
				} else {
					const { data } = await this.api.message.add({
						discussionId: discussion.id,
						content,
						type: MESSAGE_TYPE.Infos,
					});

					if (SignalrAppInstance.connection.state !== 'Connected') {
						this.onAddedMessage(data);
					}
				}
			},
			discussionTitleFormatter: (discussion) => {
				return this.getRawDiscussionFromId(discussion.id)?.title
          ?? this.chat.config.discussionInterlocutorsFormatter?.(discussion).mapKey('name').join(', ')
          ?? '';
			},
			discussionInterlocutorsFormatter: (discussion) => {
				const rawDiscussion = this.getRawDiscussionFromId(discussion.id);
				const defaultReturn = {
					id: 0,
					name: '',
					avatar: '',
				};

				if (!rawDiscussion) return [defaultReturn] as Orion.Chat.User[];

				if (this.user.isAgency()) {
					if (rawDiscussion.clientCompany) {
						return [{
							id: rawDiscussion.clientCompany.id,
							name: rawDiscussion.clientCompany.name ?? defaultReturn.name,
							avatar: rawDiscussion.clientCompany.name ?? defaultReturn.name,
						}] as Orion.Chat.User[];
					} else if (rawDiscussion.tempWorkerUser) {
						return [{
							id: rawDiscussion.tempWorkerUser.id,
							name: rawDiscussion.tempWorkerUser.name ?? defaultReturn.name,
							avatar: rawDiscussion.tempWorkerUser.avatarId ? `/app/avatar/${rawDiscussion.tempWorkerUser.avatarId}` : '',
						}] as Orion.Chat.User[];
					}
				} else if (rawDiscussion.agencyCompany) {
					return [{
						id: rawDiscussion.agencyCompany.id,
						name: rawDiscussion.agencyCompany.name ?? defaultReturn.name,
						avatar: `/app/companylogo/${rawDiscussion.agencyCompany.id}`,
						avatarProps: {
							square: true,
							contain: true,
						},
					}] as Orion.Chat.User[];
				}

				return [defaultReturn] as Orion.Chat.User[];
			},
			discussionUnreadMessagesCounter: ({ discussionId }) => {
				return this.state.unreadMessages.filter(x => x.discussionId === discussionId).length;
			},
		});
	}

	registerDiscussionsComponent (chatComponent: SharedDiscussionsSetupService['publicInstance']) {
		this.state.chatComponent = chatComponent;
	}

	discussionIsInChat (discussionId: number) {
		return this.chat.discussions.mapKey().includes(discussionId);
	}

	getRawDiscussionFromId (id: number) {
		return this.state.rawDiscussions.findByKey(id) as Undef<DiscussionItem>;
	}

	getDiscussionAgencyCompanyName (discussionId: number) {
		return this.getRawDiscussionFromId(discussionId)?.agencyCompany?.name;
	}

	getDiscussionClientCompanyName (discussionId: number) {
		return this.getRawDiscussionFromId(discussionId)?.clientCompany?.name;
	}

	deleteUnreadMessage (messageId: number, discussionId: number) {
		this.state.unreadMessages.deleteWhere('id', messageId);
		Bus.emit('SharedDiscussionsSetupService:check-unread-messages-in-dom', discussionId);
	}

	onAddedMessage (message: IMessageForBroadcastModel) {
		if (message.author?.id !== this.user.id && message.authorUserType !== this.user.userType && message.discussion) {
			this.addUnreadMessage(message.id, message.discussion.id);
		}

		if (message.discussion && !this.chat.getDiscussion(message.discussion.id)) {
			this.state.rawDiscussions.push(message.discussion);
			this.chat.addDiscussion(this.formatDiscussion(message.discussion));
		}

		if (message.discussion) {
			const formattedMessage = this.formatMessage(message, message.discussion.id) as Orion.Chat.Message;
			this.chat.addMessagesToDiscussions([formattedMessage]);
		}
	}

	onUpdatedMessage (message: IMessageForBroadcastModel) {
		if (message.discussion?.id && message.isRead) {
			this.chat.getMessage(message.discussion.id, message.id)?.read();
		}
	}

	addUnreadMessage (messageId: number, discussionId: number) {
		this.state.unreadMessages.push({
			id: messageId,
			discussionId,
		});
	}

	async getUnreadMessagesIdsAsync (discussionId?: number) {
		const { data } = await this.api.message.listUnReaded({ discussionId });
		this.state.unreadMessages = data;
	}


	// #region UX actions
	open (discussionId: number) {
		if (!this.discussionIsInChat(discussionId)) {
			useNotif.warning(`Oops`, `Un problème est survenu avec cette discussion`);
			throw `DiscussionNotInChat - id ${discussionId}`;
		}

		this.state.openedDiscussionIds.pushUniq(discussionId);
		this.expand(discussionId);

		if (useUiService().onLayoutNavBottom && this.state.chatComponent) {
			this.state.chatComponent.hide();
		}

		nextTick(() => {
			const mainWrapper = document.getElementById('arm-main');
			// eslint-disable-next-line max-len
			if ((this.state.chatComponent?._discussionsList().value?.offsetWidth ?? 0) > (mainWrapper?.offsetWidth ?? 0) && this.state.openedDiscussionIds.length > 1) {
				const first = this.state.openedDiscussionIds.first();
				if (first) this.close(first);
			}
		});
	}

	close (discussionId: number) {
		this.state.openedDiscussionIds.delete(discussionId);
		this.state.minimizedDiscussions.delete(discussionId);
	}

	minimize (discussionId: number) {
		this.state.minimizedDiscussions.pushUniq(discussionId);
	}

	expand (discussionId: number) {
		this.state.minimizedDiscussions.delete(discussionId);
	}
	// #endregion


	// #region Context
	getContextLinkFromDiscussionId (discussionId: number) {
		const rawDiscussion = this.getRawDiscussionFromId(discussionId);
		if (rawDiscussion?.discussionContext) {
			return this.getContextLink(rawDiscussion.discussionContext);
		}
	}

	getContextLink (context: IDiscussionContextModel) {
		switch (context.documentType) {
		case DOCUMENT_TYPE.ClientContract:
			return {
				label: 'Voir le contrat',
				route: {
					name: 'ClientContractDetails',
					params: { id: context.documentId },
				},
			};
		case DOCUMENT_TYPE.TempWorkerContract:
			return {
				label: 'Voir le contrat',
				route: {
					name: 'TempWorkerContractDetails',
					params: { id: context.documentId },
				},
			};
		case DOCUMENT_TYPE.SalarySlip:
			return {
				label: 'Voir le bulletin',
				route: {
					name: 'SalarySlipDetails',
					params: { id: context.documentId },
				},
			};
		case DOCUMENT_TYPE.Certificate:
			return {
				label: 'Voir le certificat',
				route: {
					name: 'CertificateDetails',
					params: { id: context.documentId },
				},
			};
		case DOCUMENT_TYPE.Bill:
			return {
				label: 'Voir la facture',
				route: {
					name: 'BillDetails',
					params: { id: context.documentId },
				},
			};
		case DOCUMENT_TYPE.TimeTicket:
			return {
				label: 'Voir le RH',
				route: {
					name: 'TimeTicketWeek',
					params: {},
					query: { id: context.documentId },
				},
			};
		case DOCUMENT_TYPE.Order:
			if (this.user.isTempWorker()) {
				return {
					label: `Voir l'offre de mission`,
					route: {
						name: 'JobOfferDetails',
						// params: { id: context.documentId }, ID params defined after ajax call
					},
				};
			} else {
				return {
					label: 'Voir la commande',
					route: {
						name: 'OrderDetailsRecap',
						params: { id: context.documentId },
					},
				};
			}
		}
	}

	async openDiscussionFromContextAsync (context: Armado.DiscussionContext) {
		try {
			const { data } = await this.api.discussion.search({
				documentId: context.documentId,
				documentType: context.documentType,
				clientCompanyId: context.clientCompany?.id,
				tempWorkerUserId: context.tempWorkerUser?.id,
			});

			if (data.id) {
				this.state.rawDiscussions.push(data);
				if (!this.discussionIsInChat(data.id)) {
					this.chat.addDiscussion(this.formatDiscussion({
						...data,
						// change updatedDate to correctly load other discussions when opening discussion list
						updatedDate: new Date().toPost(),
					}));
				}
				this.open(data.id);
			}
		} catch (e: any) {
			if (e?.response?.data?.code === 'discussion_missing') {
				this.openOrCreateEmptyDiscussion(context);
			}
		} finally {
			if (useUiService().onLayoutNavBottom) {
				useUiService().asideLeftSlideOut = false;
				useUiService().asideRightSlideOut = false;
			}
		}
	}

	getContextFromDiscussionId (discussionId: number) {
		const context = this.getRawDiscussionFromId(discussionId)?.discussionContext;
		return context;
	}

	async goToContextAsync (discussionId: number) {
		const context = this.getContextFromDiscussionId(discussionId);
		const route = this.getContextLinkFromDiscussionId(discussionId)?.route as Record<string, any>;

		if (!context || !route) return;

		if (context.documentType === DOCUMENT_TYPE.TimeTicket && (this.user.isAgency() || this.user.isClient())) {
			const api = this.user.isAgency() ? TimeTicketWeekApiAgency : TimeTicketWeekApiClient;
			const { data } = await api.discussionLink(context.documentId);
			route.params.year = data.year;
			route.params.weekNumber = data.weekNumber;
			route.query.research = data.timeTicketClientContractReference;
		}

		if (context.documentType === DOCUMENT_TYPE.Order && this.user.isTempWorker()) {
			try {
				const { data } = await OrderTempWorkerProposalApiTempWorker.link(context.documentId);
				route.params = { id: data };
			} catch (e: any) {
				if (e?.response?.status === 422) {
					useNotif.danger(e?.response.data);
					return;
				}
			}
		}

		if (router.resolve(route).href === location.href.replace(location.origin, '')) return;

		try {
			router.push(route);
		} catch (e) {
			// use a try/catch to catch the error in case of "NavigationDuplicated"
		}
	}
	// #endregion


	// #region Empty discussion
	generateEmptyDiscussionId () {
		return new Date().valueOf();
	}

	openOrCreateEmptyDiscussion (context: Armado.DiscussionContext) {
		const id = this.generateEmptyDiscussionId();
		if (this.discussionIsInChat(id)) {
			this.open(id);
		} else {
			this.open(this.createEmptyDiscussion(context));
		}
	}

	createEmptyDiscussion (context: Armado.DiscussionContext) {
		const emptyDiscussion: DiscussionItem = {
			isNew: true,
			id: this.generateEmptyDiscussionId(),
			discussionContext: context,
			title: context.title,
			subTitle: context.subtitle,
			createdDate: new Date().toPost(true),
			updatedDate: new Date().toPost(true),
			participants: [
				{
					user: {
						id: this.user.id,
						firstName: this.user.firstName,
						lastName: this.user.lastName,
						name: this.user.name,
						avatarId: this.user.avatarId,
					},
				},
			],
			agencyCompany: context.agencyCompany,
		};

		if (context.tempWorkerUser?.id) emptyDiscussion.tempWorkerUser = context.tempWorkerUser;
		if (context.clientCompany?.id) emptyDiscussion.clientCompany = context.clientCompany;

		this.state.rawDiscussions.push(emptyDiscussion);
		this.chat.addDiscussion(this.formatDiscussion(emptyDiscussion));

		return emptyDiscussion.id;
	}
	// #endregion


	// #region Format data
	formatDiscussion (discussion: DiscussionItem): Orion.Chat.Discussion {
		const participants = [{
			id: discussion.agencyCompany?.id ?? 0,
			name: discussion.agencyCompany?.name ?? '',
			avatar: `/app/companylogo/${discussion.agencyCompany?.id}`,
			avatarProps: {
				square: true,
				contain: true,
			},
		}];

		if (discussion.clientCompany) {
			participants.push({
				id: discussion.clientCompany.id,
				name: discussion.clientCompany.name ?? '',
				// avatar: `/app/companylogo/${discussion.clientCompany.id}`,
				avatar: discussion.clientCompany.name ?? '',
				avatarProps: {
					square: false,
					contain: true,
				},
			});
		} else if (discussion.tempWorkerUser) {
			participants.push({
				id: discussion.tempWorkerUser.id,
				name: discussion.tempWorkerUser.name ?? '',
				avatar: discussion.tempWorkerUser.avatarId
					? `/app/avatar/${discussion.tempWorkerUser.avatarId}`
					: discussion.tempWorkerUser.name ?? '',
				avatarProps: {
					square: false,
					contain: true,
				},
			});
		}

		return {
			id: discussion.id,
			createdDate: new Date(discussion.createdDate),
			updatedDate: new Date(discussion.updatedDate),
			lastMessage: discussion.lastMessage ? this.formatMessage(discussion.lastMessage, discussion.id) : undefined,
			messages: [],
			participants,
		};
	}

	formatMessage (message: IMessageModel | IMessageForBroadcastModel, discussionId: number): Undef<Orion.Chat.Message> {
		if (!message || !message.author) return;
		return {
			discussionId,
			id: message.id,
			content: message.content,
			isRead: message.isRead,
			createdDate: new Date(message.createdDate),
			author: {
				id: message.author.id,
				name: message.author.name ?? '',
				avatar: message.author?.avatarId ? `/app/avatar/${message.author.avatarId}` : '',
			},
		};
	}
	// #endregion


	// #region SignalR
	onActiveDiscussionChange (discussionId?: number) {
		if (!discussionId) return;
		if (!this.state.joinedDiscussions.includes(discussionId)) {
			SignalRDiscussion.join(discussionId);
			this.state.joinedDiscussions.push(discussionId);
		}
	}

	startSignalRMessage () {
		SignalRMessage.onAdded(this.onAddedMessage.bind(this));
		SignalRMessage.onUpdated(this.onUpdatedMessage.bind(this));
	}

	stopSignalRMessage () {
		SignalRMessage.offAdded(this.onAddedMessage.bind(this));
		SignalRMessage.offUpdated(this.onUpdatedMessage.bind(this));
	}

	leaveDiscussions () {
		this.state.joinedDiscussions.forEach((id) => {
			SignalRDiscussion.leave(id);
		});
	}
	// #endregion
}

let discussionServiceSingleton: DiscussionService | undefined;

export default function useDiscussionService (): DiscussionService | undefined;
export default function useDiscussionService (user: SelfEntity): DiscussionService;
export default function useDiscussionService (user?: SelfEntity): DiscussionService | undefined {
	if (!!user) discussionServiceSingleton = new DiscussionService(user);
	return discussionServiceSingleton;
}
