import ApiClient from '@/api/ApiClient';

const client = new ApiClient('companysetting');

class CompanySettingApi {
	static selectSetting (settingType: number, companyId: number) {
		return client.get<ICompanySettingModel | undefined>(`?settingType=${settingType}&companyId=${companyId}`);
	}

	static saveSetting (updateCompanySetting: IUpdateCompanySettingModel) {
		return client.put<void>(``, updateCompanySetting);
	}
}

export default CompanySettingApi;
