<template>
	<div class="daily-planning">
		<div>
			<o-button
				block
				outline
				:nude="setup.isDisabled"
				class="daily-planinng__day"
				:class="{
					'daily-planning__day--disabled': setup.isDisabled,
					'daily-planning__day--active': schedule?.isActive,
				}"
				:color="setup.dayColor"
				@click="!setup.isDisabled && !readonly ? setup.toggleDay(): undefined">
				{{ setup.readableDay }} {{ setup.shortDate }}
			</o-button>
		</div>

		<div v-if="!specific || (specific && schedule?.isActive)">
			<div
				v-for="(timeslot, index) in schedule?.timeSlotEntities"
				:key="timeslot.id"
				class="daily-planning__day-timeslot"
				:data-day="`${parentUid}-${weekday}`"
				@mouseenter="setup.setTimeslotEnter(timeslot.id)"
				@mouseleave="setup.removeTimeslotEnter()">
				<o-input
					v-if="schedule?.isActive"
					v-model="timeslot.startHours"
					mask="hour"
					select-on-focus
					:readonly="readonly"
					:min-value="schedule?.timeSlotEntities[index - 1]?.endHours + 0.01"
					:max-value="23.99"
					size="xs"/>
				<o-input
					v-else
					:model-value="order.getScheduleDefaultEntity(weekday)?.timeSlotEntities[index]?.startHours"
					mask="hour"
					select-on-focus
					:class="[
						{ 'daily-planning__day-timeslot-unactive': !schedule?.isActive },
						{ 'daily-planning__day-timeslot-not-working': !specific && !schedule?.isActive },
					]"
					readonly
					size="xs"/>
				<o-input
					v-if="schedule?.isActive "
					v-model="timeslot.endHours"
					mask="hour"
					select-on-focus
					:min-value="timeslot?.startHours + 0.02"
					:readonly="readonly"
					:max-value="23.99"
					size="xs"/>
				<o-input
					v-else
					:model-value="order.getScheduleDefaultEntity(weekday)?.timeSlotEntities[index]?.endHours"
					mask="hour"
					select-on-focus
					:class="[
						{ 'daily-planning__day-timeslot-unactive': !schedule?.isActive },
						{ 'daily-planning__day-timeslot-not-working': !specific && !schedule?.isActive },
					]"
					readonly
					size="xs"/>
				<o-icon
					v-if="setup.timeslotEnter === timeslot.id && schedule?.isActive && !readonly"
					ripple="danger"
					font-icon="icon-interface-delete-bin-2"
					class="daily-planning__day-delete"
					@click="setup.deleteTimeslot(timeslot.id)"/>
			</div>
		</div>


		<template v-if="specific && (!schedule || !schedule.isActive)">
			<div
				v-for="(timeslot, index) in order.getScheduleDefaultEntity(weekday)?.timeSlotEntities"
				:key="timeslot.id"
				class="daily-planning__day-timeslot"
				@mouseenter="setup.setTimeslotEnter(timeslot.id)"
				@mouseleave="setup.removeTimeslotEnter()">
				<o-input
					:model-value="order.getScheduleDefaultEntity(weekday)?.timeSlotEntities[index]?.startHours"
					mask="hour"
					select-on-focus
					readonly
					class="daily-planning__day-timeslot-unactive"
					:class="[
						{ 'daily-planning__day-timeslot-not-working': !order.getScheduleDefaultEntity(weekday)?.isActive },
					]"
					size="xs"/>
				<o-input
					:model-value="order.getScheduleDefaultEntity(weekday)?.timeSlotEntities[index]?.endHours"
					mask="hour"
					class="daily-planning__day-timeslot-unactive"
					:class="[
						{ 'daily-planning__day-timeslot-not-working': !order.getScheduleDefaultEntity(weekday)?.isActive },
					]"
					select-on-focus
					readonly
					size="xs"/>
			</div>
		</template>

		<div
			v-if="setup.displayAddButton"
			class="daily-planning__actions">
			<o-icon
				v-if="setup.isSpecificSchedule()"
				v-tooltip="`Dupliquer sur tous les ${ setup.props.schedule?.date.toReadableDate('$dddd').pluralize(2, false).toLowerCase() }`"
				font-icon="icon-expand-vertical-3"
				@click="setup.duplicateDay()"/>
			<o-icon
				v-tooltip="`Ajouter un créneau horaire`"
				icon="add_plus"
				@click="setup.addTimeSlot()"/>
			<o-icon
				v-tooltip="`Dupliquer sur toute la période`"
				font-icon="icon-expand-horizontal-3-1"
				@click="setup.duplicateDay(true)"/>
		</div>
		<o-input
			v-if="schedule && schedule.isActive"
			v-model="schedule.workedHours"
			mask="hour"
			class="daily-planning__day-timeslot-total"
			disabled
			size="xs"/>
	</div>
</template>

<script setup lang="ts">
import OrderCreationDailyPlanningSetupService from '@/setup/order/creation/OrderCreationDailyPlanningSetupService';
const props = defineProps(OrderCreationDailyPlanningSetupService.props);
const setup = new OrderCreationDailyPlanningSetupService(props);
</script>

<style scoped lang="less">

:deep(.orion-button) {
	&__text {
		white-space: nowrap;
	}
}
.daily-planning {
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
	padding-bottom: 3rem;

		&__day {

			&-timeslot {
				position: relative;

				&-unactive {
					:deep(.orion-input__input) {
						color: var(--grey);
						border: none;
					}
				}

				&-not-working {
					:deep(.orion-input__input) {
						color: transparent;
					}
				}

				&-total {
					font-weight: 600;
					position: absolute;
					bottom: 0;
				}
			}

			&--active {
				border: 0.08rem solid;
			}

			&--disabled {
				cursor: default;
				color: var(--grey);
				&:hover {
					background-color: unset;
				}

				&:deep(.orion-button) {
					&__ripple {
						display: none;
					}
				}
			}

			&-delete {
				position: absolute;
				top: 45%;
				right: 0.7rem;
			}
		}


		.arm-highlight-timeslot {
			:deep(.orion-input) {
				&:first-child {
					> input {
						border-color: var(--info);
						background-color: var(--info-light);
					}
				}

				&:nth-child(2) {
					margin-top: 0;
					margin-bottom: 0;
					> input {
						border-color: var(--info);
						background-color: var(--info-light);
					}
				}
			}
		}

	:deep(.orion-input) {
		&:first-child {
			margin-bottom: 0;
			> input {
				border-bottom: none;
				border-radius: 0.3rem 0.3rem 0 0;
			}
		}

		&:nth-child(2) {
			margin-top: 0;
			margin-bottom: 0;
			> input {
				border-top: none;
				border-radius: 0 0 0.3rem 0.3rem;
			}
		}

		&__input {
			text-align: center;
		}
	}

	&__actions {
		display: flex;
		justify-content: space-around;
		margin: 1rem 0 0.5rem 0;
	}
}
</style>
