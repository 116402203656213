import { PropType, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrderEntity from '@/entity/order/OrderEntity';

type Props = SetupProps<typeof OrderCreationAsideSetupService.props>

export type Emits = { (e: 'current' | 'origin', val: number): void }

export default class OrderCreationAsideSetupService extends BaseSetupService<Props> {
	static readonly props = {
		orderCopy: {
			type: Object as PropType<OrderEntity>,
			default: () => new OrderEntity(),
		},
	};

	readonly _aside?: OrionAside;
	readonly _timeline = ref<OrionTimeline>();

	get sectionActions () { (!!document.getElementById('aside-order-edition_reset')); return !!document.getElementById('aside-order-edition_reset');}

	constructor (props: Props, _aside?: OrionAside) {
		super(props);
		this._aside = _aside;
	}

	protected onMounted () {
		super.onMounted();
		if (this.props.orderCopy.id)
			this.props.orderCopy.asideActivePane = 6;
		else
			this.props.orderCopy.asideActivePane = 1;
	}

	reset () {
		Object.assign(this.props.orderCopy, new OrderEntity());
		this.props.orderCopy.asideActivePane = 1;
	}
}
