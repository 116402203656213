<template>
	<div
		:ref="setup._el"
		class="order-creation-add-hours">
		<o-section>
			<div
				v-if="specific && scheduleRange"
				class="order-creation-add-hours__specific">
				<div class="order-creation-add-hours__specific-header">
					<o-datepicker
						v-model:range="setup.daterange"
						size="lg"
						:disabled="setup.isReadonly && !_aside"
						:min-date="order.start"
						:max-date="order.end"
						suffix-font-icon="icon-interface-calendar-mark"
						type="range"/>
					<div class="order-creation-add-hours__specific-icons">
						<o-icon
							v-if="!setup.isReadonly || (setup.self.can('OrderCreate') && setup.self.isClient() && !!_aside)"
							ripple="danger"
							font-icon="icon-interface-delete-bin-2"
							@click="setup.deletePeriod()"/>
					</div>
				</div>

				<div
					v-for="(schedule, weeknumber) in scheduleRange?.specificScheduleEntityGroupedByWeeknumber"
					:key="weeknumber"
					class="order-creation-add-hours__week">
					<div class="order-creation-add-hours__week-header">
						<span class="order-creation-add-hours__week-chip">Semaine {{ weeknumber }}</span>
					</div>
					<div class="order-creation-add-hours__days">
						<order-creation-daily-planning
							v-for="weekday in [1, 2, 3, 4, 5, 6, 0]"
							:key="weekday"
							:order="order"
							:weekday="weekday"
							:specific="specific"
							:parent-uid="setup.uid"
							:readonly="setup.isReadonly && !_aside"
							:schedule="setup.getSpecificScheduleEntity(weekday, +weeknumber)"/>
					</div>
				</div>
			</div>

			<div v-else>
				<div class="order-creation-add-hours__days">
					<order-creation-daily-planning
						v-for="weekday in [1, 2, 3, 4, 5, 6, 0]"
						:key="weekday"
						:order="order"
						:weekday="weekday"
						:parent-uid="setup.uid"
						:readonly
						:schedule="order.getScheduleDefaultEntity(weekday)"/>
				</div>
			</div>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import OrderCreationScheduleSetupService from '@/setup/order/creation/OrderCreationScheduleSetupService';
import OrderCreationDailyPlanning from './OrderCreationDailyPlanning.vue';
import { inject } from 'vue';
const _aside = inject<OrionAside>('_aside');
const props = defineProps(OrderCreationScheduleSetupService.props);
const setup = new OrderCreationScheduleSetupService(props, _aside);
</script>

<style scoped lang="less">
@import '@/styles/mixins';


.order-creation-add-hours {

	+ .order-creation-add-hours {
		&::before {
      content: "";
      display: block;
      flex: 1;
      height: 0.125rem;
			margin-bottom: 2rem;
      background-color: var(--grey-lighter);

      [data-orion-theme="dark"] & {
        background-color: var(--grey);
      }
    }
	}

	margin-top: 2rem;

	:deep(.orion-section) {
		&__title {
			font-size: 1.25rem
		}
		&__subtitle {
			font-size: 1rem
		}
	}

	&__days {
		display: flex;
		justify-content: space-between;
		gap: 0.5rem;
	}

	&__week {
		display: flex;
		flex-direction: column;

		&-header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 1rem;
		}

		&-chip {
			background-color: var(--grey-lighter);
			border-radius: 1rem;
			height: 2.3rem;
			font-weight: 600;
			padding: 0.5rem 1rem;
		}
	}

	&__specific {
		display: flex;
		justify-content: space-between;
		align-content: center;
		flex-direction: column;

		&-header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 1rem;
			width: 100%;

			:deep(.orion-datepicker){
				width: 14rem;
			}
		}

		&-icons {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1rem;
		}
	}

	&__period {
		display: flex;
		justify-content: space-between;
		font-size: 1rem;
		margin-bottom: 1rem;

		&-day {
			display: flex;
			justify-content: center;
			align-items: center;
			.circle(2rem);
			color:white;
			margin: 0 0.5rem;
			font-size: 1rem;
			background-color: var(--info);
		}
	}
}
</style>
