<template>
	<div class="order-step">
		<o-section id="order-step-1">
			<h4 class="order-step__title">
				<span class="text--brand">
					Étape 1
				</span> - Sélectionnez l'agence et l'entreprise à solliciter
			</h4>

			<div class="order-step__form">
				<div class="row row--grid">
					<div class="col-sm-6">
						<shared-select-user-company
							v-if="setup.self.isAgency()"
							v-model="order.agencyCompanyId"
							label="Agence d'emploi"
							required
							agency
							:type="setup.self.readableUserType"
							:validation="setup.validator.rule('agencyCompanyId')"
							set-to-first
							check-order-plugin
							@select="setup.selectAgency($event as ICompanyModel )"/>
						<shared-select-company
							v-else
							v-model="order.agencyCompanyId"
							required
							type="agency"
							:validation="setup.validator.rule('agencyCompanyId')"
							:permission-action="PERMISSION_ACTION.OrderCreate"
							set-to-first
							@select="setup.selectAgency($event as ICompanyModel)"/>
					</div>
					<div
						v-show="order.agencyCompanyId"
						class="col-sm-6">
						<shared-select-company
							v-if="setup.self.isAgency()"
							v-model="order.clientCompanyId"
							required
							:agency-company-id="order.agencyCompanyId"
							type="client"
							:validation="setup.validator.rule('clientCompanyId')"
							:permission-action="PERMISSION_ACTION.OrderCreate"
							auto-select-single
							@select="setup.selectClient($event as ICompanyModel)">
							<template #empty>
								<o-alert
									class="mv-xs"
									color="info">
									Vous n'avez aucun client rattaché à cette agence
								</o-alert>
							</template>
						</shared-select-company>
						<shared-select-user-company
							v-else
							v-model="order.clientCompanyId"
							label="Entreprise"
							required
							:type="setup.self.readableUserType"
							:validation="setup.validator.rule('clientCompanyId')"
							auto-select-single
							:permission-action="PERMISSION_ACTION.OrderCreate"
							check-order-plugin
							@select="setup.selectClient($event as ICompanyModel)">
							<template #empty>
								<o-alert
									class="mv-xs"
									color="info">
									Vous n'avez aucune entreprise rattachée à cette agence
								</o-alert>
							</template>
						</shared-select-user-company>
					</div>
				</div>
			</div>
		</o-section>

		<o-section
			v-if="order.asideActivePane !== 6"
			align="center">
			<o-button
				color="danger"
				outline
				@click="setup._aside?.close">
				Annuler
			</o-button>
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				outline
				@click="setup.next()">
				Suivant
			</o-button>
		</o-section>

		<o-section
			v-else
			align="center">
			<o-button
				suffix-font-icon="icon-interface-arrows-button-right"
				color="success"
				@click="$emit('current', 6)">
				Valider
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import OrderCreationStep1SetupService from '@/setup/order/creation/OrderCreationStep1SetupService';
import { PERMISSION_ACTION } from '@/typings/enums/PERMISSION_ACTION';
import { inject } from 'vue';
type Emits = { (e: 'current' | 'origin', val: number): void }
const emits = defineEmits<Emits>();
const _aside = inject<OrionAside>('_aside');
const props = defineProps(OrderCreationStep1SetupService.props);
const setup = new OrderCreationStep1SetupService(props, emits, _aside);
</script>
