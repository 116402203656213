import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/orderinterlocutor');

class OrderInterlocutorApiAgency {
	static add (addOrderAgencyModel: IAddOrderForAgencyModel) {
		return client.post<IOrderAgencyOverPageModel>('', addOrderAgencyModel);
	}

	static update (idOrder: number, interlocutor: IUpdateOrderInterlocutorModel) {
		return client.put<void>(`${idOrder}`, interlocutor);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}
}

export default OrderInterlocutorApiAgency;
