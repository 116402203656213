import ApiClient from '@/api/ApiClient';

const client = new ApiClient('taguser');

class TagUserApi {

	static add (addTagUserModel: IAddTagUserModel) {
		return client.post<ITagUserModel[]>(``, addTagUserModel);
	}

	static delete (deleteTagUserModel: IDeleteTagUserModel) {
		return client.post<void>('delete', deleteTagUserModel);
	}

	static listUser (tagId: number, withHiddenUsers: boolean) {
		return client.get<ITagUserForTagDetailModel[]>(`users/${tagId}`, { params: { withHiddenUsers } });
	}

	static self () {
		return client.get<ITagUserSelfModel[]>(`self`);
	}

}

export default TagUserApi;
