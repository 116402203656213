import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrderTemplateEntity from '@/entity/order-template/OrderTemplateEntity';
import ProfessionalSkillApi from '@/api/ProfessionalSkillApi';
import { Validator, useValidation } from '@orion.ui/orion';

type Props = SetupProps<typeof OrderToolboxJobDescriptionAddModalSetupService.props>

export default class OrderToolboxJobDescriptionAddModalSetupService extends BaseSetupService<Props> {
	static readonly props = {
		title: {
			type: String,
			default: 'Nouvelle description de poste',
		},
		orderTemplate: {
			type: Object as PropType<OrderTemplateEntity>,
			required: true as const,
		},
	};

	private readonly state = reactive({
		showLoader: true,
		professionalSkillsOptions: [] as IProfessionalSkillModel[],
		titleValidation: true,
	});

	get showLoader () { return this.state.showLoader; }
	get professionalSkillsOptions () { return this.state.professionalSkillsOptions; }


	validator = useValidation(this.props.orderTemplate, {
		title: new Validator([
			Validator.rules.required(),
			() => ({
				level: 'error',
				result: this.state.titleValidation,
				message: `Ce nom existe déjà`,
			}),
		]),
		content: new Validator([
			Validator.rules.required(),
			Validator.rules.hasMaxLength(2000),
		]),
	});

	readonly _modal?: OrionModal;

	constructor (props: Props, _modal?: OrionModal) {
		super(props);
		this._modal = _modal;
		this._modal?.bus.on(`titleValidation`, (val) => {this.state.titleValidation = val as boolean;});
	}

	protected async onBeforeMountAsync () {
		const { data } = await ProfessionalSkillApi.list();
		this.state.professionalSkillsOptions = data;
		this.state.showLoader = false;
	}

	handleTitleValidation (value: boolean) {
		this.state.titleValidation = value;
	}

	cancel () {
		this._modal?.close();
	}

	validate () {
		if (!this.validator.validate()) {
			this.validator.showValidationState();
			return;
		}

		this._modal?.trigger('validateJobDescription', this.props.orderTemplate.publicState);
	}
}
